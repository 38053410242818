import { useState, useEffect, useMemo, useCallback } from "react";
import { addMonths } from "date-fns";
import { useParams } from "react-router-dom";
import { getEmployeeSupportData } from "../../services/EmployeeService";
/**
 * A custom React hook that manages employees data, including filtering, sorting, and handling updates.
 *
 * @param {object} options - An object containing the id and type properties.
 * @param {string} options.id - The id used for fetching the employees.
 * @param {string} options.type - The type of employees to fetch.
 * @return {object} An object containing various state variables and functions to interact with the employees data.
 */
const useListSupport = ({ id, type }) => {
  const [employees, setEmployees] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filterGroup, setFilterGroup] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const { group } = useParams();
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "ascending",
  });

  const sortedEmployees = useMemo(() => {
    if (!sortConfig.key) {
      return [...employees];
    }

    const sortedEmployees = [...employees].sort((a, b) => {
      let keyA = a.attributes[sortConfig.key];
      let keyB = b.attributes[sortConfig.key];

      // Verificar si las claves son undefined
      if (keyA === undefined) keyA = "";
      if (keyB === undefined) keyB = "";

      // Convertir a cadena si no es una cadena
      if (typeof keyA !== "string") {
        keyA = keyA.toString();
      }
      if (typeof keyB !== "string") {
        keyB = keyB.toString();
      }

      if (keyA === "0") keyA = 0;
      if (keyB === "0") keyB = 0;
      if (keyA === "1") keyA = 1;
      if (keyB === "1") keyB = 1;

      return sortConfig.direction === "ascending"
        ? isNaN(keyA) || isNaN(keyB)
          ? keyA.localeCompare(keyB)
          : keyA - keyB
        : isNaN(keyA) || isNaN(keyB)
        ? keyB.localeCompare(keyA)
        : keyB - keyA;
    });

    return sortedEmployees;
  }, [employees, sortConfig]);

  /**
   * A description of the entire function.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  /**
   * A function to update the sorting configuration based on the key provided.
   *
   * @param {string} key - The key to set for sorting.
   * @return {void} This function does not return anything.
   */
  const requestSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getEmployeeSupportData();

      if (response.success) {
        let filteredData;

        if (type === "general") {
          filteredData = response.data;
        } else {
          filteredData = response.data.filter((employee) => {
            console.log(employee);
            const { campaing_id, area_id } = employee.attributes;
            return (
              (type === "campaing" && campaing_id === id) ||
              (type === "area" && area_id === id)
            );
          });
        }

        setEmployees(filteredData);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      console.error("Error fetching employee demographic data:", error);
      setEmployees([]);
    } finally {
      setIsLoading(false);
    }
  }, [id, type]);

  useEffect(() => {
    fetchData();
    if (isUpdated) {
      setIsUpdated(false);
    }
  }, [fetchData, isUpdated]);

  useEffect(() => {
    if (group === "contratistas") {
      setFilterGroup("Prestación de servicios");
    } else if (group === "empleados") {
      setFilterGroup("No prestación de servicios");
    }
  }, [group]);

  /**
   * A description of the entire function.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  const handleUpdate = () => {
    setIsUpdated(true);
  };

  const filteredByContractType = useMemo(() => {
    return sortedEmployees.filter((employee) => {
      if (filterGroup === "Prestación de servicios") {
        return employee.attributes.tipo_contrato === "Prestación de servicios";
      } else if (filterGroup === "No prestación de servicios") {
        return employee.attributes.tipo_contrato !== "Prestación de servicios";
      }
      return true;
    });
  }, [sortedEmployees, filterGroup]);

  /**
   * Filters the employees array based on the provided status.
   *
   * @param {array} employees - The array of employees to filter.
   * @param {string} status - The status to filter employees by.
   * @return {array} The filtered array of employees.
   */
  const filterByStatus = (employees, status) => {
    return employees.filter((employee) =>
      status === "activo"
        ? employee.attributes.estado === 1
        : employee.attributes.estado === 0
    );
  };

  const filteredEmployees = useMemo(() => {
    let filtered = filteredByContractType;

    if (filterValue) {
      const lowercaseFilterValue = filterValue.toLowerCase();
      if (["activo", "inactivo"].includes(lowercaseFilterValue)) {
        filtered = filterByStatus(filtered, lowercaseFilterValue);
      } else {
        filtered = filtered.filter((employee) => {
          const attributes = employee.attributes;
          return (
            Object.values(attributes).some((value) =>
              String(value).toLowerCase().includes(lowercaseFilterValue)
            ) ||
            (attributes.campaign && attributes.campaign.name
              ? attributes.campaign.name
                  .toLowerCase()
                  .includes(lowercaseFilterValue)
              : false) ||
            (attributes.area && attributes.area.name
              ? attributes.area.name
                  .toLowerCase()
                  .includes(lowercaseFilterValue)
              : false)
          );
        });
      }
    }

    return filtered;
  }, [filteredByContractType, filterValue]);
  const formatDate = (fecha) => {
    if (!fecha) return "";
    const date = new Date(fecha);

    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;

    return formattedDate;
  };
  const csvData = filteredEmployees.map((employee) => ({
    ID: employee.id,
    Nombre: employee.attributes.nombre.toUpperCase(),
    Estado: employee.attributes.estado === 1 ? "Activo" : "Inactivo",
    "Número de Identificación": employee.attributes.numero_identificacion,
    Campaña: employee.attributes.campaing.name,
    Área: employee.attributes.area.name,
    "Correo electrónico": employee.attributes.correo_electronico.toUpperCase(),
    "Correo Corporativo": employee.attributes.correo_corporativo,
    Teléfono: employee.attributes.telefono,
    Dirección: employee.attributes.direccion,
    Ciudad: employee.attributes.ciudad,
    "¿Tiene silla?": employee.attributes.tiene_silla === 1 ? "Sí" : "No",
    "¿Tiene computador?":
      employee.attributes.tiene_computador === 1 ? "Sí" : "No",
    "¿Tiene mouse?": employee.attributes.tiene_mouse === 1 ? "Sí" : "No",
    "¿Tiene cámara?": employee.attributes.tiene_camara === 1 ? "Sí" : "No",
    "¿Tiene teclado?": employee.attributes.tiene_teclado === 1 ? "Sí" : "No",
    "¿Tiene diadema?": employee.attributes.tiene_diadema === 1 ? "Sí" : "No",
    "¿Tiene VPN?": employee.attributes.tiene_vpn === 1 ? "Sí" : "No",
    "¿Tiene Compartida?":
      employee.attributes.tiene_compartida === 1 ? "Sí" : "No",
    "¿Tiene biométrico?":
      employee.attributes.tiene_biometrico === 1 ? "Sí" : "No",
    "Acta de entrega": employee.attributes.acta,
    "Técnico asignado": employee.attributes.tecnico_asignado,
    "Fecha entrega": formatDate(employee.attributes.fecha_entrega_equipos),
    "Fecha devolución": formatDate(employee.attributes.fecha_devolucion_equipo),
    "Paz y Salvo": employee.attributes.paz_salvo_TI,
    Observaciones: employee.attributes.observaciones_TI,
  }));

  const headersCsv = Object.keys(csvData[0] || {});

  return {
    selectedEmployee,
    setSelectedEmployee,
    setIsEditModalOpen,
    isEditModalOpen,
    isLoading,
    requestSort,
    handleUpdate,
    filterValue,
    filteredEmployees,
    handleFilterChange,
    csvData,
    headersCsv,
  };
};

export { useListSupport };
