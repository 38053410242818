import { useState } from "react";
import { alerts } from "../../utils/Notifications";
import { forgotPassword } from "../../services/AuthService";

/**
 * Custom hook to handle reset password functionality.
 *
 * @param {Object} handleClose - Function to handle closing the reset password modal
 * @return {Object} Object containing email, isValidEmail, isLoading, handleEmailChange, handleSubmit, handleClose, alerts, validateEmail, setIsLoading, setIsValidEmail, setEmail
 */
const useResetPassword = ({ handleClose }) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Validates the format of an email address.
   *
   * @param {string} inputValue - The email address to be validated
   * @return {void}
   */
  const validateEmail = (inputValue) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);
    setIsValidEmail(isValid);
  };

  /**
   * Handles the change event for the email input, sets the email state, and validates the email format.
   *
   * @param {Event} e - the event object triggering the change
   * @return {void}
   */
  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    validateEmail(inputValue);
  };

  
  /**
   * Handles the form submission asynchronously, validates email, displays success or error alert.
   *
   * @param {Event} e - The event object triggering the form submission
   * @return {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidEmail) {
      setIsLoading(true);

      try {
        const response = await forgotPassword(email);

        if (response.success) {
          alerts(
            "success",
            "Se ha enviado un correo para cambiar la contraseña!",
            2000
          );
        } else {
          alerts("error", response.message, 3000);
        }
      } catch (e) {
        alerts(
          "error",
          "El correo ingresado no se encuentra registrado!",
          3000
        );
      } finally {
        setIsLoading(false);
        handleClose();
      }
    }
  };

  return {
    email,
    isValidEmail,
    isLoading,
    handleEmailChange,
    handleSubmit,
    handleClose,
    alerts,
    validateEmail,
    setIsLoading,
    setIsValidEmail,
    setEmail,
  };
};

export { useResetPassword };
