import React from "react";
import {
    Row,
    Col,
    Image,
    Form,
    Button,
    Container,
    InputGroup,
} from "react-bootstrap";
import { IoEyeOffSharp } from "react-icons/io5";
import { IoMdEye } from "react-icons/io";
import { useLogin } from "./useLogin";
import { ResetPassword } from "../../Components/ResetPassword";
import { ChangePassword } from "../../Components/ChangePassword";
import logo from "../../Assets/logo.png";
import "./login.css";

/**
 * Represents a functional component for a login form interface.
 *
 * @return {JSX.Element} The JSX element representing the login form interface.
 */
const Login = () => {
    const {
        showPassword,
        password,
        email,
        handleTogglePassword,
        handleLogin,
        handleKeyDown,
        setEmail,
        setPassword,
        setShowIcon,
        showIcon,
        handleOpenResetModal,
        handleCloseResetModal,
        showResetModal,
        token,
        emailParam,
        showResetChangeModal,
        handleCloseResetChangeModal,
    } = useLogin();
    return (
        <Container fluid className="login-container">
            <Row>
                <Col></Col>
                <Col
                    md={5}
                    className="d-flex justify-content-center align-items-center flex-column form-login"
                >
                    <Image src={logo} className="w-25" />
                    <h2 className="text-blue text-center display-6 fw-bold ">
                        Iniciar Sesión
                    </h2>
                    <p className="fw-bold"> Sistema de Gestión Humana</p>
                    <div className="form mt-3">
                        <Form>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        className="form-control-login"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        onKeyDown={handleKeyDown}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                                <Col md={12}>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="Contraseña"
                                            value={password}
                                            className="form-control-login"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            onKeyDown={handleKeyDown}
                                            autoComplete="off"
                                        />
                                        {showIcon ? (
                                            <IoEyeOffSharp
                                                onClick={() => {
                                                    handleTogglePassword();
                                                    setShowIcon(false);
                                                }}
                                                className="position-absolute top-50 translate-middle-y end-0 "
                                            />
                                        ) : (
                                            <IoMdEye
                                                onClick={() => {
                                                    handleTogglePassword();
                                                    setShowIcon(true);
                                                }}
                                                className="position-absolute top-50 translate-middle-y end-0 "
                                            />
                                        )}
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row className="mb-3 text-center mt-4 justify-content-center align-items-center">
                                <Col md={12} className="mt-2">
                                    <Button
                                        className="bg-blue btn-sm w-75"
                                        type="button"
                                        onClick={handleLogin}
                                    >
                                        Ingresar
                                    </Button>
                                </Col>
                                <Col md={12} className="mt-2">
                                    <span
                                        style={{ fontSize: "0.8rem" }}
                                        className="fw-bold mt-4 px-2"
                                    >
                                        ¿Olvidó su contraseña?
                                        <span
                                            className="ml-2 text-blue text-decoration-none"
                                            role="button"
                                            onClick={handleOpenResetModal}
                                        >
                                            {" "}
                                            Click
                                        </span>
                                    </span>
                                </Col>
                            </Row>
                        </Form>
                        {token && (
                            <ChangePassword
                                email={emailParam}
                                token={token}
                                show={showResetChangeModal}
                                handleClose={handleCloseResetChangeModal}
                            />
                        )}
                        <ResetPassword
                            show={showResetModal}
                            handleClose={handleCloseResetModal}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
