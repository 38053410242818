import { useState } from "react";
import { deleteEmployee } from "../../services/EmployeeService";
import { alerts } from "../../utils/Notifications";
/**
 * Custom hook to manage employee deletion modal state and behavior.
 *
 * @param {Object} param0 - Destructured object with onClose, employee, and onUpdate properties
 * @return {Object} Object with stateEmployee, changeEmployeeStatus, onClose, and isLoading properties
 */
const useDeletModal = ({ onClose, employee, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  /**
   * Toggle the status of an employee.
   *
   * @param {type} stateEmployee - the current status of the employee
   * @return {type} the new status of the employee
   */
  const toggleEmployeeStatus = (stateEmployee) => {
    return stateEmployee === 1 ? "Inactivar" : "Activar";
  };
  let stateEmployee = toggleEmployeeStatus(employee.attributes.estado);

  /**
   * A function to change the status of an employee.
   *
   */
  const changeEmployeeStatus = async () => {
    setIsLoading(true);

    try {
      const response = await deleteEmployee(employee.id);

      if (response.success) {
        alerts("success", "cambios guardados", 2000);
        onClose();
        onUpdate();
      }
    } catch (e) {
      alerts("error", "Error al procesar la solicitud", 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    stateEmployee,
    changeEmployeeStatus,
    onClose,
    isLoading,
  };
};
export { useDeletModal };
