import React from "react";
import { Row, Col } from "react-bootstrap";
import Sidebar from "../../Components/SideBar";
import { useListPage } from "./useListPage";

/**
 * Renders a ListPage component with a sidebar and list component based on the selected menu item.
 *
 * @param {Object} listType - The type of list to display.
 * @return {JSX.Element} The rendered ListPage component.
 */
const ListPage = ({ listType }) => {
  const { selectedMenuItem, handleMenuClick, ListComponent } = useListPage({
    listType,
  });

  return (
    <Row className="g-0">
      <Col xs={1}>
        <Sidebar onMenuClick={handleMenuClick} className="p-0" />
      </Col>
      <Col xs={11} className="flex-grow-1 overflow-hidden">
        <div className="px-5 mx-5 overflow-hidden my-0">
          <ListComponent
            id={selectedMenuItem.id}
            type={selectedMenuItem.type}
            name={selectedMenuItem.name}
            className="flex-grow-1 overflow-hidden"
          />
        </div>
      </Col>
    </Row>
  );
};

export default ListPage;
