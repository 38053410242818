import React from "react";
import { CSVLink } from "react-csv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoMdCloudDownload } from "react-icons/io";
import { MdOutlineContentCopy } from "react-icons/md";
import { Container, Table, Form, Row, Col } from "react-bootstrap";
import Loading from "../Loading";
import User from "../User";
import { useListComunications } from "./useListComunications";

/**
 * Renders the ListComunications component.
 *
 * @param {Object} id - The id of the area, campaing or general
 * @param {string} type - The type of the employee
 * @param {string} name - The name of the communication
 * @return {JSX.Element} The rendered ListComunications component
 */

const ListComunications = ({ id, type, name }) => {
  const {
    requestSort,
    handleCopyColumn,
    isLoading,
    sortedEmployees,
    handleFilterChange,
    filterValue,
    filteredEmployees,
    csvData,
    formatDate, 
    headersCsv,
  } = useListComunications({ id, type, name });

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <>
          <Container fluid className="mx-5 my-2">
            <User color="blue" marginX={4} position={true} marginY={2} />

            <h2 className="text-center text-blue">Personal - {name}</h2>

            <section>
              <Row className="justify-content-between mb-1">
                <Col md={4} className="d-flex flex-column">
                  <span className="fw-bold text-blue">
                    Módulo: Comunicaciones
                  </span>
                  <span className="text-blue mb-0">
                    Registros: {filteredEmployees.length}
                  </span>
                </Col>

                <Col md={8} className="d-flex justify-content-end">
                  <Form>
                    <Form.Group controlId="filter">
                      <Form.Control
                        style={{ width: "400px" }}
                        type="text"
                        placeholder="Filtrar..."
                        value={filterValue}
                        onChange={handleFilterChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                  </Form>
                  <CSVLink
                    data={csvData}
                    headers={headersCsv}
                    filename={"datos_empleados.csv"}
                  >
                    <IoMdCloudDownload
                      color="#0096C8"
                      size={30}
                      className="mx-1"
                    />
                  </CSVLink>
                </Col>
              </Row>
            </section>

            <div className="table-container">
              <Table
                striped
                bordered
                hover
                size="md"
                className="table-employee"
              >
                <thead>
                  <tr className="text-center">
                    <th onClick={() => requestSort("id")}>ID</th>
                    <th>
                      Nombre
                      <CopyToClipboard
                        text={
                          sortedEmployees &&
                          sortedEmployees
                            .map((employee) => employee.attributes.nombre)
                            .join("\n")
                        }
                        onCopy={() => handleCopyColumn("nombre")}
                      >
                        <MdOutlineContentCopy
                          className="ms-2"
                          size={15}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </CopyToClipboard>
                    </th>
                    <th onClick={() => requestSort("campaing")}>Campaña</th>
                    <th onClick={() => requestSort("area")}>Área</th>
                    <th onClick={() => requestSort("tipo_contrato")}>
                      Tipo de contrato
                    </th>
                    <th>
                      Correo electrónico
                      <CopyToClipboard
                        text={
                          sortedEmployees &&
                          sortedEmployees
                            .map(
                              (employee) =>
                                employee.attributes.correo_electronico
                            )
                            .join("\n")
                        }
                        onCopy={() => handleCopyColumn("correo_electronico")}
                      >
                        <MdOutlineContentCopy
                          className="ms-2"
                          size={15}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </CopyToClipboard>
                    </th>

                    <th>
                      Correo Corporativo
                      <CopyToClipboard
                        text={
                          sortedEmployees &&
                          sortedEmployees
                            .map(
                              (employee) =>
                                employee.attributes.correo_corporativo
                            )
                            .join("\n")
                        }
                        onCopy={() => handleCopyColumn("correo_corporativo")}
                      >
                        <MdOutlineContentCopy
                          className="ms-2"
                          size={15}
                          style={{ cursor: "pointer" }}
                        />
                      </CopyToClipboard>
                    </th>
                    <th onClick={() => requestSort("telefono")}>
                      Teléfono
                      <CopyToClipboard
                        text={sortedEmployees
                          .map(
                            (employee) => employee.attributes.telefono
                          )
                          .join("\n")}
                        onCopy={() => handleCopyColumn("telefono")}
                      >
                        <MdOutlineContentCopy
                          className="ms-2"
                          size={15}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </CopyToClipboard>
                    </th>
                    <th onClick={() => requestSort("ciudad")}>Ciudad</th>
                    <th onClick={() => requestSort("genero")}>Género</th>
                    <th onClick={() => requestSort("fecha_nacimiento")}>
                      Fecha de nacimiento
                    </th>
                    <th onClick={() => requestSort("profesion")}>Profesión</th>
                    <th onClick={() => requestSort("tiene_hijo")}>
                      Tiene hijo
                    </th>
                    <th onClick={() => requestSort("cantidad_hijos")}>
                      Cantidad de hijos
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEmployees.map((employee) => (
                    <tr key={employee.id}>
                      <td>{employee.id}</td>
                      <td>{employee.attributes.nombre}</td>
                      <td>{employee.attributes.campaing}</td>
                      <td>{employee.attributes.area}</td>
                      <td>{employee.attributes.tipo_contrato}</td>
                      <td>{employee.attributes.correo_electronico}</td>
                      <td>{employee.attributes.correo_corporativo}</td>
                      <td>{employee.attributes.telefono}</td>
                      <td>{employee.attributes.ciudad}</td>
                      <td>{employee.attributes.genero}</td>
                      <td>{formatDate(employee.attributes.fecha_nacimiento)}</td>
                      <td>{employee.attributes.profesion}</td>
                      <td>
                        {employee.attributes.tiene_hijo === 1 ? "Si" : "No"}
                      </td>
                      <td>{employee.attributes.cantidad_hijos}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default ListComunications;
