import React from "react";
import { CSVLink } from "react-csv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoMdCloudDownload } from "react-icons/io";
import { MdOutlineContentCopy } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import ModalSocialDemographic from "../ModalSocialDemographic";
import { FcSurvey } from "react-icons/fc";
import { TbReload } from "react-icons/tb";
import { Container, Table, Form, Row, Col } from "react-bootstrap";
import Loading from "../Loading";
import User from "../User";
import { useListDemographic } from "./useListDemographic";

const ListDemographic = ({ id, type, name }) => {
  const {
    requestSort,
    handleCopyColumn,
    isLoading,
    sortedEmployees,
    handleFilterChange,
    filterValue,
    filteredEmployees,
    csvData,
    headersCsv,
    isEditModalOpen,
    setIsEditModalOpen,
    selectedEmployee,
    setSelectedEmployee,
    handleUpdate,
    formatDate, 
    calculateAge,
  } = useListDemographic({ id, type, name });

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <Container fluid className="mx-5 my-2">
          <User color="blue" marginX={4} position={true} marginY={2} />
          <h2 className="text-center text-blue">Personal - {name}</h2>
          <section>
            <Row className="justify-content-between mb-1">
              <Col md={4} className="d-flex flex-column">
                <span className="fw-bold text-blue">Módulo: SG- SST</span>
                <span className="text-blue mb-0">
                  Registros: {filteredEmployees.length}
                </span>
              </Col>
              <Col md={8} className="d-flex justify-content-end">
                <Form>
                  <Form.Group controlId="filter">
                    <Form.Control
                      style={{ width: "400px" }}
                      type="text"
                      placeholder="Filtrar..."
                      value={filterValue}
                      onChange={handleFilterChange}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                </Form>
                <CSVLink
                  data={csvData}
                  headers={headersCsv}
                  filename={"datos_empleados.csv"}
                >
                  <IoMdCloudDownload
                    color="#0096C8"
                    size={30}
                    className="mx-1"
                  />
                </CSVLink>
                <TbReload
                  title="Actualizar la información"
                  role="button"
                  color="#0096C8"
                  size={25}
                  className="mx-1"
                  onClick={handleUpdate}
                />
              </Col>
            </Row>
          </section>
          <div className="table-container">
            <Table striped bordered hover size="md" className="table-employee">
              <thead>
                <tr className="text-center">
                  <th></th>
                  <th></th>
                  <th onClick={() => requestSort("id")}>ID</th>
                  <th>
                    Nombre
                    <CopyToClipboard
                      text={
                        sortedEmployees &&
                        sortedEmployees
                          .map((employee) => employee.attributes.nombre)
                          .join("\n")
                      }
                      onCopy={() => handleCopyColumn("nombre")}
                    >
                      <MdOutlineContentCopy
                        className="ms-2"
                        size={15}
                        style={{ cursor: "pointer" }}
                      />
                    </CopyToClipboard>
                  </th>
                  <th onClick={() => requestSort("campaing")}>Campaña</th>
                  <th onClick={() => requestSort("area")}>Área</th>
                  <th onClick={() => requestSort("cargo")}>Cargo</th>
                  <th onClick={() => requestSort("tipo_identificacion")}>
                    Tipo de identificación
                  </th>

                  <th onClick={() => requestSort("numero_identificacion")}>
                    Número de identificación
                  </th>
                  <th onClick={() => requestSort("lugar_nacimiento")}>
                    Lugar de nacimiento
                  </th>
                  <th onClick={() => requestSort("nivel_educativo")}>
                    Nivel educativo
                  </th>
                  <th onClick={() => requestSort("modalidad_trabajo")}>
                    Modalidad de trabajo
                  </th>
                  <th onClick={() => requestSort("fecha_inicio")}>
                    Fecha de inicio
                  </th>
                  <th onClick={() => requestSort("tipo_contrato")}>
                    Tipo de contrato
                  </th>

                  <th>
                    Correo electrónico
                    <CopyToClipboard
                      text={
                        sortedEmployees &&
                        sortedEmployees
                          .map(
                            (employee) => employee.attributes.correo_electronico
                          )
                          .join("\n")
                      }
                      onCopy={() => handleCopyColumn("correo_electronico")}
                    >
                      <MdOutlineContentCopy
                        className="ms-2"
                        size={15}
                        style={{ cursor: "pointer" }}
                      />
                    </CopyToClipboard>
                  </th>

                  <th>
                    Correo Corporativo
                    <CopyToClipboard
                      text={
                        sortedEmployees &&
                        sortedEmployees
                          .map(
                            (employee) => employee.attributes.correo_corporativo
                          )
                          .join("\n")
                      }
                      onCopy={() => handleCopyColumn("correo_corporativo")}
                    >
                      <MdOutlineContentCopy
                        className="ms-2"
                        size={15}
                        style={{ cursor: "pointer" }}
                      />
                    </CopyToClipboard>
                  </th>

                  <th onClick={() => requestSort("telefono")}>Teléfono</th>
                  <th onClick={() => requestSort("direccion")}>Dirección</th>
                  <th onClick={() => requestSort("ciudad")}>Ciudad</th>
                  <th onClick={() => requestSort("genero")}>Género</th>
                  <th onClick={() => requestSort("fecha_nacimiento")}>
                    Fecha de nacimiento
                  </th>
                  <th onClick={() => requestSort("edad")}>Edad</th>

                  <th onClick={() => requestSort("profesion")}>Profesión</th>
                  <th onClick={() => requestSort("tiene_hijo")}>Tiene hijo</th>
                  <th onClick={() => requestSort("cantidad_hijos")}>
                    Cantidad de hijos
                  </th>
                  <th onClick={() => requestSort("orientacion_sexual")}>
                    Orientación sexual
                  </th>
                  <th onClick={() => requestSort("pais")}>País</th>
                  <th onClick={() => requestSort("nacionalidad")}>
                    Nacionalidad
                  </th>
                  <th onClick={() => requestSort("departamento")}>
                    Departamento
                  </th>
                  <th onClick={() => requestSort("tipo_vivienda")}>
                    Tipo de vivienda
                  </th>
                  <th onClick={() => requestSort("tiene_personas_a_cargo")}>
                    Tiene personas a cargo
                  </th>
                  <th onClick={() => requestSort("cantidad_personas_a_cargo")}>
                    Cantidad de personas a cargo
                  </th>
                  <th onClick={() => requestSort("estrato_socioeconomico")}>
                    Estrato socioeconómico
                  </th>
                  <th onClick={() => requestSort("estado_civil")}>
                    Estado civil
                  </th>
                  <th onClick={() => requestSort("raza")}>Raza</th>
                  <th onClick={() => requestSort("usted_ha_sido_victima")}>
                    ¿Ha sido víctima?
                  </th>
                  <th onClick={() => requestSort("tiempo_libre_actividad")}>
                    Tiempo libre para actividad
                  </th>
                  <th onClick={() => requestSort("practica_deporte")}>
                    ¿Practica deporte?
                  </th>
                  <th onClick={() => requestSort("detalle_deporte")}>
                    Detalle deporte
                  </th>
                  <th
                    onClick={() => requestSort("consume_bebidas_alcoholicas")}
                  >
                    ¿Consume bebidas alcohólicas?
                  </th>
                  <th onClick={() => requestSort("frecuencia_alcoholica")}>
                    Frecuencia de consumo de alcohol
                  </th>
                  <th onClick={() => requestSort("fuma")}>¿Fuma?</th>
                  <th onClick={() => requestSort("frecuencia_fuma")}>
                    Frecuencia de consumo de tabaco
                  </th>
                  <th
                    onClick={() =>
                      requestSort("le_han_diagnosticado_enfermedad")
                    }
                  >
                    ¿Le han diagnosticado alguna enfermedad?
                  </th>
                  <th onClick={() => requestSort("detalle_enfermedad")}>
                    Detalle enfermedad
                  </th>
                  <th
                    onClick={() =>
                      requestSort("tiene_antecedentes_familiares_patologicos")
                    }
                  >
                    ¿Tiene antecedentes familiares patológicos?
                  </th>
                  <th
                    onClick={() => requestSort("detalle_enfermedad_patologica")}
                  >
                    Detalle enfermedad patológica
                  </th>
                  <th onClick={() => requestSort("peso")}>Peso</th>
                  <th onClick={() => requestSort("estatura")}>Estatura</th>
                  <th onClick={() => requestSort("tipo_sangre")}>
                    Tipo de sangre
                  </th>
                  <th onClick={() => requestSort("consume_medicamento")}>
                    ¿Consume medicamentos?
                  </th>
                  <th onClick={() => requestSort("detalle_medicamento")}>
                    Detalle medicamento
                  </th>
                  <th onClick={() => requestSort("sufre_alergias")}>
                    ¿Sufre de alergias?
                  </th>
                  <th onClick={() => requestSort("detalle_alergia")}>
                    Detalle alergia
                  </th>
                  <th onClick={() => requestSort("persona_pensionado")}>
                    ¿Es persona pensionada?
                  </th>
                  <th onClick={() => requestSort("gestantes")}>
                    ¿Está gestante?
                  </th>
                  <th onClick={() => requestSort("lactancia")}>
                    ¿En período de lactancia?
                  </th>
                  <th onClick={() => requestSort("discapacidad")}>
                    ¿Tiene alguna discapacidad?
                  </th>
                  <th onClick={() => requestSort("tipo_discapacidad")}>
                    Tipo de discapacidad
                  </th>
                  <th onClick={() => requestSort("cargo")}>Cargo</th>
                  <th onClick={() => requestSort("experiencia_cargo")}>
                    Experiencia en el cargo
                  </th>
                  <th onClick={() => requestSort("eps")}>EPS</th>
                  <th onClick={() => requestSort("arl")}>ARL</th>
                  <th onClick={() => requestSort("afp")}>AFP</th>
                  <th onClick={() => requestSort("nombre_contacto")}>
                    Nombre de contacto
                  </th>
                  <th onClick={() => requestSort("telefono_contacto")}>
                    Teléfono de contacto
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredEmployees.map((employee) => (
                  <tr key={employee.id}>
                    <th>
                      <CiEdit
                        size={20}
                        className="icon text-blue"
                        onClick={() => {
                          setIsEditModalOpen(true);
                          setSelectedEmployee(employee);
                        }}
                      />
                    </th>
                    <th>
                      {employee.attributes.encuesta_demografica ? (
                        <span title="Ya completó la encuesta">
                          <FcSurvey className="icon" />
                        </span>
                      ) : null}
                    </th>
                    <td>{employee.id}</td>
                    <td>{employee.attributes.nombre}</td>
                    <td>{employee.attributes.campaing}</td>
                    <td>{employee.attributes.area}</td>
                    <td>{employee.attributes.cargo}</td>
                    <td>{employee.attributes.tipo_identificacion}</td>
                    <td>{employee.attributes.numero_identificacion}</td>
                    <td>{employee.attributes.lugar_nacimiento}</td>

                    <td>{employee.attributes.nivel_educativo}</td>
                    <td>{employee.attributes.modalidad_trabajo}</td>
                    <td>{formatDate(employee.attributes.fecha_inicio)}</td>
                    <td>{employee.attributes.tipo_contrato}</td>
                    <td>{employee.attributes.correo_electronico}</td>
                    <td>{employee.attributes.correo_corporativo}</td>
                    <td>{employee.attributes.telefono}</td>
                    <td>{employee.attributes.direccion}</td>

                    <td>{employee.attributes.ciudad}</td>
                    <td>{employee.attributes.genero}</td>
                    <td>{formatDate(employee.attributes.fecha_nacimiento)}</td>
                    <td>
                      {calculateAge(employee.attributes.fecha_nacimiento)}
                    </td>

                    <td>{employee.attributes.profesion}</td>
                    <td>
                      {employee.attributes.tiene_hijo === 1 ? "Si" : "No"}
                    </td>
                    <td>{employee.attributes.cantidad_hijos}</td>
                    <td>{employee.attributes.orientacion_sexual}</td>
                    <td>{employee.attributes.pais}</td>
                    <td>{employee.attributes.nacionalidad}</td>
                    <td>{employee.attributes.departamento}</td>
                    <td>{employee.attributes.tipo_vivienda}</td>
                    <td>
                      {employee.attributes.tiene_personas_a_cargo === 1
                        ? "Si"
                        : "No"}
                    </td>
                    <td>{employee.attributes.cantidad_personas_a_cargo}</td>
                    <td>{employee.attributes.estrato_socioeconomico}</td>
                    <td>{employee.attributes.estado_civil}</td>
                    <td>{employee.attributes.raza}</td>
                    <td>{employee.attributes.usted_ha_sido_victima}</td>
                    <td>{employee.attributes.tiempo_libre_actividad}</td>
                    <td>
                      {employee.attributes.practica_deporte === 1 ? "Si" : "No"}
                    </td>
                    <td>{employee.attributes.detalle_deporte}</td>
                    <td>
                      {employee.attributes.consume_bebidas_alcoholicas === 1
                        ? "Si"
                        : "No"}
                    </td>
                    <td>{employee.attributes.frecuencia_alcoholica}</td>
                    <td>{employee.attributes.fuma === 1 ? "Si" : "No"}</td>
                    <td>{employee.attributes.frecuencia_fuma}</td>
                    <td>
                      {employee.attributes.le_han_diagnosticado_enfermedad === 1
                        ? "Si"
                        : "No"}
                    </td>
                    <td>{employee.attributes.detalle_enfermedad}</td>
                    <td>
                      {employee.attributes
                        .tiene_antecedentes_familiares_patologicos === 1
                        ? "Si"
                        : "No"}
                    </td>
                    <td>{employee.attributes.detalle_enfermedad_patologica}</td>
                    <td>{employee.attributes.peso}</td>
                    <td>{employee.attributes.estatura}</td>
                    <td>{employee.attributes.tipo_sangre}</td>
                    <td>
                      {employee.attributes.consume_medicamento === 1
                        ? "Si"
                        : "No"}
                    </td>
                    <td>{employee.attributes.detalle_medicamento}</td>
                    <td>
                      {employee.attributes.sufre_alergias === 1 ? "Si" : "No"}
                    </td>
                    <td>{employee.attributes.detalle_alergia}</td>
                    <td>
                      {employee.attributes.persona_pensionado === 1
                        ? "Si"
                        : "No"}
                    </td>
                    <td>{employee.attributes.gestantes === 1 ? "Si" : "No"}</td>
                    <td>{employee.attributes.lactancia === 1 ? "Si" : "No"}</td>
                    <td>
                      {employee.attributes.discapacidad === 1 ? "Si" : "No"}
                    </td>
                    <td>{employee.attributes.tipo_discapacidad}</td>
                    <td>{employee.attributes.cargo}</td>
                    <td>{employee.attributes.experiencia_laboral}</td>
                    <td>{employee.attributes.eps}</td>
                    <td>{employee.attributes.arl}</td>
                    <td>{employee.attributes.afp}</td>
                    <td>{employee.attributes.nombre_contacto}</td>
                    <td>{employee.attributes.telefono_contacto}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
      )}
      {isEditModalOpen && (
        <ModalSocialDemographic
          onClose={() => setIsEditModalOpen(false)}
          employee={selectedEmployee}
          onUpdate={handleUpdate}
        />
      )}
    </>
  );
};

export default ListDemographic;
