import { useState } from "react"; // Importa useState
import { Col, Form, Button, Row, Alert } from "react-bootstrap"; // Importa Alert desde react-bootstrap
import { Formik } from "formik";
import * as yup from "yup";

const FormFirstSection = ({
  showFirstSection,
  setShowFirstSection,
  setShowSecondSection,
  formValues,
  setFormValues,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const schema = yup.object().shape({
    estrato_socioeconomico: yup.string().required("Campo requerido"),
    estado_civil: yup.string().required("Campo requerido"),
    raza: yup.string().required("Campo requerido"),
    peso: yup
      .number()
      .required("Campo requerido")
      .positive("Debe ser un número positivo"),
    estatura: yup
      .number()
      .required("Campo requerido")
      .positive("Debe ser un número positivo"),
    tipo_sangre: yup.string().required("Campo requerido"),
    orientacion_sexual: yup.string().required("Campo requerido"),
    usted_ha_sido_victima: yup.string().required("Campo requerido"),
    departamento: yup.string().required("Campo requerido"),
    pais: yup.string().required("Campo requerido"),
    nacionalidad: yup.string().required("Campo requerido"),
    tipo_vivienda: yup.string().required("Campo requerido"),
  });

  const fields = [
    {
      name: "estrato_socioeconomico",
      label: "Estrato Socioeconómico",
      options: [...Array(7).keys()].map((num) => ({
        value: num.toString(),
        label: num.toString(),
      })),
    },
    {
      name: "estado_civil",
      label: "Estado Civil",
      options: [
        "Soltero",
        "Casado",
        "Viudo",
        "Separado",
        "Divorciado",
        "Unión Libre",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "raza",
      label: "Raza",
      options: [
        "Afrodesenciente",
        "Indigena",
        "Gitana",
        "Mestizo",
        "Ninguno",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "peso",
      label: "Peso (kg)",
      type: "number",
    },
    {
      name: "estatura",
      label: "Estatura (cm)",
      type: "number",
    },
    {
      name: "tipo_sangre",
      label: "Tipo de Sangre",
      options: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"].map(
        (option) => ({ value: option, label: option })
      ),
    },
    {
      name: "orientacion_sexual",
      label: "Orientación Sexual",
      options: [
        "Heterosexual",
        "Homosexual",
        "Bisexual",
        "Pansexual",
        "Asexual",
   
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "usted_ha_sido_victima",
      label: "¿Usted ha sido víctima de violencia?",
      options: [
        "Desplazamiento",
        "Secuestro",
        "Violencia",
        "Violencia de Género",
        "Ninguno",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "departamento",
      label: "Departamento",
      options: [
        "Amazonas",
        "Antioquia",
        "Arauca",
        "Atlántico",
        "Bolívar",
        "Boyacá",
        "Caldas",
        "Caquetá",
        "Casanare",
        "Cauca",
        "Cesar",
        "Chocó",
        "Córdoba",
        "Cundinamarca",
        "Guainía",
        "Guaviare",
        "Huila",
        "La Guajira",
        "Magdalena",
        "Meta",
        "Nariño",
        "Norte de Santander",
        "Putumayo",
        "Quindío",
        "Risaralda",
        "San Andrés y Providencia",
        "Santander",
        "Sucre",
        "Tolima",
        "Valle del Cauca",
        "Vaupés",
        "Vichada",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "pais",
      label: "País de Residencia",
      options: [
        "Colombia",
        "Afganistán",
        "Albania",
        "Alemania",
        "Andorra",
        "Angola",
        "Antigua y Barbuda",
        "Arabia Saudita",
        "Argelia",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaiyán",
        "Bahamas",
        "Bangladés",
        "Barbados",
        "Baréin",
        "Bélgica",
        "Belice",
        "Benín",
        "Bielorrusia",
        "Birmania",
        "Bolivia",
        "Bosnia y Herzegovina",
        "Botsuana",
        "Brasil",
        "Brunéi",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Bután",
        "Cabo Verde",
        "Camboya",
        "Camerún",
        "Canadá",
        "Catar",
        "Chad",
        "Chile",
        "China",
        "Chipre",
        "Ciudad del Vaticano",
        "Comoras",
        "Corea del Norte",
        "Corea del Sur",
        "Costa de Marfil",
        "Costa Rica",
        "Croacia",
        "Cuba",
        "Dinamarca",
        "Dominica",
        "Ecuador",
        "Egipto",
        "El Salvador",
        "Emiratos Árabes Unidos",
        "Eritrea",
        "Eslovaquia",
        "Eslovenia",
        "España",
        "Estados Unidos",
        "Estonia",
        "Etiopía",
        "Filipinas",
        "Finlandia",
        "Fiyi",
        "Francia",
        "Gabón",
        "Gambia",
        "Georgia",
        "Ghana",
        "Granada",
        "Grecia",
        "Guatemala",
        "Guyana",
        "Guinea",
        "Guinea-Bisáu",
        "Guinea Ecuatorial",
        "Haití",
        "Honduras",
        "Hungría",
        "India",
        "Indonesia",
        "Irak",
        "Irán",
        "Irlanda",
        "Islandia",
        "Islas Marshall",
        "Islas Salomón",
        "Israel",
        "Italia",
        "Jamaica",
        "Japón",
        "Jordania",
        "Kazajistán",
        "Kenia",
        "Kirguistán",
        "Kiribati",
        "Kuwait",
        "Laos",
        "Lesoto",
        "Letonia",
        "Líbano",
        "Liberia",
        "Libia",
        "Liechtenstein",
        "Lituania",
        "Luxemburgo",
        "Madagascar",
        "Malasia",
        "Malaui",
        "Maldivas",
        "Malí",
        "Malta",
        "Marruecos",
        "Mauricio",
        "Mauritania",
        "México",
        "Micronesia",
        "Moldavia",
        "Mónaco",
        "Mongolia",
        "Montenegro",
        "Mozambique",
        "Namibia",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "Níger",
        "Nigeria",
        "Noruega",
        "Nueva Zelanda",
        "Omán",
        "Países Bajos",
        "Pakistán",
        "Palaos",
        "Panamá",
        "Papúa Nueva Guinea",
        "Paraguay",
        "Perú",
        "Polonia",
        "Portugal",
        "Reino Unido",
        "República Centroafricana",
        "República Checa",
        "República Democrática del Congo",
        "República Dominicana",
        "República del Congo",
        "Ruanda",
        "Rumanía",
        "Rusia",
        "Samoa",
        "San Cristóbal y Nieves",
        "San Marino",
        "San Vicente y las Granadinas",
        "Santa Lucía",
        "Santo Tomé y Príncipe",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leona",
        "Singapur",
        "Siria",
        "Somalia",
        "Sri Lanka",
        "Suazilandia",
        "Sudáfrica",
        "Sudán",
        "Sudán del Sur",
        "Suecia",
        "Suiza",
        "Surinam",
        "Tailandia",
        "Tanzania",
        "Tayikistán",
        "Timor Oriental",
        "Togo",
        "Tonga",
        "Trinidad y Tobago",
        "Túnez",
        "Turkmenistán",
        "Turquía",
        "Tuvalu",
        "Ucrania",
        "Uganda",
        "Uruguay",
        "Uzbekistán",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Yibuti",
        "Zambia",
        "Zimbabue",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "nacionalidad",
      label: "Nacionalidad",
      options: ["Colombiano", "Extranjero"].map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "tipo_vivienda",
      label: "Tipo de Vivienda",
      options: ["Propia", "Arrendada", "Familiar"].map((option) => ({
        value: option,
        label: option,
      })),
    },
  ];
  const initialValues = {};
  fields.forEach((field) => {
    initialValues[field.name] = field.type === "number" ? 0 : "";
  });

  return (
    <>
      {showFirstSection && (
        <Formik
          validationSchema={schema}
          initialValues={formValues || initialValues}
          onSubmit={(values) => {
            setFormValues({ ...formValues, ...values });

            setShowSecondSection(true);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <>
              {formSubmitted && Object.keys(errors).length > 0 && (
                <Alert variant="danger" className="text-black">
                  Por favor, completa todos los campos antes de enviar el
                  formulario.
                </Alert>
              )}
              <Form
                noValidate
                onSubmit={(event) => {
                  handleSubmit(event);
                  setFormSubmitted(true);
                }}
              >
                <Row>
                  {fields.map((field) => (
                    <Col md={6} key={field.name}>
                      <Form.Group
                        controlId={`validationFormik${field.name}`}
                        className="group mb-2"
                      >
                        <Form.Label className="color-label">
                          {field.label}
                        </Form.Label>
                        {field.options ? (
                          <Form.Select
                            name={field.name}
                            className="form-control-register"
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              (touched[field.name] || formSubmitted) &&
                              !!errors[field.name]
                            }
                            defaultValue={"DEFAULT"}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona una opción
                            </option>
                            {field.options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            type={field.type || "text"}
                            name={field.name}
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              (touched[field.name] || formSubmitted) &&
                              !!errors[field.name]
                            }
                          />
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors[field.name]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ))}
                </Row>

                <Button type="submit" className="bg-blue">
                  Continuar
                </Button>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormFirstSection;
