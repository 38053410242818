import React from "react";
import { Button, Modal } from "react-bootstrap";
import Loading from "../Loading";
import { useDeletModal } from "./useDeleteModal";
/**
 * Generates a modal component for deleting an employee with the option to confirm or cancel the action.
 *
 * @param {Function} onClose - Function to close the modal
 * @param {Object} employee - The employee object to be deleted
 * @param {Function} onUpdate - Function to update employee data after deletion
 * @return {JSX.Element} The modal component for deleting an employee
 */
const DeletModal = ({ onClose, employee, onUpdate }) => {
  const { stateEmployee, changeEmployeeStatus, isLoading } = useDeletModal({
    employee,
    onUpdate,
    onClose,
  });
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{employee.attributes.nombre}</Modal.Title>
      </Modal.Header>
      <Loading isLoading={isLoading} />

      <Modal.Body>
        <p>
          ¿Deseas {stateEmployee} a{" "}
          <strong>{employee.attributes.nombre}?</strong>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button className="bg-red" onClick={onClose}>
          No
        </Button>
        <Button
          className="bg-blue"
          onClick={changeEmployeeStatus}
          disabled={isLoading}
        >
          Si
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletModal;
