import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useFormDemographic } from "./useFormDemographic";

const FormDemographic = () => {
  const {
    initialValues,
    onSubmit,
    schema,
    error,
    isSubmitting,
    setError,
    setIsSubmitting,
  } = useFormDemographic();
  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group
              as={Col}
              md="12"
              controlId="validationFormik02"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Número de identificación
              </Form.Label>
              <Form.Control
                type="text"
                name="numero_identificacion"
                className="form-control-register"
                value={values.numero_identificacion}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.numero_identificacion}
                isValid={
                  touched.numero_identificacion && !errors.numero_identificacion
                }
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.numero_identificacion}
              </Form.Control.Feedback>
            </Form.Group>

            <Col md="12">
              <Button
                type="submit"
                disabled={isSubmitting}
                className="bg-blue my-2"
              >
                {isSubmitting ? "Iniciando..." : "Iniciar encuesta"}
              </Button>
            </Col>
          </Row>

          {error && (
            <Row className="mb-3">
              <Col>
                {error.type === "completed" ? (
                  <p className="text-success">{error.message}</p>
                ) : (
                  <>
                    <p className="text-danger">{error.message}</p>
                    {error.type === "Error" && (
                      <p>
                        Registre sus datos antes de iniciar la encuesta
                        sociodemográfica{" "}
                        <a href="/registro" target="_blank">
                          aquí
                        </a>
                      </p>
                    )}
                  </>
                )}
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FormDemographic;
