import axios from "axios";

/**
 * The axios instance configured with the base URL.
 * @type {AxiosInstance}
 */
const apiService = axios.create({
  baseURL: "https://th.contactcentergrupo.com/api/public/api/v1/",
  //baseURL: "http://localhost:8000/api/v1/",
});

const token = localStorage.getItem("token");

/**
 * Retrieves the token from local storage and sets it as the Authorization header for all requests.
 */
if (token) {
  apiService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

/**
 * Interceptor to handle unauthorized (401) responses.
 * @param {Object} response The HTTP response object.
 * @returns {Object} The response object.
 * @throws {Error} If the response status is 401, removes the token from local storage and redirects to the login page.
 */
apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
export default apiService;
