import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { BsHeadset } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi2";
import { BiSolidContact } from "react-icons/bi";
import { BsArrowReturnLeft } from "react-icons/bs";
import { useSideBar } from "./useSideBar";
import logo from "../../Assets/logo-header.png";
import "./sideBar.css";

/**
 * Renders the Sidebar component with navigation dropdowns for campaigns and areas.
 *
 * @param {Object} setShow - Function to set the show state
 * @param {Function} onMenuClick - Function to handle menu clicks
 * @return {JSX.Element} The rendered Sidebar component
 */
const Sidebar = ({ setShow, onMenuClick }) => {
  const { campaings, areas, handleMenuClick } = useSideBar({
    setShow,
    onMenuClick,
  });
  /**
   * Renders a navigation dropdown with the provided icon, title, items, and clickHandler.
   *
   * @param {type} icon - Description of icon parameter
   * @param {type} title - Description of title parameter
   * @param {type} items - Description of items parameter
   * @param {type} clickHandler - Description of clickHandler parameter
   * @return {type} Description of the return value
   */
  const renderNavDropdown = (icon, title, items, clickHandler) => (
    <NavDropdown
      className="text-white"
      title={
        <>
          {icon} <span className="text-white lead">{title}</span>
        </>
      }
    >
      {items.map((item) => (
        <NavDropdown.Item
          key={item.id}
          href="#"
          className="text-center"
          onClick={() => clickHandler(item.id, item.type, item.attributes.name)}
        >
          {item.attributes.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );

  return (
    <nav className="side-nav">
      <ul className="nav-menu d-flex flex-column">
        <Navbar.Brand
          href="/home"
          className="d-flex justify-content-center align-items-center"
        >
          <img src={logo} alt="Logo" className="img-fluid" />
        </Navbar.Brand>

        {renderNavDropdown(
          <BsHeadset size={25} className="mx-2" />,
          "Campañas",
          campaings,
          handleMenuClick
        )}

        {renderNavDropdown(
          <HiUserGroup size={25} className="mx-2" />,
          "Áreas",
          areas,
          handleMenuClick
        )}

        <Nav.Item className="text-white">
          <Nav.Link
            href="#"
            onClick={() => handleMenuClick(0, "general", "General")}
          >
            <BiSolidContact size={25} className="mx-2" />
            <span className="text-white lead">General</span>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className="text-white mt-auto pb-5">
          <Nav.Link href="/home">
            <BsArrowReturnLeft size={25} className="mx-5" />
          </Nav.Link>
        </Nav.Item>
      </ul>
    </nav>
  );
};

export default Sidebar;
