import { Button, Modal, Form, Row, Col, Alert } from "react-bootstrap";
import Loading from "../Loading";
import { useModalSocialDemographic } from "./useModalSocialDemographic";
import { Formik } from "formik";

/**
 * ModalEdit component for editing employee details.
 * @param {Object} props Props passed to the component.
 * @param {Function} props.onClose Callback function to close the modal.
 * @param {Object} props.employee The employee object to be edited.
 * @param {Function} props.onUpdate Callback function to trigger when the employee data is updated.
 * @returns {JSX.Element} The ModalEdit component.
 */
const ModalSocialDemographic = ({ onClose, employee, onUpdate }) => {
  const {
    isLoading,
    showForm,
    schema,
    areValuesEqual,
    initialValues,
    setErrorMessage,
    errorMessage,
    setShowForm,
    setIsLoading,
    getChangedValues,
    setFormSubmitted,
    formSubmitted,
    fields,
    handleSubmitSocialDemographic,
  } = useModalSocialDemographic({
    employee,
    onUpdate,
    onClose,
  });

  return (
    <Modal
      show={true}
      onHide={onClose}
      id="custom-modal"
      dialogClassName="my-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{employee.attributes.nombre}</Modal.Title>
      </Modal.Header>
      <Loading isLoading={isLoading} />
      <Modal.Body>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        {showForm && (
          <Formik
            validationSchema={schema}
            initialValues={employee.attributes}
            onSubmit={(values) => handleSubmitSocialDemographic(
              values,
              initialValues,
              areValuesEqual,
              getChangedValues,
              employee,
              setErrorMessage,
              setShowForm,
              setIsLoading,
              onClose,
              onUpdate,
              setFormSubmitted
            )}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <Row className="mb-2">
                  {fields.map((field, index) => (
                    <Col
                      md={field.name === "tiempo_libre_actividad" ? 12 : 6}
                      key={index}
                    >
                      <Form.Group controlId={field.name} className="group mb-2">
                        {field.type !== "boolean" && (
                          <Form.Label className="color-label">
                            {field.label}
                          </Form.Label>
                        )}
                        {field.type === "select" ? (
                          <Form.Select
                            as="select"
                            name={field.name}
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              (formSubmitted || touched[field.name]) &&
                              !!errors[field.name]
                            }
                            defaultValue="DEFAULT"
                          >
                            <option value="DEFAULT" disabled>
                              Seleccionar una opción
                            </option>
                            {field.options.map((option, optionIndex) => (
                              <option key={optionIndex} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        ) : field.type === "boolean" ? (
                          <Form.Check
                            type="checkbox"
                            name={field.name}
                            checked={values[field.name]}
                            onChange={(e) => {
                              setFieldValue(field.name, e.target.checked);
                            }}
                            onBlur={handleBlur}
                            isInvalid={
                              (formSubmitted || touched[field.name]) &&
                              !!errors[field.name]
                            }
                            label={field.label}
                          />
                        ) : (
                          <Form.Control
                            type={field.type}
                            name={field.name}
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={
                              (field.name === "detalle_deporte" &&
                                values["practica_deporte"] === "false") ||
                              (field.name === "frcuencia_alcoholica" &&
                                values["consume_bebidas_alcoholicas"] ===
                                  "false") ||
                              (field.name === "frecuencia_fuma" &&
                                values["fuma"] === "false") ||
                              (field.name === "detalle_enfermedad" &&
                                values["le_han_diagnosticado_enfermedad"] ===
                                  "false") ||
                              (field.name === "detalle_enfermedad_patologica" &&
                                values[
                                  "tiene_antecedentes_familiares_patologicos"
                                ] === "false") ||
                              (field.name === "detalle_medicamento" &&
                                values["consume_medicamento"] === "false") ||
                              (field.name === "detalle_alergia" &&
                                values["sufre_alergias"] === "false")
                            }
                            isInvalid={
                              (formSubmitted || touched[field.name]) &&
                              !!errors[field.name]
                            }
                          />
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors[field.name]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ))}
                </Row>
                <Modal.Footer className="mt-2">
                  <Button variant="secondary" onClick={onClose}>
                    Cancelar
                  </Button>
                  <Button
                    className="bg-blue"
                    type="submit"
                    disabled={isLoading}
                  >
                    Guardar Cambios
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalSocialDemographic;
