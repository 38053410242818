import { getLogs } from "../../services/Logs";
import { useState } from "react";
/**
 * Generates a custom hook for handling logs data. It includes functions for fetching logs, handling date changes, and formatting dates.
 *
 * @param {Object} closeModal - Function to close a modal
 * @return {Object} An object containing isLoading state, handleSubmit function, date state, logs state, formatDate function, and handleDateChange function
 */
const useListLogs = ({ closeModal }) => {
  const currentDate = new Date().toISOString().slice(0, 10);
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [date, setDate] = useState({
    start_date: currentDate,
    end_date: currentDate,
  });
  /**
   * Fetches logs data and updates the state accordingly.
   *
   */
  const fetchLogs = async () => {
    try {
      const response = await getLogs(date);

      if (response.success) {
        setLogs(response.data.data);
      } else {
        console.error("Error al obtener los logs:", response.error);
      }
    } catch (error) {
      console.error("Error al obtener los logs:", error);
    } finally {
      setIsLoading(false);
    }
  };
  /**
   * A function that handles the date change event.
   *
   * @param {Object} event - the event object
   * @return {void}
   */
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setDate({ ...date, [name]: value });
  };
  /**
   * A description of the entire function.
   *
   * @param {type} event - description of parameter
   * @return {type} description of return value
   */
  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    fetchLogs();
  };
  /**
   * Formats a given date string into a specific date and time format.
   *
   * @param {string} dateString - The date string to be formatted.
   * @return {string} The formatted date string in the specified format.
   */
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return {
    isLoading,
    handleSubmit,
    date,
    logs,
    formatDate,
    handleDateChange,
  };
};
export { useListLogs };
