import React from "react";
import { Container, Image } from "react-bootstrap";
import robot from "../../Assets/robot-thanks.png";

/**
 * Componente para completar formularios.
 *
 * @param {string} title El título del mensaje de agradecimiento.
 * @param {string} description La descripción del mensaje de agradecimiento.
 * @return {JSX.Element} Componente renderizado con un mensaje de agradecimiento y una imagen.
 */
const CompleteForms = ({ title, description, company }) => {
  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      fluid
    >
      <div style={{ width: "45%" }} className="my-5">
        <h1 className="text-blue fw-bold display-4 text-center mt-3">
          {title}
        </h1>
        <p className="text-center text-blue fs-2 fw-bold">
          {description}
        </p>
      </div>

      <Image src={robot} style={{ width: "40%" }} alt="Mascota ccg" />

    </Container>
  );
};

export default CompleteForms;
