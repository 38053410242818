import { useLocation } from "react-router-dom";


/**
 * Function to handle user permissions and retrieve update password flag.
 *
 * @return {Object} Object containing permissions and update password flag
 */
const useHome = () => {
    const role = localStorage.getItem("role");
    const isAdmin = role === "Administrador";
    const canViewRH = isAdmin || role === "Recursos Humanos" || role === "Visualizador";
    const canViewSgsst = isAdmin || role === "Seguridad y Salud en el Trabajo" || role === "Recursos Humanos";
    const canViewComunicaciones = isAdmin || role === "Comunicaciones";
    const canViewSupport = isAdmin || role === "Soporte";
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const updatePassword = searchParams.get("updatePassword");

    return {
        canViewRH,
        canViewSgsst,
        canViewComunicaciones,
        canViewSupport,
        updatePassword,
    };
};

export { useHome };
