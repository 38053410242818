import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Loading from "../Loading";
import { useChangePassword } from "./useChangePassword";

/**
 * @component
 * 
 * Component for changing password.
 *
 * @param {Object} email - The email of the user
 * @param {string} token - The token for password reset
 * @param {boolean} show - Flag to show/hide the modal
 * @param {function} handleClose - Function to handle modal close
 * @return {JSX.Element} A modal for changing password
 */
const ChangePassword = ({ email, token, show, handleClose }) => {
  const {
    newPassword,
    password_confirmation,
    isLoading,
    handleSubmit,
    handlePasswordChange,
    handlepasswordconfirmationChange,
  } = useChangePassword({ token, email,handleClose }); 

  return (
    <Modal show={show} centered keyboard={false} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Cambiar contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingresa tu nueva contraseña"
              value={newPassword}
              onChange={handlePasswordChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirmar nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirma tu nueva contraseña"
              value={password_confirmation}
              onChange={handlepasswordconfirmationChange}
            />
          </Form.Group>

          <Loading isLoading={isLoading} />
          <Button
            type="submit"
            className="btn bg-blue text-white w-100 mt-3 mx-auto"
          >
            Cambiar contraseña
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export { ChangePassword };
