import React, { useState, useEffect } from "react";
import { getChildrenByEmployeeId } from "../../services/ChildrenService";
/**
 * Generates a custom hook to fetch and manage children data for a given employee.
 *
 * @param {object} employee - The employee object to fetch children for.
 * @return {object} An object containing isLoading state, children data, and a function to calculate age.
 */
const useModalChildren = ({ employee }) => {
  const [children, setChildren] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChildren = async () => {
      try {
        const response = await getChildrenByEmployeeId(employee.id);
        setChildren(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching children:", error);
      }
    };

    fetchChildren();
  }, [employee.id]);
  /**
   * Calculate the age based on the provided date of birth.
   *
   * @param {Date} fechaNacimiento - the date of birth to calculate age from
   * @return {Object} an object containing the calculated age in years and months
   */
  const calculateAge = (fechaNacimiento) => {
    const hoy = new Date();
    const fechaNac = new Date(fechaNacimiento);
    let edadAnios = hoy.getFullYear() - fechaNac.getFullYear();
    let edadMeses = hoy.getMonth() - fechaNac.getMonth();
    if (
      edadMeses < 0 ||
      (edadMeses === 0 && hoy.getDate() < fechaNac.getDate())
    ) {
      edadAnios--;
      edadMeses += 12;
    }
    return { anios: edadAnios, meses: edadMeses };
  };
  return {
    isLoading,
    children,
    calculateAge,
  };
};
export { useModalChildren };
