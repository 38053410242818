import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addEmployee } from "../../services/EmployeeService";
import { addChildren } from "../../services/ChildrenService";
import { getCampaingsActive } from "../../services/CampaingService";
import { getActiveAreas } from "../../services/AreaService";
import { alerts } from "../../utils/Notifications";
import * as yup from "yup";
/**
 * Generates a custom hook for handling form registration.
 *
 * @return {object} Object containing initial values, form submission function, form validation schema, submission status, areas list, campaigns list, function to handle changes in educational level, and a boolean to show/hide title field.
 */
const useFormRegister = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [areas, setAreas] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const initialValues = {
    nombre: "",
    numero_identificacion: "",
    tipo_identificacion: "DEFAULT",
    correo_electronico: "",
    ciudad: "",
    direccion: "",
    telefono: "",
    fecha_nacimiento: "",
    lugar_nacimiento: "",
    fecha_expedicion: "",
    lugar_expedicion: "",
    nombre_contacto: "",
    telefono_contacto: "",
    genero: "DEFAULT",
    profesion: "",
    tiene_hijo: "DEFAULT",
    cantidad_hijos: 0,
    area: "",
    campaing: "",
    tipo_contrato: "DEFAULT",
    nivel_educativo: "DEFAULT",
    terminos: false,
  };
  const [showTitleField, setShowTitleField] = useState(false);
  const [values, setValues] = useState(initialValues);

  /**
   * A function that handles the change of the educational level.
   *
   * @param {Event} event - The event that triggered the change
   * @return {void}
   */
  const handleChangeNivelEducativo = (event) => {
    const selectedNivel = event.target.value;
    setShowTitleField(
      ["Posgrado", "Profesional", "Tecnólogo", "Técnico"].includes(
        selectedNivel
      )
    );
  };

  /**
   * A function that adjusts the initial values based on the number of children.
   *
   * @param {object} initialValues - the initial values object
   * @param {number} cantidad_hijos - the number of children
   * @return {object} the updated initial values object with children array
   */
  const adjustInitialValues = (initialValues, cantidad_hijos) => {
    const hijos = [];
    for (let i = 0; i < cantidad_hijos; i++) {
      hijos.push({
        nombre: initialValues[`nombre_hijo_${i + 1}`] || "",
        identificacion: initialValues[`identificacion_hijo_${i + 1}`] || "",
        fecha_nacimiento: initialValues[`fecha_nacimiento_hijo_${i + 1}`] || "",
      });
    }
    return { ...initialValues, hijos };
  };

  /**
   * A function that handles form submission, processes the data, and displays alerts accordingly.
   *
   * @param {object} values - The values submitted in the form
   * @return {void}
   */
  const onSubmit = async (values) => {
    setSubmitted(true);
    setIsSubmitting(true);
    try {
      alerts("info", "Procesando solicitud");

      let areaNumber = values.area === "N/A" ? null : parseInt(values.area);
      let campaignNumber =
        values.campaing === "N/A" ? null : parseInt(values.campaing);

      const { cantidad_hijos, ...formDataWithoutChildren } = values;

      const hijos = Array.from({ length: values.cantidad_hijos }, (_, i) => ({
        nombre: values[`nombre_hijo_${i + 1}`],
        identificacion: values[`identificacion_hijo_${i + 1}`],
        fecha_nacimiento: values[`fecha_nacimiento_hijo_${i + 1}`],
      }));
      const hijosDiligenciados = hijos.every(
        (hijo) => hijo.nombre && hijo.identificacion && hijo.fecha_nacimiento
      );
      if (!hijosDiligenciados) {
        alerts(
          "error",
          "Por favor diligencia todos los datos de los hijos",
          3000
        );
        setIsSubmitting(false);
        return;
      }

      const response = await addEmployee({
        ...formDataWithoutChildren,
        area_id: areaNumber,
        campaing_id: campaignNumber,
        cantidad_hijos: cantidad_hijos,
      });
   
      if (response.success) {
        const employee_id = response.data;

        await Promise.all(
          hijos.map((child) => addChildren({ ...child, employee_id }))
        );

        alerts("success", "Tus datos han sido registrados", 3000);
        navigate("/gracias");
      } else {
        if (response.errors) {
          Object.values(response.errors).forEach((error) => {
            alerts("error", error, 3000);
          });
        } else {
          alerts("error", "Error al procesar la solicitud", 3000);
        }
      }
    } catch (error) {
      alerts("error", "Error al procesar la solicitud", 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

 

  const schema = yup.object().shape({
    nombre: yup.string().required("El nombre es obligatorio"),
    tipo_identificacion: yup
      .string()
      .required("El tipo de identificación es obligatorio"),
    numero_identificacion: yup
      .string()
      .required("El número de identificación es obligatorio")
      .matches(
        /^\d+$/,
        "El número de identificación debe contener solo números"
      ),
    correo_electronico: yup
      .string()
      .email("El correo electrónico debe ser valido")
      .required("El correo electrónico es obligatorio"),
    ciudad: yup.string().required("La ciudad es obligatoria"),
    direccion: yup.string().required("La dirección es obligatorio"),
    lugar_nacimiento: yup
      .string()
      .required("El lugar de nacimiento es obligatorio"),
    fecha_expedicion: yup
      .date()
      .required("La fecha de expedición es obligatorio"),
    lugar_expedicion: yup
      .string()
      .required("El lugar de expedición es obligatorio"),
    nombre_contacto: yup
      .string()
      .required("El nombre del contacto es obligatorio"),
    telefono_contacto: yup
      .string()
      .required("El Teléfono de contacto es obligatorio"),
    telefono: yup
      .string()
      .required("El teléono es obligatorio")
      .matches(/^\d{10}$/, "El teléfono debe tener 10 dígitos numéricos"),
    fecha_nacimiento: yup
      .date()
      .required("La fecha de nacimiento es obligatorio"),
    genero: yup.string().required("El genero es obligatorio"),
    nivel_educativo: yup.string().required("El nivel educativo es obligatorio"),
    profesion: yup.string().when("nivel_educativo", {
      is: (nivel) =>
        nivel === "Técnico" ||
        nivel === "Tecnólogo" ||
        nivel === "Profesional" ||
        nivel === "Postgrado",
      then: (schema) => schema.required("La profesión es requerida"),
    }),
    tiene_hijo: yup.boolean().required("Este campo es obligatorio"),
    cantidad_hijos: yup.number().when("tiene_hijo", {
      is: true,
      then: (schema) =>
        schema
          .required()
          .min(1, "debe tener 1")
          .test(
            "cantidad_hijos-conditional",
            "Solo se permiten números",
            (value, context) => {
              return !(/\D/.test(value) || /^\s*$/.test(value));
            }
          ),
    }),

    terminos: yup.bool().required().oneOf([true], "Debes aceptar los términos"),
    area: yup.string().required("El área es obligatoria"),
    campaing: yup.string().required("La campaña es obligatoria"),
    tipo_contrato: yup.string().required("El tipo de contrato es obligatorio"),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [areaResponse, campaignResponse] = await Promise.all([
          getActiveAreas(),
          getCampaingsActive(),
        ]);
        setAreas(areaResponse.data);
        setCampaigns(campaignResponse.data);
      } catch (e) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    const updatedInitialValuesWithChildren = adjustInitialValues(
      initialValues,
      values.cantidad_hijos
    );
    setValues(updatedInitialValuesWithChildren);
  }, [initialValues.cantidad_hijos]);

  return {
    initialValues,
    onSubmit,
    schema,
    submitted,
    isSubmitting,
    areas,
    campaigns,
    handleChangeNivelEducativo,
    showTitleField,
  };
};

export { useFormRegister };
