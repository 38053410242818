import React, { useState } from "react";
import { Col, Form, Button, Row, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

const FormSecondSection = ({
  showSecondSection,
  setShowThirdSection,
  setShowFirstSection,
  formValues,
  setFormValues,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fields = [
    {
      name: "cargo",
      label: "Cargo",
      type: "text",
    },
    {
      name: "experiencia_cargo",
      label: "Experiencia en el cargo",
      type: "select",
      options: [
        { value: "Sin experiencia", label: "Sin experiencia" },
        { value: "1-6 meses", label: "1-6 meses" },
        { value: "7-11 meses", label: "7-11 meses" },
        { value: "1-2 años", label: "1-2 años" },
        { value: "3-5 años", label: "3-5 años" },
        { value: "más de 5 años", label: "más de 5 años" },
      ],
    },
    {
      name: "eps",
      label: "¿Cuál es tu EPS?",
      type: "select",
      options: [
        "Empresas Públicas de Medellín Departamento Médico",
        "Fondo de Pasivo Social de Ferrocarriles",
        "Aliansalud EPS",
        "Salud Total S.A.",
        "E.P.S Sanitas",
        "Compensar Entidad Promotora de Salud",
        "EPS Sura",
        "Comfenalco Valle EPS",
        "Famisanar",
        "Servicio Occidental de Salud S.O.S. S.A.",
        "Nueva EPS",
        "Caja de Compensación Familiar de Antioquía - Comfama - Hoy Savia Salud EPS",
        "Nueva EPS S.A. Movilidad",
        "Cooperativa de Salud y Desarrollo Integral de la Zona Sur Oriental de Cartagena Ltda. Coosalud E.S.S.",
        "Fundación Salud MIA EPS",
        "Asociación Mutual Ser Empresa Solidaria De Salud  Eps-S - Mutual Ser Eps-S",
        "Fondo de Solidaridad y Garantía Fosyga",
        "Entidad Administradora de Régimen Subsidiado Convida",
        "Capresoca EPS",
        "Capital Salud EPSS S.A.S.",
        "Asociación de Cabildos Indígenas del Cesar “Dusakawi”",
        'Asociación de Cabildos Indígenas del Resguardo Indígena Zenú de San Andrés de Sotavento Córdoba - Sucre "Manexka"',
        "Asociación Indígena del Cauca - A.I.C.",
        "Entidad Promotora de Salud Anas Wayuu EPSI",
        "Entidad Promotora de Salud Mallamas EPSI",
        "Entidad Promotora de Salud Pijaosalud EPSI",
        "Asociación Mutual Ser Empresa Solidaría de Salud ESS",
        "Sociedad Simplificada por Acciones Emssanar S.A.S.",
        "Asociación Mutual La Esperanza Asmet Salud",
        "Salud Bolivar EPS",
        "Comfachoco – CCF del Chocó",
        "EPS FAMILIAR DE COLOMBIA S.A.S",
        "EPS-S Comfaoriente",
        "CAJACOPI EPS S.A.S. ",
        "Administradora",
        "Universidad del Atlántico",
        "Universidad Industrial de Santander",
        "Universidad del Valle",
        "Universidad Nacional de Colombia",
        "Universidad del Cauca",
        "Universidad de Cartagena",
        "Universidad de Antioquia",
        "Universidad de Córdoba",
        "Universidad de Nariño",
        "Universidad Pedagógica y Tecnológica de Colombia - UPTC",
      ].map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "arl",
      label: "¿Cuál es tu ARL?",
      type: "select",
      options: [
        "A.R.L. Seguros de Vida Colpatria S.A.",
        "Compañía de Seguros Bolívar S.A.",
        "Seguros de Vida Aurora",
        "Seguros de Vida Suramericana S.A.",
        "ARP Alfa",
        "Positiva Compañía de Seguros",
        "Colmena Riesgos Profesionales",
        "La Equidad Seguros de Vida",
        "Mapfre Colombia Vida Seguros S.A",
        "ARL de la Compañía de Seguros “Colsanitas S.A",
        "Fiduciaria La Previsora S.A  E.F. FONDO RIESGOS LAB 2019 – UGPP ",
      ].map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "modalidad_trabajo",
      label: "Modalidad de trabajo",
      type: "select",
      options: ["Presencial", "Virtual", "Híbrido"].map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "tiene_personas_a_cargo",
      label: "¿Tienes personas a cargo?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "cantidad_personas_a_cargo",
      label: "Cantidad de personas a cargo",
      type: "number",
    },
    {
      name: "discapacidad",
      label: "¿Tienes alguna discapacidad?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "tipo_discapacidad",
      label: "¿Cuál es tu tipo de discapacidad?",
      type: "text",
    },
    {
      name: "persona_pensionado",
      label: "¿Es persona pensionada?",
      type: "boolean",
    },
    {
      name: "gestantes",
      label: "¿Estás embarazada?",
      type: "boolean",
    },
    {
      name: "lactancia",
      label: "¿Estás lactando?",
      type: "boolean",
    },
  ];
  const initialValues = {};
  fields.forEach((field) => {
    initialValues[field.name] = field.type === "number" ? 0 : "";
  });

  const schema = yup.object().shape({
    cargo: yup.string().required("Campo requerido"),
    experiencia_cargo: yup.string().required("Campo requerido"),
    eps: yup.string().required("Campo requerido"),
    arl: yup.string().required("Campo requerido"),
    modalidad_trabajo: yup.string().required("Campo requerido"),
    tiene_personas_a_cargo: yup.string().required("Campo requerido"),
    discapacidad: yup.string().required("Campo requerido"),

    cantidad_personas_a_cargo: yup.number().typeError("Debe ser un número"),
  });

  return (
    <>
      {showSecondSection && (
        <Formik
          validationSchema={schema}
          initialValues={formValues}
          onSubmit={(values) => {
            if (
              values["discapacidad"] === "1" &&
              !values["tipo_discapacidad"]
            ) {
              setErrorMessage(
                "Por favor, completa el campo ¿Cuál es tu tipo de discapacidad?"
              );
            } else if (
              values["tiene_personas_a_cargo"] === "1" &&
              !values["cantidad_personas_a_cargo"]
            ) {
              setErrorMessage(
                "Por favor, completa el campo Cantidad de personas a cargo"
              );
            } else {
              let errorMessages = [];
              if (
                values["discapacidad"] === "1" &&
                !values["tipo_discapacidad"]
              ) {
                errorMessages.push(
                  "Por favor, completa el campo ¿Cuál es tu tipo de discapacidad?"
                );
              }
              if (
                values["tiene_personas_a_cargo"] === "1" &&
                !values["cantidad_personas_a_cargo"]
              ) {
                errorMessages.push(
                  "Por favor, completa el campo Cantidad de personas a cargo"
                );
              }

              if (errorMessages.length > 0) {
              } else {
                setFormValues({ ...formValues, ...values });
                setErrorMessage(null);
                setShowThirdSection(true);
              }
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <>
              {errorMessage && (
                <Alert variant="danger" className="text-black">
                  {errorMessage}
                </Alert>
              )}
              {formSubmitted && Object.keys(errors).length > 0 && (
                <Alert variant="danger" className="text-black">
                  Por favor, completa todos los campos antes de enviar el
                  formulario.
                </Alert>
              )}

              <Form
                noValidate
                onSubmit={(event) => {
                  handleSubmit(event);
                  setFormSubmitted(true);
                }}
              >
                <Row>
                  {fields.map((field, index) => (
                    <Col
                      md={field.name === "modalidad_trabajo" ? 12 : 6}
                      key={index}
                    >
                      {
                        <Form.Group
                          controlId={field.name}
                          className="group mb-2"
                        >
                          {field.type !== "boolean" && (
                            <Form.Label className="color-label">
                              {field.label}
                            </Form.Label>
                          )}
                          {field.type === "select" ? (
                            <Form.Select
                              name={field.name}
                              value={values[field.name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                (formSubmitted || touched[field.name]) &&
                                !!errors[field.name]
                              }
                              isValid={
                                touched[field.name] && !errors[field.name]
                              }
                              defaultValue={"DEFAULT"}
                            >
                              <option value="DEFAULT" disabled>
                                Selecciona una opción
                              </option>
                              {field.options.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          ) : field.type === "boolean" ? (
                            <Form.Check
                              type="checkbox"
                              label={field.label}
                              name={field.name}
                              checked={values[field.name]}
                              onChange={(e) => {
                                const { name, checked } = e.target;
                                if (values[name] !== checked) {
                                  setFormValues((prevValues) => ({
                                    ...prevValues,
                                    [name]: checked,
                                  }));
                                }
                              }}
                              onBlur={handleBlur}
                              isInvalid={
                                (formSubmitted || touched[field.name]) &&
                                !!errors[field.name]
                              }
                            />
                          ) : (
                            <Form.Control
                              type={field.type}
                              name={field.name}
                              value={values[field.name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={
                                (field.name === "cantidad_personas_a_cargo" &&
                                  values["tiene_personas_a_cargo"] ===
                                    "false") ||
                                (field.name === "tipo_discapacidad" &&
                                  values["discapacidad"] === "false")
                              }
                              isInvalid={
                                (formSubmitted || touched[field.name]) &&
                                !!errors[field.name]
                              }
                              isValid={
                                touched[field.name] && !errors[field.name]
                              }
                            />
                          )}
                          <Form.Control.Feedback type="invalid">
                            {errors[field.name]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      }
                    </Col>
                  ))}
                </Row>

                <Button
                  type="button"
                  onClick={() => setShowFirstSection(true)}
                  className="bg-blue mx-3"
                >
                  Volver
                </Button>
                <Button type="submit" className="bg-blue">
                  Continuar
                </Button>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormSecondSection;
