import React from "react";
import { CSVLink } from "react-csv";
import { Container, Table, Form, Row, Col } from "react-bootstrap";
import { TbReload } from "react-icons/tb";
import { IoMdCloudDownload } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { BsToggle2Off, BsToggle2On } from "react-icons/bs";
import User from "../User";
import ModalSupport from "../ModalSupport";
import Loading from "../Loading";
import { useListSupport } from "./useListSupport";

import "../ListGeneral/ListGeneral.css";

/**
 * Renders the ListGernal component.
 *
 * @param {Object} id - The id of the communication
 * @param {string} type - The type of the communication
 * @param {string} name - The name of the communication
 * @return {JSX.Element} The rendered ListComunications component
 */
const ListGeneral = ({ id, type, name }) => {
  const role = localStorage.getItem("role");
  const hasPermissionToEdit = role === "Visualizador";

  const {
    selectedEmployee,
    setSelectedEmployee,
    setIsEditModalOpen,
    isEditModalOpen,
    isLoading,
    requestSort,
    handleUpdate,
    filterValue,
    filteredEmployees,
    handleFilterChange,
    csvData,
    headersCsv,
    formatDate
  } = useListSupport({
    id,
    type,
    name,
  });

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  return (
    <>
      <Container fluid className="mx-5 my-2">
        <User color="blue" marginX={4} position={true} marginY={2} />

        <h2 className="text-center text-blue">Personal - {name}</h2>

        <section>
          <Row className="justify-content-between mb-1">
            <Col md={4} className="d-flex flex-column">
              <span className="fw-bold text-blue">
                Módulo: Recursos Humanos
              </span>
              <span className="text-blue mb-0">
                Registros: {filteredEmployees.length}
              </span>
            </Col>

            <Col md={8} className="d-flex justify-content-end">
              <Form>
                <Form.Group controlId="filter">
                  <Form.Control
                    style={{
                      width: "400px",
                    }}
                    type="text"
                    placeholder="Filtrar..."
                    value={filterValue}
                    onChange={handleFilterChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </Form>
              <CSVLink
                data={csvData}
                headers={headersCsv}
                filename={"datos_empleados.csv"}
              >
                <IoMdCloudDownload
                  color="#0096C8"
                  size={30}
                  className="mx-1"
                  title="Descargar la información"
                />
              </CSVLink>
              <TbReload
                title="Actualizar la información"
                role="button"
                color="#0096C8"
                size={25}
                className="mx-1"
                onClick={handleUpdate}
              />
            </Col>
          </Row>
        </section>

        <div className="table-container">
          <Table striped bordered hover size="md" className="table-employee">
            <thead>
              <tr className="text-center">
                <th></th>

                <th onClick={() => requestSort("estado")}>Estado</th>
                <th onClick={() => requestSort("id")}>ID</th>
                <th onClick={() => requestSort("nombre")}>Nombre</th>
                <th onClick={() => requestSort("numero_identificacion")}>
                  Número de Identificación
                </th>
                <th onClick={() => requestSort("campaing")}>Campaña</th>
                <th onClick={() => requestSort("area")}>Área</th>
                <th onClick={() => requestSort("correo_electronico")}>
                  Correo electrónico
                </th>
                <th onClick={() => requestSort("correo_corporativo")}>
                  Correo Corporativo
                </th>
                <th onClick={() => requestSort("telefono")}>Teléfono</th>
                <th onClick={() => requestSort("direccion")}>Direccion</th>
                <th onClick={() => requestSort("ciudad")}>Ciudad</th>
                <th onClick={() => requestSort("tiene_silla")}>
                  ¿Tiene silla?
                </th>
                <th onClick={() => requestSort("tiene_computador")}>
                  ¿Tiene computador?
                </th>
                <th onClick={() => requestSort("tiene_mouse")}>
                  ¿tiene mouse?
                </th>
                <th onClick={() => requestSort("tiene_camara")}>
                  ¿tiene camara?
                </th>
                <th onClick={() => requestSort("tiene_teclado")}>
                  ¿tiene teclado?
                </th>
                <th onClick={() => requestSort("tiene_diadema")}>
                  ¿tiene diadema?
                </th>
                <th onClick={() => requestSort("tiene_vpn")}>
                  ¿tiene VPN?
                </th>
                <th onClick={() => requestSort("tiene_compartida")}>
                  ¿tiene Compartida?
                </th>
                <th onClick={() => requestSort("tiene_biometrico")}>
                  ¿tiene biometrico?
                </th>
                <th onClick={() => requestSort("acta")}>Acta de entrega</th>
                <th onClick={() => requestSort("tecnico_asignado")}>
                  Técnico asignado
                </th>
                <th onClick={() => requestSort("fecha_entrega_equipos")}>
                  Fecha entrega
                </th>
                <th onClick={() => requestSort("fecha_devolucion_equipo")}>
                  Fecha devolución
                </th>
                <th onClick={() => requestSort("paz_salvo_TI")}>Paz y Salvo</th>
                <th onClick={() => requestSort("observaciones_TI")}>
                  Observaciones
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployees.map((employee) => (
                <tr key={employee.id}>
                  <td>
                    {!hasPermissionToEdit && (
                      <CiEdit
                        className="icon text-blue"
                        onClick={() => {
                          setIsEditModalOpen(true);
                          setSelectedEmployee(employee);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {employee.attributes.estado === 1 ? (
                      <BsToggle2On color="#0096C8" />
                    ) : (
                      <BsToggle2Off color="#E74242" />
                    )}
                  </td>
                  <td>{employee.id}</td>
                  <td>{employee.attributes.nombre}</td>
                  <td>{employee.attributes.numero_identificacion}</td>
                  <td>{employee.attributes.campaing}</td>
                  <td>{employee.attributes.area}</td>
                  <td>{employee.attributes.correo_electronico}</td>
                  <td>{employee.attributes.correo_corporativo}</td>
                  <td>{employee.attributes.telefono}</td>
                  <td>{employee.attributes.direccion.replace(",", "")}</td>
                  <td>{employee.attributes.ciudad}</td>

                  <td>
                    {employee.attributes.tiene_silla === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>

                  <td>
                    {employee.attributes.tiene_computador === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>

                  <td>
                    {employee.attributes.tiene_mouse === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>

                  <td>
                    {employee.attributes.tiene_camara === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>

                  <td>
                    {employee.attributes.tiene_teclado === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>
                  <td>
                    {employee.attributes.tiene_diadema === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>
                  <td>
                    {employee.attributes.tiene_vpn === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>
                  <td>
                    {employee.attributes.tiene_compartida === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>
                  <td>
                    {employee.attributes.tiene_biometrico === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>

                  <td>{employee.attributes.acta}</td>
                  <td>{employee.attributes.tecnico_asignado}</td>
                  <td>{formatDate(employee.attributes.fecha_entrega_equipos)}</td>
                  <td>{formatDate(employee.attributes.fecha_devolucion_equipo)}</td>
                  <td>
                    {employee.attributes.paz_salvo_TI === 1 ? (
                      <FaCheckCircle color="#83B30E" size={15} />
                    ) : (
                      <IoMdCloseCircleOutline color="#E74242" size={20} />
                    )}
                  </td>
                  <td>{employee.attributes.observaciones_TI}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>

      {isEditModalOpen && (
        <ModalSupport
          onHide={() => setIsEditModalOpen(false)}
          employee={selectedEmployee}
          onUpdate={handleUpdate}
        />
      )}
    </>
  );
};

export default ListGeneral;
