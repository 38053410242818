import { useState } from "react";
import ListGeneral from "../../Components/ListGeneral";
import ListComunications from "../../Components/ListComunications";
import ListDemographic from "../../Components/ListDemographic";
import ListSupport from "../../Components/ListSupport";


/**
 * The `useListPage` function returns an object with `selectedMenuItem`, `handleMenuClick`, and `ListComponent`.
 *
 * @param {Object} listType - The type of list to display.
 * @return {Object} An object containing `selectedMenuItem`, `handleMenuClick`, and `ListComponent`.
 */
const useListPage = ({ listType }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState({
    id: 0,
    type: "general",
    name: "General",
  });
  let ListComponent;
  switch (listType) {
    case "comunications":
      ListComponent = ListComunications;
      break;
    case "humanTalent":
      ListComponent = ListGeneral;
      break;
    case "soporte":
      ListComponent = ListSupport;
      break;
    case "sgsst":
      ListComponent = ListDemographic;
    default:
      break;
  }
  
  /**
   * Handles the click event on the menu item and sets the selected menu item.
   *
   * @param {type} id - Description of id parameter
   * @param {type} type - Description of type parameter
   * @param {type} name - Description of name parameter
   * @return {type} Description of the return value
   */
  const handleMenuClick = (id, type, name) => {
    setSelectedMenuItem({ id, type, name });
  };
  return {
    selectedMenuItem,
    handleMenuClick,
    ListComponent,
  };
};

export { useListPage };
