import React, { useState } from "react";
import {
  Form,
  Button,
  Col,
  Row,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { updateEmployeeSociodemographic } from "../../services/EmployeeService";
import { Formik } from "formik";
import * as yup from "yup";

const FormThirdSection = ({
  showThirdSection,
  setShowSecondSection,
  formValues,
  setFormValues,
  onSubmit,
}) => {
  const employeeId = localStorage.getItem("employeeId");

  const schema = yup.object().shape({
    tiempo_libre_actividad: yup.string().required("Campo requerido"),
    practica_deporte: yup.string().required("Campo requerido"),
    consume_bebidas_alcoholicas: yup.string().required("Campo requerido"),
    fuma: yup.string().required("Campo requerido"),
    le_han_diagnosticado_enfermedad: yup.string().required("Campo requerido"),
    tiene_antecedentes_familiares_patologicos: yup
      .string()
      .required("Campo requerido"),
    consume_medicamento: yup.string().required("Campo requerido"),
    sufre_alergias: yup.string().required("Campo requerido"),
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fields = [
    {
      name: "practica_deporte",
      label: "¿Practica algún deporte?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "detalle_deporte",
      label: "Indica el deporte que practica",
      type: "text",
      warning:
        "Por favor, indica el deporte que practicas si seleccionaste 'Sí'",
    },
    {
      name: "tiempo_libre_actividad",
      label: "Tiempo libre - Actividad",
      type: "text",
    },

    {
      name: "consume_bebidas_alcoholicas",
      label: "¿Consume bebidas alcohólicas?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "frecuencia_alcoholica",
      label: "Frecuencia con que consume",
      type: "select",
      options: [
        { value: "Diario", label: "Diario" },
        { value: "1 o 2 veces por semana", label: "1 o 2 veces por semana" },
        { value: "1 o 2 veces por mes", label: "1 o 2 veces por mes" },
        { value: "ocasionalmente", label: "Ocasionalmente" },
        { value: "Nunca", label: "Nunca" },
      ],
      warning: "Por favor, indica la frecuencia si seleccionaste 'Sí'",
    },
    {
      name: "fuma",
      label: "¿Fuma?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "frecuencia_fuma",
      label: "Frecuencia con que fuma",
      type: "select",
      options: [
        { value: "Diario", label: "Diario" },
        { value: "1 o 2 veces por semana", label: "1 o 2 veces por semana" },
        { value: "1 o 2 veces por mes", label: "1 o 2 veces por mes" },
        { value: "ocasionalmente", label: "Ocasionalmente" },
        { value: "Nunca", label: "Nunca" },
      ],
      warning: "Por favor, indica la frecuencia si seleccionaste 'Sí'",
    },
    {
      name: "le_han_diagnosticado_enfermedad",
      label: "¿Le han diagnosticado alguna enfermedad?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "detalle_enfermedad",
      label: "Indique la enfermedad",
      type: "text",
      warning: "Por favor, indica la enfermedad si seleccionaste 'Sí'",
    },
    {
      name: "tiene_antecedentes_familiares_patologicos",
      label: "¿Tiene antecedentes familiares patológicos?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "detalle_enfermedad_patologica",
      label: "Indique la enfermedad",
      type: "text",
      warning: "Por favor, indica la enfermedad si seleccionaste 'Sí'",
    },
    {
      name: "consume_medicamento",
      label: "¿Consume algún tipo de medicamento?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "detalle_medicamento",
      label: "Indique el medicamento",
      type: "text",
      warning: "Por favor, indica el medicamento si seleccionaste 'Sí'",
    },
    {
      name: "sufre_alergias",
      label: "¿Sufre de alergias?",
      type: "select",
      options: [
        { value: "true", label: "Sí" },
        { value: "false", label: "No" },
      ],
    },
    {
      name: "detalle_alergia",
      label: "Indique la alergia",
      type: "text",
      warning: "Por favor, indica la alergia si seleccionaste 'Sí'",
    },
  ];

  return (
    <>
      {showThirdSection && (
        <Formik
          validationSchema={schema}
          initialValues={formValues}
          onSubmit={async (values) => {
            try {
              const errors = {};

              for (const field of fields) {
                if (
                  (field.name === "detalle_deporte" &&
                    values["practica_deporte"] === "true" &&
                    !values[field.name]) ||
                  (field.name === "frcuencia_alcoholica" &&
                    values["consume_bebidas_alcoholicas"] === "true" &&
                    !values[field.name]) ||
                  (field.name === "frecuencia_fuma" &&
                    values["fuma"] === "true" &&
                    !values[field.name]) ||
                  (field.name === "detalle_enfermedad" &&
                    values["le_han_diagnosticado_enfermedad"] === "true" &&
                    !values[field.name]) ||
                  (field.name === "detalle_enfermedad_patologica" &&
                    values["tiene_antecedentes_familiares_patologicos"] ===
                      "true" &&
                    !values[field.name]) ||
                  (field.name === "detalle_medicamento" &&
                    values["consume_medicamento"] === "true" &&
                    !values[field.name]) ||
                  (field.name === "detalle_alergia" &&
                    values["sufre_alergias"] === "true" &&
                    !values[field.name])
                ) {
                  errors[field.name] = "Campo requerido";
                }
              }

              if (Object.keys(errors).length > 0) {
                setErrorMessage(
                  "Por favor, completa todos los campos antes de enviar el formulario."
                );
              } else {
                setErrorMessage("");
                const updatedFormValues = { ...formValues, ...values };

                for (const key in updatedFormValues) {
                  if (updatedFormValues[key] === "false") {
                    updatedFormValues[key] = false;
                  }
                  if (updatedFormValues[key] === "true") {
                    updatedFormValues[key] = true;
                  }
                }
                updatedFormValues.encuesta_demografica=1
                const response = await updateEmployeeSociodemographic(
                  employeeId,
                  updatedFormValues,
                
                );
                if (response.success) {
                  onSubmit();
                  setFormSubmitted(true);
                } else {
                  console.error("Error al actualizar el empleado:", response);
                }
              }
            } catch (error) {
              console.error("Error al actualizar el empleado:", error);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(event);
                setFormSubmitted(true);
              }}
            >
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Row className="mb-2">
                {fields.map((field, index) => (
                  <Col
                    md={field.name === "tiempo_libre_actividad" ? 12 : 6}
                    key={index}
                  >
                    <Form.Group controlId={field.name} className="group mb-2">
                      <Form.Label className="color-label">
                        {field.label}
                      </Form.Label>
                      {field.type === "select" ? (
                        <Form.Select
                          as="select"
                          name={field.name}
                          value={values[field.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            (formSubmitted || touched[field.name]) &&
                            !!errors[field.name]
                          }
                          defaultValue="DEFAULT"
                        >
                          <option value="DEFAULT" disabled>Seleccionar una opción.</option>
                          {field.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <OverlayTrigger
                          key={index}
                          overlay={<Tooltip>{field.warning}</Tooltip>}
                        >
                          <Form.Control
                            type={field.type}
                            name={field.name}
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={
                              (field.name === "detalle_deporte" &&
                                values["practica_deporte"] === "false") ||
                              (field.name === "frcuencia_alcoholica" &&
                                values["consume_bebidas_alcoholicas"] ===
                                  "false") ||
                              (field.name === "frecuencia_fuma" &&
                                values["fuma"] === "false") ||
                              (field.name === "detalle_enfermedad" &&
                                values["le_han_diagnosticado_enfermedad"] ===
                                  "false") ||
                              (field.name === "detalle_enfermedad_patologica" &&
                                values[
                                  "tiene_antecedentes_familiares_patologicos"
                                ] === "false") ||
                              (field.name === "detalle_medicamento" &&
                                values["consume_medicamento"] == "false") ||
                              (field.name === "detalle_alergia" &&
                                values["sufre_alergias"] === "false")
                            }
                            isInvalid={
                              (formSubmitted || touched[field.name]) &&
                              !!errors[field.name]
                            }
                          />
                        </OverlayTrigger>
                      )}
                      <Form.Control.Feedback type="invalid">
                        {errors[field.name]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                ))}
              </Row>
              <Button
                type="button"
                className="bg-blue"
                onClick={() => setShowSecondSection(true)}
              >
                Volver
              </Button>
              <Button type="submit" className="bg-blue mx-3">
                {formSubmitted && !errorMessage ? "Enviando..." : "Enviar"}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormThirdSection;
