import { useState, useEffect, useRef } from "react";
import { addUser } from "../../services/UserService";
import { createCampaing } from "../../services/CampaingService";
import { alerts } from "../../utils/Notifications";
import { createArea } from "../../services/AreaService";
/**
 * Custom hook for handling modal configuration when adding an item.
 *
 * @param {Object} props - The props object containing type and onHide function
 * @return {Object} An object containing various properties and functions for modal configuration
 */
const useModalConfigAdd = (props) => {
  const { type, onHide } = props;
  const [showUserFields, setShowUserFields] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const roleRef = useRef(null);
  const passwordRef = useRef(null);
  /**
   * Check if user fields should be shown.
   *
   * @param {type} type - The type to check
   * @return {boolean} true if type is "users", false otherwise
   */
  const shouldShowUserFields = (type) => {
    return type === "users";
  };
  /**
   * Validate the fields for adding a new user.
   *
   * @return {boolean} Whether all fields are valid or not.
   */
  const validateFieldsAddUser = () => {
    const newErrors = {};

    if (!nameRef.current.value.trim()) {
      newErrors.name = "Ingresa un nombre ";
    }

    if (showUserFields) {
      if (!emailRef.current.value.trim()) {
        newErrors.email = "Ingresa un correo electrónico";
      }
      if (
        !roleRef.current.value.trim() ||
        roleRef.current.value === "DEFAULT"
      ) {
        newErrors.role = "Selecciona un rol";
      }

      if (!passwordRef.current.value.trim()) {
        newErrors.password = "Ingresa una contraseña";
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  /**
   * Validates the fields for adding a campaign.
   *
   * @return {boolean} Whether the fields are valid or not
   */
  const validateFieldsAddCampaing = () => {
    const newErrors = {};

    if (!nameRef.current.value.trim()) {
      newErrors.name = "Ingresa un nombre ";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  /**
   * A function that handles the click event for sending data.
   */
  const handleEnviarClick = async () => {
    setIsLoading(true);
    try {
      let response;
      let formData = {
        name: nameRef.current.value,
        email: emailRef.current?.value,
        password: passwordRef.current?.value,
        role: roleRef.current?.value,
      };

      if (type === "users") {
        const isValid = validateFieldsAddUser();
        if (!isValid) return;
        response = await addUser(formData);
      } else if (type === "campaign") {
        const isValidCampaing = validateFieldsAddCampaing();
        if (!isValidCampaing) return;
        let formData = {
          name: nameRef.current.value,
        };
        response = await createCampaing(formData);
      } else {
        const isValidCampaing = validateFieldsAddCampaing();
        if (!isValidCampaing) return;
        let formData = {
          name: nameRef.current.value,
        };
        response = await createArea(formData);
      }

      if (response && response.success) {
        alerts("success", response.message, 2000);
        onHide();
      } else {
        const errors = response?.message || {};
        let errorMessage = "Error al crear el usuario";

        if (Object.keys(errors).length > 0) {
          errorMessage += "<ul>";
          Object.entries(errors).forEach(([field, messages]) => {
            messages.forEach((message) => {
              errorMessage += `<li>${message}</li>`;
            });
          });
          errorMessage += "</ul>";
        }

        alerts("error", errorMessage, 2000);
        onHide();
      }
    } catch (e) {
      alerts("error", "Error al procesar tu solicitud", 2000);
      onHide();
    } finally {
      setIsLoading(false);
    }
  };
  /**
   * This function handles the hiding of the modal.
   */
  const handleModalHide = () => {
    setErrors({});
  };

  useEffect(() => {
    setShowUserFields(shouldShowUserFields(type));
  }, [type]);

  return {
    showUserFields,
    handleEnviarClick,
    handleModalHide,
    nameRef,
    emailRef,
    roleRef,
    passwordRef,
    errors,
    isLoading,
  };
};

export { useModalConfigAdd };
