import apiService from "./ApiService";

/**
 * Retrieves employees by campaign ID from an API and handles different response scenarios.
 *
 * @param {string} campaingId - The ID of the campaign for which to retrieve employees.
 * @return {Object} An object with properties `success` and `data` if the response status is 200. If the response status is not 200, it will return an object with `success` set to `false`. If an error occurs and the response status is 403, it will return an object with `success` set to `false` and a `message` property.
 */
const getEmployeesByCampaing = async (campaingId) => {
  try {
    const response = await apiService.get(
      `employee/employee-by-campaing/${campaingId}`
    );
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Retrieves employees by area ID from the API and handles different response scenarios.
 *
 * @param {number} areaId - The ID of the area for which to retrieve employees.
 * @return {Object} An object with properties `success` and `data` if the response status is 200. If the response status is not 200, it returns an object with `success` set to false. If an error occurs and the response status is 403, it returns an object with `success` set to false and a `message` property.
 */
const getEmployeesByArea = async (areaId) => {
  try {
    const response = await apiService.get(
      `employee/employee-by-area/${areaId}`
    );
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Asynchronously retrieves all employees from the API and handles different response scenarios.
 *
 * @returns {Object} An object with properties `success` and `data` if the response status is 200.
 * If there is an error, it returns an object with `success` set to false. If the error status is 403,
 * it also includes a `message` property with the value "Error de permisos".
 */
const getAllEmployees = async () => {
  try {
    const response = await apiService.get("employees");
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Retrieves employee communications by campaign ID asynchronously.
 *
 * @param {number} campaingId - The ID of the campaign for which to retrieve employee communications.
 * @return {Object} An object with properties `success` and `data` if the response status is 200. If the response status is not 200, it returns an object with `success` set to false. If an error occurs and the response status is 403, it returns an object with `success` set to false and a `message` property.
 */
const getEmployeeCommunicationsByCampaing = async (campaingId) => {
  try {
    const response = await apiService.get(
      `employee/comunications-by-campaing/${campaingId}`
    );
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Retrieves employee communications by area ID asynchronously.
 *
 * @param {number} areaId - The ID of the area for which to retrieve employee communications.
 * @return {Object} An object with properties `success` and `data` if the response status is 200. If the response status is not 200, it returns an object with `success` set to false. If an error occurs and the response status is 403, it returns an object with `success` set to false and a `message` property.
 */
const getEmployeeCommunicationsByArea = async (areaId) => {
  try {
    const response = await apiService.get(
      `employee/comunications-by-area/${areaId}`
    );
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Retrieves all employee communications from the API and handles different response scenarios.
 *
 * @returns {Object} An object with properties `success` and `data` if the response status is 200. If the response status is not 200, it returns an object with `success` set to false. If an error occurs and the response status is 403, it returns an object with `success` set to false and a `message` property.
 */
const getAllEmployeeCommunications = async () => {
  try {
    const response = await apiService.get("employee/comunications");
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};
const getEmployeeDemographic = async () => {
  try {
    const response = await apiService.get("employee/demographics");
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Asynchronously updates an employee's information based on the provided employeeId and changedFields.
 *
 * @param {string} employeeId - The unique identifier of the employee to update.
 * @param {Object} changedFields - The fields to be updated for the employee.
 * @returns {Object} An object indicating the success status of the update operation along with the updated employee data or an error message.
 */
const updateEmployee = async (employeeId, changedFields) => {
  try {
    const response = await apiService.post(`employees/${employeeId}`, {
      _method: "PUT",
      ...changedFields,
    });

    if (response && response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false, error: "Error al actualizar" };
  }
};

/**
 * This asynchronous function deletes an employee based on the provided employeeId using the API service and handles different response scenarios.
 *
 * @param {string} employeeId - The unique identifier of the employee to be deleted.
 * @returns {Object} An object with a success flag indicating the deletion status. If there is an error, it may include a message.
 */
const deleteEmployee = async (employeeId) => {
  try {
    const response = await apiService.delete(`employees/${employeeId}`);
    if (response && response.status === 200) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * This asynchronous function adds an employee using the API service and handles different response scenarios.
 *
 * @param {Object} employee - The employee object to be added
 * @returns {Object} An object with a success flag and the added employee's ID if the operation is successful. If there is an error, it returns an object with a success flag and an optional error message or errors.
 */
const addEmployee = async (employee) => {
  try {
    const response = await apiService.post("employees", employee);

    if (response && response.status === 201) {
      return {
        success: true,
        data: response.data.id,
      };
    } else {
      return { success: false };
    }
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data.errors) {
      return { success: false, errors: e.response.data.errors };
    } else {
      return { success: false, message: "Error al procesar la solicitud" };
    }
  }
};
/**
 * Retrieves employees by employee ID from an API and handles different response scenarios.
 *
 * @param {string} employee - The ID of the employee for which to retrieve employees.
 * @return {Object} An object with properties `success` and `data` if the response status is 200. If the response status is not 200, it will return an object with `success` set to `false`. If an error occurs and the response status is 403, it will return an object with `success` set to `false` and a `message` property.
 */
const getEmployeeByIdentification = async (employee) => {
  try {
    const response = await apiService.post("employee/employee-by-id", employee);

    if (response && response.status === 200) {
      localStorage.setItem("employeeId", response.data.data.id);
      localStorage.setItem("name", response.data.data.attributes.nombre);
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status == 404) {
      return { success: false, message: e.response.data.message };
    }
    return { success: false };
  }
};
const updateEmployeeSociodemographic = async (employeeId, changedFields) => {
  try {
    const response = await apiService.post(
      `employees/update-all/${employeeId}`,
      {
        _method: "PUT",
        ...changedFields,
      }
    );

    if (response && response.status === 200) {
      localStorage.removeItem("employeeId");
      localStorage.removeItem("name");

      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false, error: "Error al actualizar" };
  }
};

const getEmployeeSupportData = async () => {
  try {
    const response = await apiService.get("employee/support");
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    if (e.response.status == 404) {
      return { success: false, message: e.response.data.message };
    }
    return { success: false };
  }
};

/**
 * Asynchronously updates an employee's information based on the provided employeeId and changedFields.
 *
 * @param {string} employeeId - The unique identifier of the employee to update.
 * @param {Object} changedFields - The fields to be updated for the employee.
 * @returns {Object} An object indicating the success status of the update operation along with the updated employee data or an error message.
 */
const updateEmployeeSupportData = async (employeeId, changedFields) => {
  try {
    const response = await apiService.post(`employee/support-update/${employeeId}`, {
      _method: "POST",
      ...changedFields,
    });

    if (response && response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false, error: "Error al actualizar" };
  }
};
export {
  getEmployeesByCampaing,
  getEmployeesByArea,
  getAllEmployees,
  getEmployeeCommunicationsByCampaing,
  getEmployeeCommunicationsByArea,
  getAllEmployeeCommunications,
  updateEmployee,
  deleteEmployee,
  addEmployee,
  getEmployeeByIdentification,
  updateEmployeeSociodemographic,
  getEmployeeDemographic,
  getEmployeeSupportData,
  updateEmployeeSupportData,
};
