import { useState, useEffect, useCallback } from "react";
import { alerts } from "../../utils/Notifications";
import { getCampaingsActive } from "../../services/CampaingService";
import { getActiveAreas } from "../../services/AreaService";
import { updateEmployee } from "../../services/EmployeeService";

/**
 * Custom hook to manage the employee editing modal, including state and functions to handle changes and submission, and to fetch data.
 *
 * @param {object} onClose - Function to close the modal
 * @param {object} employee - The employee being edited
 * @param {object} onUpdate - Function to update the employee
 * @return {object} Object containing state and functions for the modal
 */
const useEditModal = ({ onClose, employee, onUpdate }) => {
  const [editEmployee, setEditEmployee] = useState({
    ...employee.attributes,
    area: employee.attributes.area ? employee.attributes.area.id : 0,
    campaing: employee.attributes.campaing
      ? employee.attributes.campaing.id
      : 0,
    nameArea: employee.attributes.area ? employee.attributes.area.name : "",
    nameCampaing: employee.attributes.campaing
      ? employee.attributes.campaing.name
      : "",
  });
  const [campaings, setCampaings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [areas, setAreas] = useState([]);
  const [showForm, setShowForm] = useState(false);

  /**
   * Function to fetch data asynchronously and update state variables accordingly.
   */
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const [areaResponse, campaingResponse] = await Promise.all([
        getActiveAreas(),
        getCampaingsActive(),
      ]);

      if (areaResponse.success) {
        setAreas(areaResponse.data);
      }

      if (campaingResponse.success) {
        setCampaings(campaingResponse.data);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
      setShowForm(true);
    }
  }, []);

  /**
   * Function to handle input changes and update the editEmployee state accordingly.
   * @param {Event} e The input change event.
   */
  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setEditEmployee((prevState) => ({
        ...prevState,
        [name]:
          name === "area" || name === "campaing" ? parseInt(value) : value,
      }));
      const changes = Object.keys(editEmployee).some(
        (key) => editEmployee[key] !== employee.attributes[key]
      );
      setHasChanges(changes);
    },
    [editEmployee, employee.attributes]
  );
  /**
   * Function to handle form submission, update employee data, and display notifications accordingly.
   * @param {Event} e The form submission event.
   */
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError(null);

      if (!hasChanges) {
        alerts("success", "No se realizaron cambios", 2000);
        onClose();
        return;
      }

      let updatedEmployee = { ...editEmployee };

      updatedEmployee.campaing_id = updatedEmployee.campaing;
      updatedEmployee.area_id = updatedEmployee.area;

      delete updatedEmployee.campaing;
      delete updatedEmployee.area;

      if (updatedEmployee.area === 0) {
        updatedEmployee = { ...updatedEmployee, area: null };
      }
      if (updatedEmployee.campaing === 0) {
        updatedEmployee = { ...updatedEmployee, campaing: null };
      }

      if (updatedEmployee.campaing_id > 0 && updatedEmployee.area_id > 0) {
        setError("Un empleado no puede tener un área y una campaña");
        setIsLoading(false);
        return;
      }
      if (
        updatedEmployee.area_id === null &&
        updatedEmployee.campaing_id === null
      ) {
        setError("Un empleado debe tener un área o una campaña");
        setIsLoading(false);
        return;
      }

      try {
        const changedFields = {};

        Object.keys(updatedEmployee).forEach((key) => {
          if (key === "area" || key === "campaing") {
            changedFields[`${key}_id`] = updatedEmployee[key];
          } else if (updatedEmployee[key] !== employee.attributes[key]) {
            changedFields[key] = updatedEmployee[key];
          }
        });

        const response = await updateEmployee(employee.id, changedFields);

        if (response.success) {
          alerts("success", "Sus cambios fueron guardados", 2000);
          onUpdate();
          onClose();
        }
      } catch (e) {
        alerts("error", "Se ha presentado un error, inténtelo más tarde", 2000);
      } finally {
        setIsLoading(false);
      }
    },
    [
      editEmployee,
      employee.attributes,
      hasChanges,

      onClose,
      onUpdate,
      employee.id,
    ]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, employee]);

  useEffect(() => {
    const modalBody = document.getElementById("custom-modal");
    modalBody.scrollIntoView({ behavior: "smooth" });
  }, [isLoading, error]);

  return {
    editEmployee,
    handleInputChange,
    handleSubmit,
    isLoading,
    error,
    hasChanges,
    showForm,
    areas,
    campaings,
    onClose,
  };
};

export { useEditModal };
