import { useState, useEffect } from "react";
import { alerts } from "../../utils/Notifications";
import { updateEmployeeSociodemographic } from "../../services/EmployeeService";
import * as Yup from "yup";

/**
 * Custom hook to manage the employee editing modal, including state and functions to handle changes and submission, and to fetch data.
 *
 * @param {object} onClose - Function to close the modal
 * @param {object} employee - The employee being edited
 * @param {object} onUpdate - Function to update the employee
 * @return {object} Object containing state and functions for the modal
 */
const useModalSocialDemographic = ({ onClose, employee, onUpdate }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [initialValues, setInitialValues] = useState(employee.attributes);
  const [showForm, setShowForm] = useState(true);

  const schema = Yup.object().shape({});
  useEffect(() => {
    setInitialValues(employee.attributes);
  }, [employee]);
  const [isLoading, setIsLoading] = useState(false);

  const fields = [
    {
      name: "estrato_socioeconomico",
      label: "Estrato Socioeconómico",
      type: "select",
      options: [...Array(7).keys()].map((num) => ({
        value: num.toString(),
        label: num.toString(),
      })),
    },
    {
      name: "estado_civil",
      label: "Estado Civil",
      type: "select",
      options: [
        "Soltero",
        "Casado",
        "Viudo",
        "Separado",
        "Divorciado",
        "Unión Libre",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "raza",
      label: "Raza",
      type: "select",
      options: [
        "Afrodesenciente",
        "Indigena",
        "Gitana",
        "Mestizo",
        "Ninguno",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "peso",
      label: "Peso (kg)",
      type: "number",
    },
    {
      name: "estatura",
      label: "Estatura (cm)",
      type: "number",
    },
    {
      name: "tipo_sangre",
      label: "Tipo de Sangre",
      type: "select",
      options: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"].map(
        (option) => ({ value: option, label: option })
      ),
    },
    {
      name: "orientacion_sexual",
      label: "Orientación Sexual",
      type: "select",

      options: [
        "Heterosexual",
        "Homosexual",
        "Bisexual",
        "Pansexual",
        "Asexual",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "usted_ha_sido_victima",
      label: "¿Usted ha sido víctima de violencia?",
      type: "select",

      options: [
        "Desplazamiento",
        "Secuestro",
        "Violencia",
        "Violencia de Género",
        "Ninguno",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "departamento",
      label: "Departamento",
      type: "select",

      options: [
        "Amazonas",
        "Antioquia",
        "Arauca",
        "Atlántico",
        "Bolívar",
        "Boyacá",
        "Caldas",
        "Caquetá",
        "Casanare",
        "Cauca",
        "Cesar",
        "Chocó",
        "Córdoba",
        "Cundinamarca",
        "Guainía",
        "Guaviare",
        "Huila",
        "La Guajira",
        "Magdalena",
        "Meta",
        "Nariño",
        "Norte de Santander",
        "Putumayo",
        "Quindío",
        "Risaralda",
        "San Andrés y Providencia",
        "Santander",
        "Sucre",
        "Tolima",
        "Valle del Cauca",
        "Vaupés",
        "Vichada",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "pais",
      label: "País de Residencia",
      type: "select",

      options: [
        "Colombia",
        "Afganistán",
        "Albania",
        "Alemania",
        "Andorra",
        "Angola",
        "Antigua y Barbuda",
        "Arabia Saudita",
        "Argelia",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaiyán",
        "Bahamas",
        "Bangladés",
        "Barbados",
        "Baréin",
        "Bélgica",
        "Belice",
        "Benín",
        "Bielorrusia",
        "Birmania",
        "Bolivia",
        "Bosnia y Herzegovina",
        "Botsuana",
        "Brasil",
        "Brunéi",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Bután",
        "Cabo Verde",
        "Camboya",
        "Camerún",
        "Canadá",
        "Catar",
        "Chad",
        "Chile",
        "China",
        "Chipre",
        "Ciudad del Vaticano",
        "Comoras",
        "Corea del Norte",
        "Corea del Sur",
        "Costa de Marfil",
        "Costa Rica",
        "Croacia",
        "Cuba",
        "Dinamarca",
        "Dominica",
        "Ecuador",
        "Egipto",
        "El Salvador",
        "Emiratos Árabes Unidos",
        "Eritrea",
        "Eslovaquia",
        "Eslovenia",
        "España",
        "Estados Unidos",
        "Estonia",
        "Etiopía",
        "Filipinas",
        "Finlandia",
        "Fiyi",
        "Francia",
        "Gabón",
        "Gambia",
        "Georgia",
        "Ghana",
        "Granada",
        "Grecia",
        "Guatemala",
        "Guyana",
        "Guinea",
        "Guinea-Bisáu",
        "Guinea Ecuatorial",
        "Haití",
        "Honduras",
        "Hungría",
        "India",
        "Indonesia",
        "Irak",
        "Irán",
        "Irlanda",
        "Islandia",
        "Islas Marshall",
        "Islas Salomón",
        "Israel",
        "Italia",
        "Jamaica",
        "Japón",
        "Jordania",
        "Kazajistán",
        "Kenia",
        "Kirguistán",
        "Kiribati",
        "Kuwait",
        "Laos",
        "Lesoto",
        "Letonia",
        "Líbano",
        "Liberia",
        "Libia",
        "Liechtenstein",
        "Lituania",
        "Luxemburgo",
        "Madagascar",
        "Malasia",
        "Malaui",
        "Maldivas",
        "Malí",
        "Malta",
        "Marruecos",
        "Mauricio",
        "Mauritania",
        "México",
        "Micronesia",
        "Moldavia",
        "Mónaco",
        "Mongolia",
        "Montenegro",
        "Mozambique",
        "Namibia",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "Níger",
        "Nigeria",
        "Noruega",
        "Nueva Zelanda",
        "Omán",
        "Países Bajos",
        "Pakistán",
        "Palaos",
        "Panamá",
        "Papúa Nueva Guinea",
        "Paraguay",
        "Perú",
        "Polonia",
        "Portugal",
        "Reino Unido",
        "República Centroafricana",
        "República Checa",
        "República Democrática del Congo",
        "República Dominicana",
        "República del Congo",
        "Ruanda",
        "Rumanía",
        "Rusia",
        "Samoa",
        "San Cristóbal y Nieves",
        "San Marino",
        "San Vicente y las Granadinas",
        "Santa Lucía",
        "Santo Tomé y Príncipe",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leona",
        "Singapur",
        "Siria",
        "Somalia",
        "Sri Lanka",
        "Suazilandia",
        "Sudáfrica",
        "Sudán",
        "Sudán del Sur",
        "Suecia",
        "Suiza",
        "Surinam",
        "Tailandia",
        "Tanzania",
        "Tayikistán",
        "Timor Oriental",
        "Togo",
        "Tonga",
        "Trinidad y Tobago",
        "Túnez",
        "Turkmenistán",
        "Turquía",
        "Tuvalu",
        "Ucrania",
        "Uganda",
        "Uruguay",
        "Uzbekistán",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Yibuti",
        "Zambia",
        "Zimbabue",
      ].map((option) => ({ value: option, label: option })),
    },
    {
      name: "nacionalidad",
      label: "Nacionalidad",
      type: "select",

      options: ["Colombiano", "Extranjero"].map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "tipo_vivienda",
      label: "Tipo de Vivienda",
      type: "select",
      options: ["Propia", "Arrendada", "Familiar"].map((option) => ({
        value: option,
        label: option,
      })),
    },

    {
      name: "experiencia_cargo",
      label: "Experiencia en el cargo",
      type: "select",
      options: [
        { value: "Sin experiencia", label: "Sin experiencia" },
        { value: "1-6 meses", label: "1-6 meses" },
        { value: "7-11 meses", label: "7-11 meses" },
        { value: "1-2 años", label: "1-2 años" },
        { value: "3-5 años", label: "3-5 años" },
        { value: "más de 5 años", label: "más de 5 años" },
      ],
    },

    {
      name: "modalidad_trabajo",
      label: "Modalidad de trabajo",
      type: "select",
      options: ["Presencial", "Virtual", "Híbrido"].map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "tiene_personas_a_cargo",
      label: "¿Tienes personas a cargo?",
      type: "select",
      options: [
        { value: "1", label: "Sí" },
        { value: "0", label: "No" },
      ],
    },
    {
      name: "cantidad_personas_a_cargo",
      label: "Cantidad de personas a cargo",
      type: "number",
    },
    {
      name: "discapacidad",
      label: "¿Tienes alguna discapacidad?",
      type: "select",
      options: [
        { value: "1", label: "Sí" },
        { value: "0", label: "No" },
      ],
    },
    {
      name: "tipo_discapacidad",
      label: "¿Cuál es tu tipo de discapacidad?",
      type: "text",
    },
    {
      name: "persona_pensionado",
      label: "¿Es persona pensionada?",
      type: "boolean",
    },
    {
      name: "gestantes",
      label: "¿Estás embarazada?",
      type: "boolean",
    },
    {
      name: "lactancia",
      label: "¿Estás lactando?",
      type: "boolean",
    },
  ];
  const areValuesEqual = (initialValues, currentValues) => {
    return JSON.stringify(initialValues) === JSON.stringify(currentValues);
  };
  const getChangedValues = (initialValues, values) => {
    const changedValues = {};
    for (const key in values) {
      if (values[key] !== initialValues[key]) {
        changedValues[key] = values[key];
      }
    }
    return changedValues;
  };
  const handleSubmitSocialDemographic = async (
    values,
    initialValues,
    areValuesEqual,
    getChangedValues,
    employee,
    setErrorMessage,
    setShowForm,
    setIsLoading,
    onClose,
    onUpdate,
    setFormSubmitted
  ) => {
    if (areValuesEqual(initialValues, values)) {
      setErrorMessage("No se han realizado cambios.");
    } else {
      setShowForm(false);
      setIsLoading(true);
      const changedValues = getChangedValues(initialValues, values);
      const response = await updateEmployeeSociodemographic(
        employee.id,
        changedValues
      );
      if (response.success) {
        alerts("success", "Se han actualizado los datos", 3000);
        setFormSubmitted(true);
        onClose();
        onUpdate();
      } else {
        setErrorMessage(
          "Se ha presentado un error, vuelva a intentarlo más tarde."
        );
      }
      setIsLoading(false);
      setFormSubmitted(true);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    const modalBody = document.getElementById("custom-modal");
    modalBody.scrollIntoView({ behavior: "smooth" });
  }, [isLoading, errorMessage]);

  return {
    isLoading,
    showForm,
    schema,
    areValuesEqual,
    initialValues,
    setErrorMessage,
    setShowForm,
    setIsLoading,
    getChangedValues,
    fields,
    errorMessage,
    setFormSubmitted,
    formSubmitted,
    handleSubmitSocialDemographic,
  };
};

export { useModalSocialDemographic };
