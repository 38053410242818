import React from "react";
import { Container, Spinner } from "react-bootstrap";
/**
 * Formats a given date string into a specific date and time format.
 *
 * @param {string} dateString - The date string to be formatted.
 * @return {string} The formatted date string in the specified format.
 */
const Loading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Container className="d-flex align-items-center justify-content-center my-3">
        <Spinner animation="border" variant="info" />
      </Container>
    );
  } else {
    return null;
  }
};

export default Loading;
