import { Modal, Table, Col, Form, Row, Button } from "react-bootstrap";
import Loading from "../Loading";
import "../ModalEdit/EditModal.css";
import { useListLogs } from "./useListLogs";
/**
 * Generates a modal to display a list of logs with date filters and loading indicator.
 *
 * @param {Function} closeModal - Function to close the modal
 * @return {JSX.Element} Modal component displaying logs
 */
const ListLogs = ({ closeModal }) => {
  const { isLoading, handleSubmit, date, logs, formatDate, handleDateChange } =
    useListLogs({ closeModal });

  return (
    <Modal
      show={true}
      onHide={closeModal}
      id="custom-modal"
      dialogClassName="modal-log"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-blue fw-bold">
          Registro de Actividad
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="mb-5">
          <Row className="justify-content-between">
            <Col md={5} className="mt-1">
              <Form.Group controlId="formFechaInicio">
                <Form.Label>Fecha Inicio</Form.Label>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={date.start_date}
                  onChange={handleDateChange}
                />
              </Form.Group>
            </Col>

            <Col md={5} className="mt-1">
              <Form.Group controlId="formFechaFin">
                <Form.Label>Fecha Fin</Form.Label>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={date.end_date}
                  onChange={handleDateChange}
                />
              </Form.Group>
            </Col>
            <Col md={2} className="mt-1">
              <Button
                type="submit"
                variant="primary"
                className="btn bg-blue text-white w-100 mt-4"
              >
                Ver
              </Button>
            </Col>
          </Row>
        </Form>
        <Loading isLoading={isLoading} />
        {logs.length > 0 && (
          <Table striped bordered hover className="table-employee" size="sm" responsive>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Usuario</th>
                <th>Acción</th>
                <th>Entidad</th>
                <th>Descripción</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{formatDate(log.attributes.created_at)}</td>
                  <td>{log.attributes.user}</td>
                  <td style={{ whiteSpace: "pre" }}>{log.attributes.action}</td>
                  <td>{log.attributes.resource_type}</td>
                  <td style={{ whiteSpace: "pre-wrap" }}>
                    {log.attributes.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ListLogs;
