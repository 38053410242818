import React from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import * as formik from "formik";
import { useFormRegister } from "./useFormRegister";
import pdf from "../../Assets/PDF/Politica de Privacidad y Tratamiento de Datos Personales.pdf";
import "./formRegister.css";
/**
 * Renders a form for user registration, including fields for personal information, contact information, and emergency contact information.
 *
 * @return {JSX.Element} The form for user registration
 */
const FormRegister = () => {
  const { Formik } = formik;
  const {
    initialValues,
    onSubmit,
    schema,
    submitted,
    isSubmitting,
    areas,
    campaigns,
    showTitleField,
    handleChangeNivelEducativo,
  } = useFormRegister();
  return (

    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-2">
            {/* Nomnbre Completo */}
            <Form.Group
              as={Col}
              md="12"
              controlId="validationFormik02"
              className="group mb-2"
            >
              <Form.Label className="color-label">Nombre Completo</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                className="form-control-register"
                value={values.nombre}
                onChange={handleChange}
                isInvalid={(submitted || touched.nombre) && !!errors.nombre}
                isValid={touched.nombre && !errors.nombre}
              />
              {touched.name && !errors.name && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Correo electrónico */}
            <Form.Group
              as={Col}
              md="12"
              controlId="validationFormikcorreo_electronico"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Correo Electrónico (Personal)
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="email"
                  aria-describedby="inputGroupPrepend"
                  name="correo_electronico"
                  className="form-control-register"
                  value={values.correo_electronico}
                  onChange={handleChange}
                  isInvalid={
                    (submitted || touched.correo_electronico) &&
                    !!errors.correo_electronico
                  }
                  isValid={
                    touched.correo_electronico && !errors.correo_electronico
                  }
                />
                {touched.correo_electronico && !errors.correo_electronico && (
                  <Form.Control.Feedback type="valid">
                    <span className="fa fa-check-circle"></span>
                  </Form.Control.Feedback>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.correo_electronico}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {/* Ciudad */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik03"
              className="group mb-2"
            >
              <Form.Label className="color-label">Ciudad</Form.Label>
              <Form.Control
                type="text"
                name="ciudad"
                className="form-control-register"
                value={values.ciudad}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={(submitted || touched.ciudad) && !!errors.ciudad}
                isValid={touched.ciudad && !errors.ciudad}
              />
              {touched.ciudad && !errors.ciudad && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.ciudad}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Dirección */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik04"
              className="group mb-2"
            >
              <Form.Label className="color-label">Dirección</Form.Label>
              <Form.Control
                type="text"
                className="form-control-register"
                name="direccion"
                value={values.direccion}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.direccion) && !!errors.direccion
                }
                isValid={touched.direccion && !errors.direccion}
              />
              {touched.direccion && !errors.direccion && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.direccion}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Teléfono */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik05"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Número de celular (Personal)
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control-register"
                name="telefono"
                value={values.telefono}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={(submitted || touched.telefono) && !!errors.telefono}
                isValid={touched.telefono && !errors.telefono}
              />
              {touched.telefono && !errors.telefono && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.telefono}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Género */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik07"
              className="group mb-2"
            >
              <Form.Label className="color-label">Género</Form.Label>
              <Form.Select
                name="genero"
                className="form-control-register"
                value={values.genero}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={(submitted || touched.genero) && !!errors.genero}
                isValid={touched.genero && !errors.genero}
             
            
              >
                <option value="DEFAULT" disabled >
                  Selecciona una opción
                </option>
                <option value="Mujer">Mujer</option>
                <option value="Hombre">Hombre</option>
                <option value="Prefiero no decirlo">Prefiero no decirlo</option>
              </Form.Select>
              {touched.genero && !errors.genero && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.genero}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Género */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik07"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Tipo de identificacion
              </Form.Label>
              <Form.Select
                name="tipo_identificacion"
                className="form-control-register"
                value={values.tipo_identificacion}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.tipo_identificacion) &&
                  !!errors.tipo_identificacion
                }
                isValid={
                  touched.tipo_identificacion && !errors.tipo_identificacion
                }
             
              >
                <option value="DEFAULT" disabled >
                  Selecciona una opción
                </option>
                <option value="Tarjeta de identidad">
                  Tarjeta de identidad
                </option>
                <option value="Cédula de ciudadanía">
                  Cédula de ciudadanía
                </option>
                <option value="Cédula de extranjería">
                  Cédula de extranjería
                </option>
                <option value="Pasaporte">Pasaporte</option>
                <option value="PPT">PPT</option>
                <option value="PEP">PEP</option>
              </Form.Select>
              {touched.tipo_identificacion && !errors.tipo_identificacion && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.tipo_identificacion}
              </Form.Control.Feedback>
            </Form.Group>

            {/* N° de Identificación */}

            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik02"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Número de identificación
              </Form.Label>
              <Form.Control
                type="text"
                name="numero_identificacion"
                className="form-control-register"
                value={values.numero_identificacion}
                onChange={handleChange}
                isInvalid={
                  (submitted || touched.numero_identificacion) &&
                  !!errors.numero_identificacion
                }
                isValid={
                  touched.numero_identificacion && !errors.numero_identificacion
                }
              />
              {touched.numero_identificacion &&
                !errors.numero_identificacion && (
                  <Form.Control.Feedback type="valid">
                    <span className="fa fa-check-circle"></span>
                  </Form.Control.Feedback>
                )}
              <Form.Control.Feedback type="invalid">
                {errors.numero_identificacion}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Fecha de nacimiento */}

            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik06"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Fecha de nacimiento
              </Form.Label>
              <Form.Control
                className="form-control-register"
                type="date"
                name="fecha_nacimiento"
                value={values.fecha_nacimiento}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.fecha_nacimiento) &&
                  !!errors.fecha_nacimiento
                }
                isValid={touched.fecha_nacimiento && !errors.fecha_nacimiento}
              />
              {touched.fecha_nacimiento && !errors.fecha_nacimiento && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.fecha_nacimiento}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Lugar de nacimiento */}

            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik06"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Lugar de nacimiento
              </Form.Label>
              <Form.Control
                className="form-control-register"
                type="text"
                name="lugar_nacimiento"
                value={values.lugar_nacimiento}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.lugar_nacimiento) &&
                  !!errors.lugar_nacimiento
                }
                isValid={touched.lugar_nacimiento && !errors.lugar_nacimiento}
              />
              {touched.lugar_nacimiento && !errors.lugar_nacimiento && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.lugar_nacimiento}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Fecha de expedición */}

            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik06"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Fecha de Expedición
              </Form.Label>
              <Form.Control
                className="form-control-register"
                type="date"
                name="fecha_expedicion"
                value={values.fecha_expedicion}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.fecha_expedicion) &&
                  !!errors.fecha_expedicion
                }
                isValid={touched.fecha_expedicion && !errors.fecha_expedicion}
              />
              {touched.fecha_expedicion && !errors.fecha_expedicion && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.fecha_expedicion}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Lugar de expedición */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik06"
              className="group mb-2"
            >
              <Form.Label className="color-label">
                Lugar de Expedición
              </Form.Label>
              <Form.Control
                className="form-control-register"
                type="text"
                name="lugar_expedicion"
                value={values.lugar_expedicion}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.lugar_expedicion) &&
                  !!errors.lugar_expedicion
                }
                isValid={touched.lugar_expedicion && !errors.lugar_expedicion}
              />
              {touched.lugar_expedicion && !errors.lugar_expedicion && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.lugar_expedicion}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik08"
              className="group mb-2"
            >
              <Form.Label className="color-label">Nivel educativo</Form.Label>
              <Form.Select
                className="form-control-register"
                name="nivel_educativo"
                value={values.nivel_educativo}
                onChange={(e) => {
                  handleChangeNivelEducativo(e);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.nivel_educativo) &&
                  !!errors.nivel_educativo
                }
                isValid={touched.nivel_educativo && !errors.nivel_educativo}
             
              >
                <option value="DEFAULT" disabled>
                  Selecciona una opción
                </option>
                <option value="Bachiller incompleto">
                  Bachiller incompleto
                </option>
                <option value="Bachiller completo">Bachiller completo</option>
                <option value="Técnico">Técnico</option>
                <option value="Tecnólogo">Tecnólogo</option>
                <option value="Profesional">Profesional</option>
                <option value="Posgrado">Posgrado</option>
              </Form.Select>

              {touched.nivel_educativo && !errors.nivel_educativo && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.nivel_educativo}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Profesión */}
            {showTitleField && (
              <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik08"
                className="group mb-2"
              >
                <Form.Label className="color-label">
                  Título educativo
                </Form.Label>
                <Form.Control
                  className="form-control-register"
                  type="text"
                  name="profesion"
                  value={values.profesion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    (submitted || touched.profesion) && !!errors.profesion
                  }
                  isValid={touched.profesion && !errors.profesion}
                />
                {touched.profesion && !errors.profesion && (
                  <Form.Control.Feedback type="valid">
                    <span className="fa fa-check-circle"></span>
                  </Form.Control.Feedback>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.profesion}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Row>
          <Row className="mb-2">
            {/* Tiene Hijos */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik09"
              className="group mb-2"
            >
              <Form.Label className="color-label">¿Tiene hijos?</Form.Label>
              <Form.Select
                className="form-control-register"
                name="tiene_hijo"
                value={values.tiene_hijo}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("cantidad_hijos", 0);
                  setFieldValue("tiene_hijo", e.target.value === "true");
                }}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.tiene_hijo) && !!errors.tiene_hijo
                }
                isValid={touched.tiene_hijo && !errors.tiene_hijo}
             
              >
                <option value="DEFAULT" disabled >
                  Selecciona una opción
                </option>
                <option value="true">Sí</option>
                <option value="false">No</option>
              </Form.Select>
              {touched.tiene_hijo && !errors.tiene_hijo && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.tiene_hijo}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Cantidad Hijos */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik10"
              className="group mb-2"
            >
              <Form.Label className="color-label">Cantidad de hijos</Form.Label>
              <Form.Control
                className="form-control-register"
                type="number"
                name="cantidad_hijos"
                value={values.cantidad_hijos}
                disabled={values.tiene_hijo ? undefined : "disabled"}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.cantidad_hijos) &&
                  !!errors.cantidad_hijos
                }
                isValid={touched.cantidad_hijos && !errors.cantidad_hijos}
              />
              {touched.cantidad_hijos && !errors.cantidad_hijos && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.cantidad_hijos}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Info  Hijos */}
            {values.tiene_hijo &&
              values.cantidad_hijos > 0 &&
              Array.from({ length: values.cantidad_hijos }).map((_, index) => (
                <Row key={index}>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId={`nombre_hijo_${index + 1}`}
                    className="group mb-2"
                  >
                    <Form.Label className="color-label">
                      Nombre Completo
                    </Form.Label>
                    <Form.Control
                      className="form-control-register"
                      type="text"
                      name={`nombre_hijo_${index + 1}`}
                      value={values[`nombre_hijo_${index + 1}`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (submitted || touched[`nombre_hijo_${index + 1}`]) &&
                        !!errors[`nombre_hijo_${index + 1}`]
                      }
                      isValid={
                        touched[`nombre_hijo_${index + 1}`] &&
                        !errors[`nombre_hijo_${index + 1}`]
                      }
                    />
                    {touched[`nombre_hijo_${index + 1}`] &&
                      !errors[`nombre_hijo_${index + 1}`] && (
                        <Form.Control.Feedback type="valid">
                          <span className="fa fa-check-circle"></span>
                        </Form.Control.Feedback>
                      )}
                    <Form.Control.Feedback type="invalid">
                      {errors[`nombre_hijo_${index + 1}`]}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId={`identificacion_hijo_${index + 1}`}
                    className="group mb-2"
                  >
                    <Form.Label className="color-label">
                      N° de identificación
                    </Form.Label>
                    <Form.Control
                      className="form-control-register"
                      type="number"
                      name={`identificacion_hijo_${index + 1}`}
                      value={values[`identificacion_hijo_${index + 1}`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (submitted ||
                          touched[`identificacion_hijo_${index + 1}`]) &&
                        !!errors[`identificacion_hijo_${index + 1}`]
                      }
                      isValid={
                        touched[`identificacion_hijo_${index + 1}`] &&
                        !errors[`identificacion_hijo_${index + 1}`]
                      }
                    />
                    {touched[`identificacion_hijo_${index + 1}`] &&
                      !errors[`identificacion_hijo_${index + 1}`] && (
                        <Form.Control.Feedback type="valid">
                          <span className="fa fa-check-circle"></span>
                        </Form.Control.Feedback>
                      )}
                    <Form.Control.Feedback type="invalid">
                      {errors[`identificacion_hijo_${index + 1}`]}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId={`fecha_nacimiento_hijo_${index + 1}`}
                    className="group mb-2"
                  >
                    <Form.Label className="color-label">
                      Fecha de nacimiento
                    </Form.Label>
                    <Form.Control
                      className="form-control-register"
                      type="date"
                      name={`fecha_nacimiento_hijo_${index + 1}`}
                      value={values[`fecha_nacimiento_hijo_${index + 1}`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        (submitted ||
                          touched[`fecha_nacimiento_hijo_${index + 1}`]) &&
                        !!errors[`fecha_nacimiento_hijo_${index + 1}`]
                      }
                      isValid={
                        touched[`fecha_nacimiento_hijo_${index + 1}`] &&
                        !errors[`fecha_nacimiento_hijo_${index + 1}`]
                      }
                    />
                    {touched[`fecha_nacimiento_hijo_${index + 1}`] &&
                      !errors[`fecha_nacimiento_hijo_${index + 1}`] && (
                        <Form.Control.Feedback type="valid">
                          <span className="fa fa-check-circle"></span>
                        </Form.Control.Feedback>
                      )}
                    <Form.Control.Feedback type="invalid">
                      {errors[`fecha_nacimiento_hijo_${index + 1}`]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              ))}
          </Row>
          {/* Tipo de contrato */}
          <Row className="mb-2">
            <Form.Group
              as={Col}
              md="12"
              controlId="validationFormik07"
              className="group mb-2"
            >
              <Form.Label className="color-label">Tipo de contrato</Form.Label>
              <Form.Select
                name="tipo_contrato"
                className="form-control-register"
                value={values.tipo_contrato}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  (submitted || touched.tipo_contrato) && !!errors.tipo_contrato
                }
                isValid={touched.tipo_contrato && !errors.tipo_contrato}
             
              >
                <option value="DEFAULT" disabled >
                  Selecciona una opción
                </option>
                <option value="Prestación de servicios">
                  Prestación de servicios
                </option>
                <option value="Obra o Labor">Obra o Labor</option>
                <option value="Indefinido">Indefinido</option>
                <option value="Termino fijo">Termino fijo</option>
                <option value="Freelance">Freelance</option>
              </Form.Select>
              {touched.tipo_contrato && !errors.tipo_contrato && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.tipo_contrato}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            {/* Área */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik08"
              className="group mb-2"
            >
              <Form.Label className="color-label">Área</Form.Label>
              <Form.Select
                className="form-control-register"
                name="area"
                value={values.area}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("area", value);
                  setFieldValue("campaing", value === "DEFAULT" ? "" : "N/A");
                }}
                onBlur={handleBlur}
                isInvalid={(submitted || touched.area) && !!errors.area}
                isValid={touched.area && !errors.area}
                disabled={!areas || areas.length === 0 ? "disabled" : undefined}
              >
                {areas && areas.length === 0 && (
                  <option>No hay áreas activas</option>
                )}
                {areas && areas.length > 0 && (
                  <option value="DEFAULT">Selecciona una opción</option>
                )}
                {areas &&
                  areas.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.attributes.name}
                    </option>
                  ))}
              </Form.Select>

              {touched.area && !errors.area && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              {(submitted || touched.area || touched.campaing) &&
                !values.area &&
                !values.campaing && (
                  <Form.Control.Feedback type="invalid">
                    Debe seleccionar al menos un campo entre Área y Campaña
                  </Form.Control.Feedback>
                )}
            </Form.Group>
            {/* Campaña */}
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik12"
              className="group mb-2"
            >
              <Form.Label className="color-label">Campaña</Form.Label>
              <Form.Select
                className="form-control-register"
                name="campaing"
                value={values.campaing}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("campaing", value);
                  setFieldValue("area", value === "DEFAULT" ? "" : "N/A");
                }}
                onBlur={handleBlur}
                isInvalid={(submitted || touched.campaing) && !!errors.campaing}
                isValid={touched.campaing && !errors.campaing}
                disabled={
                  !campaigns || campaigns.length === 0 ? "disabled" : undefined
                }
              >
                {campaigns && campaigns.length === 0 && (
                  <option>No hay campañas activas</option>
                )}
                {campaigns && campaigns.length > 0 && (
                  <option value="DEFAULT">Selecciona una opción</option>
                )}
                {campaigns &&
                  campaigns.map((campaign) => (
                    <option key={campaign.id} value={campaign.id}>
                      {campaign.attributes.name}
                    </option>
                  ))}
              </Form.Select>

              {touched.campaing && !errors.campaing && (
                <Form.Control.Feedback type="valid">
                  <span className="fa fa-check-circle"></span>
                </Form.Control.Feedback>
              )}
              {(submitted || touched.area || touched.campaing) &&
                !values.area &&
                !values.campaing && (
                  <Form.Control.Feedback type="invalid">
                    Debe seleccionar al menos un campo entre Área y Campaña
                  </Form.Control.Feedback>
                )}
            </Form.Group>
          </Row>
          <section>
            <h3 className="text-blue">Información de contacto de emergencia</h3>

            <Row className="mb-2">
              {/* Nombre de Contacto */}
              <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik08"
                className="group mb-2"
              >
                <Form.Label className="color-label">
                  Nombre de Contacto
                </Form.Label>
                <Form.Control
                  className="form-control-register"
                  type="text"
                  name="nombre_contacto"
                  value={values.nombre_contacto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    (submitted || touched.nombre_contacto) &&
                    !!errors.nombre_contacto
                  }
                  isValid={touched.nombre_contacto && !errors.nombre_contacto}
                />
                {touched.nombre_contacto && !errors.nombre_contacto && (
                  <Form.Control.Feedback type="valid">
                    <span className="fa fa-check-circle"></span>
                  </Form.Control.Feedback>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.nombre_contacto}
                </Form.Control.Feedback>
              </Form.Group>
              {/* Telefono de Contacto */}

              <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik08"
                className="group mb-2"
              >
                <Form.Label className="color-label">
                  Teléfono de Contacto
                </Form.Label>
                <Form.Control
                  className="form-control-register"
                  type="text"
                  name="telefono_contacto"
                  value={values.telefono_contacto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    (submitted || touched.telefono_contacto) &&
                    !!errors.telefono_contacto
                  }
                  isValid={
                    touched.telefono_contacto && !errors.telefono_contacto
                  }
                />

                {touched.telefono_contacto && !errors.telefono_contacto && (
                  <Form.Control.Feedback type="valid">
                    <span className="fa fa-check-circle"></span>
                  </Form.Control.Feedback>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.telefono_contacto}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </section>
          <Form.Group className="mb-2">
            <Form.Check
              required
              name="terminos"
              label={
                <span>
                  He leído y acepto la{" "}
                  <a href={pdf} target="_blank" rel="noreferrer">
                    política de privacidad y tratamiento de datos personales
                  </a>
                </span>
              }
              onChange={handleChange}
              isInvalid={(submitted || touched.terminos) && !!errors.terminos}
              feedback={errors.terminos}
              feedbackType="invalid"
              id="validationFormik0"
            />
            <Form.Control.Feedback type="invalid">
              {errors.terminos}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Enviando..." : "Enviar"}
          </Button>{" "}
        </Form>
      )}
    </Formik>
  );
};

export default FormRegister;
