 
import apiService from "./ApiService";

/**
 * An asynchronous function that adds a user by making a POST request to an API endpoint and handles different response scenarios.


 * @param {object} user - The user object to be added.
 * @return {object} An object with properties `success` and `message` indicating the outcome of the operation.
 */
const addUser = async (user) => {
  try {
    const response = await apiService.post("users", {
      ...user,
    });

    if (response && response.status === 201) {
      return {
        success: true,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        message: "Error al crear el usuario",
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      window.location.href = "/error";
    }
    return {
      success: false,
      message: e.response.data.message,
    };
  }
};

/**
 * An asynchronous function to retrieve users data.
 *
 * @return {Object} An object with properties success and data containing user data if successful.
 */
const getUsers = async () => {
  try {
    const response = await apiService.get("users");
    if (response && response.status === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      window.location.href = "/error";
    }
    return {
      success: false,
    };
  }
};

/**
 * Asynchronously deletes a user and handles different response scenarios.
 *
 * @param {Object} user - The user object to be deleted.
 * @return {Object} An object with a property `success` indicating the deletion status.
 */
const deleteUser = async (user) => {
  try {
    const response = await apiService.delete(`users/${user}`);
    if (response && response.status === 200) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      window.location.href = "/error";
    }

    return {
      success: false,
    };
  }
};

/**
 * Asynchronously updates a user using a PUT request and handles different response scenarios.
 *
 * @param {object} user - The user object containing the details of the user to be updated.
 * @return {object} An object with properties success and message indicating the success of the update operation.
 */
const updateUser = async (user) => {
  try {
    const response = await apiService.put(`users/${user.id}`, {
      _method: "PUT",
      ...user.attributes,
    });
    if (response && response.status === 200) {
      return {
        success: true,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        message: response.data.message,
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      window.location.href = "/error";
    }
    return {
      success: false,
      message: e.response.data.message,
    };
  }
};

export { addUser, getUsers, deleteUser, updateUser };
