import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Configuration from "./Pages/Configuration";
import Forms from "./Pages/Forms";
import CompleteForms from "./Components/CompleteForms";
import FormDemographic from "./Pages/FormDemographic";
import ListPage from "./Pages/ListPage";
import Error from "./Components/Error";
import robot from "./Assets/404.png";

import "./App.css";
/**
 * The function `App` sets up routes for different user roles in a web application using React Router,
 * with protected routes based on user authentication and roles.
 *
 */
function App() {
  const user = localStorage.getItem("name");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/cambiar-contrasena"
          element={<Login />}
          query={() => ({ token: "", email: "" })}
        />
        <Route path="/registro" element={<Forms formType="register" />} />
        <Route
          path="/inicio-encuesta-sociodemografica"
          element={<Forms formType="start" />}
        />
        <Route
          path="/encuesta-sociodemografica"
          element={<FormDemographic />}
        />
        <Route
          path="/gracias"
          element={
            <CompleteForms
              title="¡Gracias!"
              description="Tu encuesta ha sido enviada con exito"
              company="Contact Center Grupo"
            />
          }
        />

        <Route
          element={
            <ProtectedRoute
              canActivate={user}
              roles={["Administrador"]}
              redirectPath="/home"
            />
          }
        >
          <Route path="config" element={<Configuration />} />
        </Route>

        <Route
          element={
            <ProtectedRoute
              canActivate={user}
              roles={[
                "Recursos Humanos",
                "Administrador",
                "Comunicaciones",
                "Tecnologias de la Informacion",
                "Seguridad y Salud en el Trabajo",
                "Visualizador",
                "Soporte",
              ]}
              redirectPath="/"
            />
          }
        >
          <Route path="home" element={<Home />} />
        </Route>

        <Route
          element={
            <ProtectedRoute
              canActivate={user}
              roles={["Recursos Humanos", "Administrador", "Visualizador"]}
              redirectPath="/home"
            />
          }
        >
          <Route
            path="personal/:group"
            element={<ListPage listType="humanTalent" />}
          />
        </Route>

        <Route
          element={
            <ProtectedRoute
              canActivate={user}
              roles={["Comunicaciones", "Administrador"]}
              redirectPath="/home"
            />
          }
        >
          <Route
            path="comunicaciones"
            element={<ListPage listType="comunications" />}
          />
        </Route>

        <Route
          element={
            <ProtectedRoute
              canActivate={user}
              roles={[
                "Seguridad y Salud en el Trabajo",
                "Administrador",
                "Recursos Humanos",
              ]}
              redirectPath="/home"
            />
          }
        >
          <Route path="sgsst" element={<ListPage listType="sgsst" />} />
        </Route>

        <Route
          element={
            <ProtectedRoute
              canActivate={user}
              roles={[
                "Soporte",
                "Administrador",
         
              ]}
              redirectPath="/home"
            />
          }
        >
          <Route path="soporte" element={<ListPage listType="soporte" />} />
        </Route>

        <Route
          path="*"
          element={
            <Error text="Pagina no encontrada" redirect="/home" robot={robot} />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
