 
import apiService from "./ApiService";

/**
 * The function `getLogs` is an asynchronous function that fetches logs data based on a specified date
 * range and handles different response scenarios.
 *
 * @param {Object} date - An object containing `start_date` and `end_date` properties to specify the date range for fetching logs.
 * @returns {Object} An object with properties `success` indicating the outcome of the API call and `data` containing the response data on success. If there is an error, it returns an object with `success: false` and an error message.
 */
const getLogs = async ( date) => {
  try {
    const response = await apiService.post("logs", {
      start_date: date.start_date,
      end_date: date.end_date
    });
    if (response) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return {
        success: false,
        message: "Error traer los registros",
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      window.location.href = "/error";
    }
    return {
      success: false,
      message: e.response.data.message,
    };
  }
};
export { getLogs };
