import React from "react";
import { Container, Image } from "react-bootstrap";
import { IoArrowBackCircleSharp } from "react-icons/io5";
/**
 * Function component for displaying an error message with optional redirection.
 *
 * @param {string} text - The error message to be displayed
 * @param {string} redirect - The URL to redirect to on user interaction
 * @param {string} robot - The image URL for a robot image
 * @return {JSX.Element} The error message component
 */
const Error = ({ text, redirect, robot }) => {
  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center mt-5"
      fluid
    >
      <div className="text-center" style={{ width: "35%" }}>
        <h1 className="text-blue fw-bold display-5 mt-3 ">¡UPS!</h1>
        <p className="fs-3 text-grey fw-bold">{text}</p>
      </div>

      <Image src={robot} style={{ width: "30%" }} alt="Mascota ccg"  className="my-3"/>
      {redirect && (
        <div className="text-center mt-3">
          <IoArrowBackCircleSharp
            size={50}
            color="#0096C8"
            style={{ cursor: "pointer" }}
            onClick={() => (window.location.href = redirect)}
          />
        </div>
      )}
    </Container>
  );
};

export default Error;
