import React from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import Loading from "../Loading";
import { useModalSupport } from "./useModalSupport";

/**
 * ModalEdit component for editing employee details.
 * @param {Object} props Props passed to the component.
 * @param {Function} props.onClose Callback function to close the modal.
 * @param {Object} props.employee The employee object to be edited.
 * @param {Function} props.onUpdate Callback function to trigger when the employee data is updated.
 * @returns {JSX.Element} The ModalEdit component.
 */
const ModalSupport = ({ onHide, employee, onUpdate }) => {
  const { editEmployee, handleInputChange, handleSubmit, isLoading } =
    useModalSupport({
      employee,
      onUpdate,
      onHide,
    });

  return (
    <Modal
      show={true}
      onHide={onHide}
      id="custom-modal"
      dialogClassName="my-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-blue fw-bold">
          {editEmployee.attributes.nombre}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="tiene_silla">
                  <Form.Label className="fw-bold my-2">
                    ¿Tiene silla?
                  </Form.Label>
                  <Form.Select
                    name="tiene_silla"
                    value={editEmployee.attributes.tiene_silla}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="tiene_computador">
                  <Form.Label className="fw-bold my-2">
                    ¿Tiene computador CCG?
                  </Form.Label>
                  <Form.Select
                    name="tiene_computador"
                    value={editEmployee.attributes.tiene_computador}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="tiene_mouse">
                  <Form.Label className="fw-bold my-2">
                    ¿Tiene mouse?
                  </Form.Label>
                  <Form.Select
                    name="tiene_mouse"
                    value={editEmployee.attributes.tiene_mouse}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="tiene_camara">
                  <Form.Label className="fw-bold my-2">
                    ¿Tiene cámara?
                  </Form.Label>
                  <Form.Select
                    name="tiene_camara"
                    value={editEmployee.attributes.tiene_camara}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="tiene_teclado">
                  <Form.Label className="fw-bold my-2">
                    ¿Tiene teclado?
                  </Form.Label>
                  <Form.Select
                    name="tiene_teclado"
                    value={editEmployee.attributes.tiene_teclado}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="acta">
                  <Form.Label className="fw-bold my-2">
                    Acta de entrega:
                  </Form.Label>
                  <Form.Control
                    type="url"
                    name="acta"
                    value={editEmployee.attributes.acta}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="tiene_diadema">
                  <Form.Label className="fw-bold my-2">
                    ¿Tiene diadema?
                  </Form.Label>
                  <Form.Select
                    name="tiene_diadema"
                    value={editEmployee.attributes.tiene_diadema}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="tiene_compartida">
                  <Form.Label className="fw-bold my-2">
                    ¿Tiene compartida?
                  </Form.Label>
                  <Form.Select
                    name="tiene_compartida"
                    value={editEmployee.attributes.tiene_compartida}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="tiene_vpn">
                  <Form.Label className="fw-bold my-2">¿Tiene VPN?</Form.Label>
                  <Form.Select
                    name="tiene_vpn"
                    value={editEmployee.attributes.tiene_vpn}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="paz_salvo_TI">
                  <Form.Label className="fw-bold my-2">Paz y salvo</Form.Label>
                  <Form.Select
                    name="paz_salvo_TI"
                    value={editEmployee.attributes.paz_salvo_TI}
                    onChange={handleInputChange}
                  >
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
              <Form.Group controlId="tiene_biometrico">
                <Form.Label className="fw-bold my-2">
                  ¿Tiene biometrico?
                </Form.Label>
                <Form.Select
                  name="tiene_biometrico"
                  value={editEmployee.attributes.tiene_biometrico}
                  onChange={handleInputChange}
                >
                  <option value={1}>Sí</option>
                  <option value={0}>No</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="correo_corporativo">
                <Form.Label className="fw-bold my-2">
                  Correo corporativo
                </Form.Label>
                <Form.Control
                  type="email"
                  name="correo_corporativo"
                  value={editEmployee.attributes.correo_corporativo}
                  onChange={handleInputChange}
                  placeholder="Ingrese el correo corporativo"
                  
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, ingrese un correo electrónico válido.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
</Row>
            <Col md={12}>
              <Form.Group controlId="tecnico_asignado">
                <Form.Label className="fw-bold my-2">
                  Técnico asignado:
                </Form.Label>
                <Form.Select
                  name="tecnico_asignado"
                  value={editEmployee.attributes.tecnico_asignado}
                  onChange={handleInputChange}
                >
                  <option value="David Sierra">David Sierra</option>
                  <option value="Miguel Sierra">Miguel Sierra</option>
                  <option value="Leonardo Villada">Leonardo Villada</option>
                  <option value="Brayan Romero">Brayan Romero</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Row>
              <Col md={6}>
                <Form.Group controlId="fecha_entrega_equipos">
                  <Form.Label className="fw-bold my-2">
                    Fecha entrega:
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_entrega_equipos"
                    value={editEmployee.attributes.fecha_entrega_equipos}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="fecha_devolucion_equipo">
                  <Form.Label className="fw-bold my-2">
                    Fecha devolución:
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_devolucion_equipo"
                    value={editEmployee.attributes.fecha_devolucion_equipo}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="observaciones_TI">
              <Form.Label className="fw-bold my-2">
                Observaciones TI:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="observaciones_TI"
                value={editEmployee.attributes.observaciones_TI}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Modal.Footer className="mt-2">
              <Button variant="secondary" onClick={onHide}>
                Cancelar
              </Button>
              <Button className="bg-blue" type="submit" disabled={isLoading}>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalSupport;
