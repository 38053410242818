import { alerts } from "../../utils/Notifications";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/AuthService";

/**
 * Generates a sweet alert with the specified icon, title, and timer.
 *
 * @param {string} icon - The icon for the alert
 * @param {string} title - The title of the alert
 * @param {number} timer - The duration of the alert
 * @return {void}
 */
const useUser = () => {
  const username = localStorage.getItem("name");
  const navigate = useNavigate();

  /**
   * A function that handles the logout process asynchronously. It attempts to log out the user, display a success message using SweetAlert, and remove token, name, and role from the localStorage upon successful logout. In case of an error during the logout process, it displays an error message.
   *
   * @return {void}
   */
  const handleLogout = async () => {
    try {
      const response = await logout();
      if (response) {
        navigate("/");
        alerts("success", "Nos vemos pronto!", 2000);
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("role");
      } else {
        navigate("/");

        alerts("success", "Nos vemos pronto!", 2000);
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("role");
      }
    } catch (e) {
      alerts("error", "Hemos presentado un error!", 2000);
    }
  };

  return { username, handleLogout };
};
export { useUser };
