import React, { useState } from "react";
import { Card, Button, Container, Image } from "react-bootstrap";
import { ModalConfigAdd } from "../../Components/ModalConfigAdd";
import { ModalConfigDelete } from "../../Components/ModalConfigDelete";
import Header from "../../Components/Header";
import imageConfig from "../../Assets/Mascota-config.png";

/**
 * The `renderCard` function in the provided code snippet is a helper function that generates a Card
 * component with specific content based on the `title` and `type` parameters passed to it. Here's a
 * breakdown of what the function does:
 * @param {string} title - The title for the Card component.
 * @param {string} type - The type of content for the Card component.
 * @return {JSX.Element} The Card component with specific content based on the `title` and `type` parameters.
 */
const Configuration = () => {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [type, setType] = useState("");

  /**
   * A function that renders a Card component with specific content based on the 'title' and 'type' parameters.
   *
   * @param {string} title - The title for the Card component.
   * @param {string} type - The type of content for the Card component.
   * @return {JSX.Element} The rendered Card component with specified content.
   */
  const renderCard = (title, type) => (
    <Card
      style={{ width: "20rem" }}
      className="shadow-lg p-3 mb-5 rounded mx-3"
    >
      <Card.Body className="d-flex flex-column align-items-center">
        <Card.Title className="text-center fw-bold text-blue">
          {title}
        </Card.Title>
        <Button
          className="btn bg-blue w-75 my-2"
          onClick={() => {
            setModalTitle(`Crear ${title}`);
            setShowModalAdd(true);
            setType(type);
          }}
        >
          Crear
        </Button>
        <Button
          className="btn bg-blue w-75 my-2"
          onClick={() => {
            setModalTitle(`Gestionar ${title}`);
            setShowDeleteModal(true);
            setType(type);
          }}
        >
          Gestionar
        </Button>
      </Card.Body>
    </Card>
  );

  return (
    <>
      <Header />
      <div className="d-flex align-items-center justify-content-center mt-5">
        <h1 className="text-blue display-4 fw-bold my-5 mx-4">
          Configuración{" "}
        </h1>
        <Image
          src={imageConfig}
          alt="Mascota ccg"
          style={{ width: "10%" }}
          fluid
        />
      </div>
      <Container
        className="
      d-flex flex-column justify-content-center align-items-center text-center mt-5"
      >
        <div className="d-flex justify-content-center align-items-center">
          {renderCard("Usuarios", "users")}
          {renderCard("Campañas", "campaign")}
          {renderCard("Áreas", "area")}
        </div>
      </Container>

      <ModalConfigAdd
        show={showModalAdd}
        type={type}
        onHide={() => setShowModalAdd(false)}
        title={modalTitle}
      />

      <ModalConfigDelete
        show={showDeleteModal}
        type={type}
        onHide={() => setShowDeleteModal(false)}
        title={modalTitle}
      />
    </>
  );
};

export default Configuration;
