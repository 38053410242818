import { useState, useEffect, useCallback } from "react";
import { getActiveAreas } from "../../services/AreaService";
import { getCampaingsActive } from "../../services/CampaingService";

/**
 * Custom hook for managing the sidebar state and data fetching.
 *
 * @param {Object} setShow - Function to set the show state
 * @param {Function} onMenuClick - Function to handle menu clicks
 * @return {Object} The state, actions, and error handling for the sidebar
 */
const useSideBar = ({ setShow, onMenuClick }) => {
  const [campaings, setCampaings] = useState([]);
  const [areas, setAreas] = useState([]);
  const [error, setError] = useState(null);

  /**
   * Asynchronously fetches data using a service function and updates the state.
   *
   * @param {Function} serviceFunction - The function that fetches data.
   * @param {Function} setData - The function to update the state with fetched data.
   */
  const fetchData = async (serviceFunction, setData) => {
    try {
      const response = await serviceFunction();
      if (response.success) {
        setData(response.data);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(`Error fetching data: ${error.message}`);
    }
  };
  /**
   * Function to fetch active campaigns and update the campaings state.
   */
  const getCampaings = useCallback(
    () => fetchData(getCampaingsActive, setCampaings),
    []
  );
  /**
   * Function to fetch active areas and update the areas state.
   */
  const getAreas = useCallback(() => fetchData(getActiveAreas, setAreas), []);

  /**
   * A function that handles the menu click event by calling the provided onMenuClick function with the given id, type, and name parameters.
   *
   * @param {type} id - Description of id parameter
   * @param {type} type - Description of type parameter
   * @param {type} name - Description of name parameter
   * @return {type} Description of the return value
   */
  const handleMenuClick = (id, type, name) => {
    onMenuClick(id, type, name);
  };

  useEffect(() => {
    getCampaings();
    getAreas();
  }, [setShow, getCampaings, getAreas]);

  return {
    campaings,
    areas,
    error,
    handleMenuClick,
  };
};

export { useSideBar };
