 
import apiService from "./ApiService";

/**
 * This function adds children data by making a POST request to the API endpoint 'children' and handles different response scenarios.
 *
 * @param {Object} children - The children data to be added.
 * @returns {Object} An object with a `success` property indicating the success status of the operation. If successful, `success` is set to true; otherwise, it's false along with an `error` message.
 */
const addChildren = async (children) => {
  try {
    const response = await apiService.post("children", children);
    if (response && response.status === 201) {
      return {
        success: true,
      };
    } else {
      return { success: false };
    }
  } catch (e) {

    return { success: false, error: "Error al registrar los datos" };
  }
};

/**
 * Retrieves children data based on the given employee ID.
 *
 * @param {number} employeeId - The ID of the employee to fetch children for.
 * @return {Object} An object with a `success` property indicating the success status of the operation. If successful, `success` is true, and `data` contains the children data; otherwise, `success` is false with an optional `message`.
 */
const getChildrenByEmployeeId = async (employeeId) => {
  try {
    const response = await apiService.get(
      `children/children-by-employee/${employeeId}`
    );
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else return { success: false };
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};
export { addChildren,getChildrenByEmployeeId };
