import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Loading from "../Loading";
import { useUpdatePassword } from "./useUpdatePassword";

/**
 * Renders a modal for updating the user's password.
 *
 * @param {Object} updatePassword - Function to update the user's password
 * @return {JSX.Element} A modal for updating the password
 */
const UpdatePassword = ({ updatePassword }) => {
  const {
    handleSubmit,
    handlePasswordChange,
    handlepasswordconfirmationChange,
    newPassword,
    password_confirmation,
    showModal,
    isLoading,
  } = useUpdatePassword();
  return (
    <Modal show={showModal && updatePassword} centered backdrop="static">
      <Modal.Header>
        <Modal.Title className="text-blue ">
          Actualiza tu contraseña
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
            isLoading ? <Loading isLoading={isLoading} />
            
    :(
       
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingresa tu nueva contraseña"
              minLength="8"
              value={newPassword}
              onChange={handlePasswordChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirmar nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirma tu nueva contraseña"
              value={password_confirmation}
              onChange={handlepasswordconfirmationChange}
            />
          </Form.Group>

          <Button
            type="submit"
            className="btn bg-blue text-white w-100 mt-3 mx-auto"
          >
            Cambiar contraseña
          </Button>
        </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePassword;
