import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useUser } from "./useUser";

/**
 * Renders the User component with specified color, margin, position, and username.
 *
 * @param {string} color - The color for text and icons
 * @param {number} marginX - The horizontal margin
 * @param {boolean} position - The position of the content
 * @param {number} marginY - The vertical margin
 * @return {JSX.Element} The rendered User component
 */
const User = ({ color, marginX, position,marginY }) => {
  const { username, handleLogout } = useUser();

  return (
    <Container fluid>
      <Row
        className={`text-${color} 
        d-flex align-items-center mx-${marginX} my-${marginY} ${
          position ? "justify-content-end" : ""
        }`}
      >
        <Col xs="auto" className="d-flex align-items-center">
          <FaUserAlt className="mx-3" size={20} />
          <strong className="fs-6 text-nowrap">{username}</strong>
          <RiLogoutCircleRLine
            size={25}
            className="mx-2 icon"
            onClick={handleLogout}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default User;
