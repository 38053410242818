import { Modal, Row, Col } from "react-bootstrap";
import { BsPersonExclamation } from "react-icons/bs";
import Loading from "../Loading";
import { useModalChildren } from "./useModalChildren";
/**
 * Renders the Modal component and its children based on the employee data.
 *
 * @param {function} onClose - The function to be called when the modal is closed
 * @param {object} employee - The employee data used to render the modal and its children
 * @return {JSX.Element} The rendered Modal component and its children
 */
const ModalChildren = ({ onClose, employee }) => {
  
  const { isLoading, children, calculateAge } = useModalChildren({ employee });

  return (
    <Modal
      show={true}
      onHide={onClose}
      id="custom-modal"
      dialogClassName="my-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-blue fw-bold">
          {employee.attributes.nombre}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            {children.length === 0 ? (
              <p className="text-center fw-bold fs-5">No hay registros.</p>
            ) : (
              <Row>
                {children.map((child) => (
                  <div className="d-flex align-items-center mb-3" key={child.id}>
                    <BsPersonExclamation size={40} color="#0096C8" />
                    <div className="p-3">
                      <div>
                        <span className="fw-bold me-1">Nombre:</span>
                        {child.attributes.nombre}
                      </div>
                      <div>
                        <span className="fw-bold me-1">Identificación:</span>
                        {child.attributes.identificacion}
                      </div>
                      <div>
                        <span className="fw-bold me-1">Fecha de Nacimiento:</span>
                        {child.attributes.fecha_nacimiento}
                      </div>
                      <div>
                        <span className="fw-bold me-1">Edad: :</span>
                        {calculateAge(child.attributes.fecha_nacimiento).anios} años{" "}
                        {calculateAge(child.attributes.fecha_nacimiento).meses} meses
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalChildren;
