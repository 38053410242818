 
import apiService from "./ApiService";

/**
 * Asynchronously retrieves active campaigns data and handles different response scenarios.
 *
 * @return {object} An object with properties success and data, or success and message indicating the success of the retrieval operation.
 */
const getCampaingsActive = async () => {
  try {
    const response = await apiService.get("/campaing/active-campaings");
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Asynchronously creates a campaign using a POST request and handles different response scenarios.
 *
 * @param {object} campaing - The campaing object containing the details of the campaign to be created.
 * @return {object} An object with properties success and message indicating the success of the creation operation.
 */
const createCampaing = async (campaing) => {
  try {
    const response = await apiService.post("/campaings", campaing);
    if (response && response.status === 201) {
      return { success: true, message: response.data.message };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false, message: e.response.data.message };
  }
};

/**
 * Asynchronously retrieves campaigns data and handles different response scenarios.
 * 
 * @return {object} An object with properties success and data, or success and message indicating the success of the retrieval operation.
 */
const getCampaings = async () => {
  try {
    const response = await apiService.get("/campaings");
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};
/**
 * Asynchronously deletes a campaign using a DELETE request and handles different response scenarios.
 *
 * @param {object} campaing - The ID of the campaign to delete.
 * @return {object} An object with a property `success` indicating the success of the deletion operation, 
 * and an optional property `message` with an error message in case of failure.
 */
const deleteCampaing = async (campaing) => {
  try {
    const response = await apiService.delete(`campaings/${campaing}`);
    if (response && response.status === 200) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return {
      success: false,
    };
  }
};

/**
 * Asynchronously updates a campaign using a PUT request and handles different response scenarios.
 *
 * @param {object} campaing - The campaing object containing the details of the campaign to be updated.
 * @return {object} An object with properties success and message indicating the success of the update operation.
 */
const updateCampaing = async (campaing) => {
  try {
    const response = await apiService.put(`campaings/${campaing.id}`, {
      _method: "PUT",
      ...campaing.attributes,
    });
    if (response && response.status === 200) {
      return {
        success: true,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        message: response.data.message,
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return {
      success: false,
      message: e.response.data.message,
    };
  }
};

export {
  getCampaings,
  getCampaingsActive,
  createCampaing,
  deleteCampaing,
  updateCampaing,
};
