import React from "react";
import {
  MdCheckCircle,
  MdEdit,
  MdCancel,
  MdOutlineSaveAs,
} from "react-icons/md";
import { Button, Modal, Table, Form } from "react-bootstrap";
import { useModalConfigDelete } from "./useModalConfigDelete";
import { FaUserCog } from "react-icons/fa";
import { RiDeviceRecoverFill } from "react-icons/ri";
import Loading from "../Loading";
/**
 * Function component for rendering a modal to configure and delete items.
 *
 * @param {boolean} show - Flag indicating whether the modal is shown or not.
 * @param {function} onHide - Function to handle modal close event.
 * @param {string} title - Title of the modal.
 * @param {string} type - Type of data being configured and deleted.
 * @return {JSX.Element} The modal component with the specified configuration.
 */
const ModalConfigDelete = ({ show, onHide, title, type }) => {
  const {
    usersActive,
    areas,
    campaings,
    handleDeleteClick,
    isLoading,
    handleEditClick,
    editingUser,
    handleInputChange,
    handleSaveClick,
    errors,
    handleRecoverClick,
  } = useModalConfigDelete({
    show,
    onHide,
    title,
    type,
  });

  const tableData = {
    area: {
      items: areas,
      nameField: "name",
      statusField: "status",
      idField: "id",
    },
    users: {
      items: usersActive,
      nameField: "name",
      roleField: "role",
      statusField: "status",
      idField: "id",
    },
    campaign: {
      items: campaings,
      nameField: "name",
      statusField: "status",
      idField: "id",
    },
  };
  /**
   * Renders a table based on the table data type.
   *
   * @return {JSX.Element} Rendered table component
   */
  const renderTable = () => {
    const tableInfo = tableData[type];
    if (!tableInfo) {
      return <p>Tipo de tabla no válido</p>;
    }

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              {type === "area"
                ? "Nombre de Área"
                : type === "campaign"
                ? "Nombre de Campaña"
                : "Nombre Usuario"}
            </th>
            {type === "users" && <th>Roles</th>}
            <th>Editar</th>
            <th>Estado</th>
            {type === "users" && <th>Contraseña</th>}
          </tr>
        </thead>
        <tbody>
          {tableInfo.items.map((item) => (
            <tr key={item.id}>
              <td>
                {editingUser && editingUser.id === item.id ? (
                  <>
                    <div className="d-flex align-items-center">
                      <Form.Control
                        disabled={editingUser.id !== item.id}
                        defaultValue={item.attributes.name}
                        aria-label={`${type}Name`}
                        onChange={(e) => handleInputChange(e, "name")}
                      />
                      {type !== "users" && (
                        <MdOutlineSaveAs
                          className="ms-2"
                          size={25}
                          color="green"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleSaveClick(item)}
                        />
                      )}
                    </div>
                    {errors.name && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                  </>
                ) : (
                  item.attributes.name
                )}
              </td>
              {type === "users" && (
                <td>
                  {editingUser && editingUser.id === item.id ? (
                    <div className="d-flex align-items-center">
                      <Form.Select
                        disabled={editingUser.id !== item.id}
                        defaultValue={item.attributes.role}
                        aria-label="role"
                        onChange={(e) => handleInputChange(e, "role")}
                      >
                        <option value="1">Administrador</option>
                        <option value="2">Recursos Humanos</option>
                        <option value="3">Comunicaciones</option>
                        <option value="Seguridad y Salud en el Trabajo">
                          Seguridad y Salud en el Trabajo
                        </option>
                        <option value="5">Tecnologías de la Información</option>
                      </Form.Select>
                      <MdOutlineSaveAs
                        className="ms-2"
                        size={25}
                        color="green"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSaveClick(item)}
                      />
                      {errors.role && (
                        <small className="text-danger">{errors.role}</small>
                      )}
                    </div>
                  ) : (
                    item.attributes.role
                  )}
                </td>
              )}
              <td>
                <Button variant="outline" className="ms-2">
                  <MdEdit
                    color="#0096C8"
                    onClick={() => handleEditClick(item)}
                  />
                </Button>
              </td>

              <td>
                {item.attributes.status === 0 ? (
                  <MdCancel
                    color="red"
                    onClick={() => handleDeleteClick(item.id)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "auto",
                    }}
                  />
                ) : (
                  <MdCheckCircle
                    color="green"
                    onClick={() => handleDeleteClick(item.id)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "auto",
                    }}
                  />
                )}
              </td>
              {type === "users" && (
                <td>
                  <RiDeviceRecoverFill
                    onClick={() => handleRecoverClick(item.attributes.email)}
                    color="#0096C8"
                    style={{
                      cursor: "pointer",
                      marginLeft: "auto",
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-blue d-flex align-items-center ">
          <FaUserCog size={30} className="me-2" />
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? <Loading isLoading={isLoading} /> : renderTable()}
      </Modal.Body>
    </Modal>
  );
};

export { ModalConfigDelete };
