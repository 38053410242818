import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { getEmployeeByIdentification } from "../../services/EmployeeService";
const useFormDemographic = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    numero_identificacion: "",
  };

  const onSubmit = async (values) => {
    setError(null);
    setIsSubmitting(true);

    try {
      const response = await getEmployeeByIdentification(values);

      if (response && response.success) {
        if (response.data) {
          if (response.data.attributes.encuesta_demografica) {
            setError({
              type: "completed",
              message:
                "Ya has completado la encuesta de datos, si necesitas actualizarla, ponte en contacto con Recursos Humanos",
            });
          } else {
          

            navigate("/encuesta-sociodemografica");
          }
        }
      } else {
        setError({ type: "Error", message: response.message });
      }
    } catch (error) {
      setError("Error al procesar la solicitud");
    } finally {
      setIsSubmitting(false);
    }
  };

  const schema = yup.object().shape({
    numero_identificacion: yup
      .string()
      .required("El número de identificación es obligatorio")
      .matches(
        /^\d+$/,
        "El número de identificación debe contener solo números"
      ),
  });

  return {
    initialValues,
    onSubmit,
    schema,
    error,
    isSubmitting,
    setError,
    setIsSubmitting,
  };
};

export { useFormDemographic };
