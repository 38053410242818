import { useState, useEffect, useMemo, useCallback } from "react";
import { getEmployeeDemographic } from "../../services/EmployeeService";
import { alerts } from "../../utils/Notifications";
/**
 * Generates a custom hook to manage and filter a list of employee.
 *
 * @param {object} id - The unique identifier of the employee.
 * @param {string} type - The type of communication.
 * @param {string} name - The name of the employee.
 * @param {boolean} show - A boolean flag to determine if the employee should be displayed.
 * @return {object} An object containing functions and states for sorting, filtering, and handling employee data.
 */
const useListDemographic = ({ id, type, name, show }) => {
  const [employees, setEmployees] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "ascending",
  });
  const [filterValue, setFilterValue] = useState("");

  const sortedEmployees = useMemo(() => {
    if (sortConfig.key === null) {
      return [];
    }

    const sortedEmployees = [...employees].sort((a, b) => {
      const keyA = a.attributes[sortConfig.key];
      const keyB = b.attributes[sortConfig.key];

      return sortConfig.direction === "ascending"
        ? keyA > keyB
          ? 1
          : -1
        : keyB > keyA
        ? 1
        : -1;
    });

    return sortedEmployees;
  }, [employees, sortConfig]);

  const filteredEmployees = sortedEmployees.filter((employee) => {
    return Object.values(employee.attributes).some((value) =>
      String(value).toLowerCase().includes(filterValue.toLowerCase())
    );
  });
  const calculateAge = (fechaNacimiento) => {
    const hoy = new Date();
    const fechaNac = new Date(fechaNacimiento);

    let edad = hoy.getFullYear() - fechaNac.getFullYear();

    const cumpleaniosEsteAno =
      hoy.getMonth() > fechaNac.getMonth() ||
      (hoy.getMonth() === fechaNac.getMonth() &&
        hoy.getDate() >= fechaNac.getDate());

    if (!cumpleaniosEsteAno) {
      edad--;
    }
    if (edad <= 0) {
      edad = 0;
    }
    return edad;
  };
  const formatDate = (fecha) => {
    if (!fecha) return ""; 
    const date = new Date(fecha); 

    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;

    return formattedDate;
  };
  const csvData = filteredEmployees.map((employee) => ({
    id: employee.id,
    nombre: employee.attributes.nombre,
    campaing: employee.attributes.campaing,
    area: employee.attributes.area,
    cargo: employee.attributes.cargo,
    tipo_identificacion: employee.attributes.tipo_identificacion,
    numero_identificacion: employee.attributes.numero_identificacion,
    tipo_contrato: employee.attributes.tipo_contrato,
    lugar_nacimiento: employee.attributes.lugar_nacimiento,
    nivel_educativo: employee.attributes.nivel_educativo,
    modalidad_trabajo: employee.attributes.modalidad_trabajo,
    fecha_inicio: formatDate(employee.attributes.fecha_inicio),
    correo_electronico: employee.attributes.correo_electronico,
    "Correo Corporativo": employee.attributes.correo_corporativo,
  
    telefono: employee.attributes.telefono,
    direccion: employee.attributes.direccion, 
    ciudad: employee.attributes.ciudad,
    genero: employee.attributes.genero,
    fecha_nacimiento: formatDate(employee.attributes.fecha_nacimiento),
    edad: calculateAge(employee.attributes.fecha_nacimiento),
    profesion: employee.attributes.profesion,
    tiene_hijo: employee.attributes.tiene_hijo === 1 ? "Si" : "No",
    cantidad_hijos: employee.attributes.cantidad_hijos,
    orientacion_sexual: employee.attributes.orientacion_sexual,
    pais: employee.attributes.pais,
    nacionalidad: employee.attributes.nacionalidad,
    departamento: employee.attributes.departamento,
    tipo_vivienda: employee.attributes.tipo_vivienda,
    tiene_personas_a_cargo: employee.attributes.tiene_personas_a_cargo === 1 ? "Si" : "No",
    cantidad_personas_a_cargo: employee.attributes.cantidad_personas_a_cargo,
    estrato_socioeconomico: employee.attributes.estrato_socioeconomico,
    estado_civil: employee.attributes.estado_civil,
    raza: employee.attributes.raza,
    usted_ha_sido_victima: employee.attributes.usted_ha_sido_victima,
    tiempo_libre_actividad: employee.attributes.tiempo_libre_actividad,
    practica_deporte: employee.attributes.practica_deporte === 1 ? "Si" : "No",
    detalle_deporte: employee.attributes.detalle_deporte,
    consume_bebidas_alcoholicas: employee.attributes.consume_bebidas_alcoholicas === 1 ? "Si" : "No",
    frecuencia_alcoholica: employee.attributes.frecuencia_alcoholica,
    fuma: employee.attributes.fuma === 1 ? "Si" : "No",
    frecuencia_fuma: employee.attributes.frecuencia_fuma,
    le_han_diagnosticado_enfermedad: employee.attributes.le_han_diagnosticado_enfermedad === 1 ? "Si" : "No",
    detalle_enfermedad: employee.attributes.detalle_enfermedad,
    tiene_antecedentes_familiares_patologicos: employee.attributes.tiene_antecedentes_familiares_patologicos === 1 ? "Si" : "No",
    detalle_enfermedad_patologica: employee.attributes.detalle_enfermedad_patologica,
    peso: employee.attributes.peso,
    estatura: employee.attributes.estatura,
    tipo_sangre: employee.attributes.tipo_sangre,
    consume_medicamento: employee.attributes.consume_medicamento === 1 ? "Si" : "No",
    detalle_medicamento: employee.attributes.detalle_medicamento,
    sufre_alergias: employee.attributes.sufre_alergias === 1 ? "Si" : "No",
    detalle_alergia: employee.attributes.detalle_alergia,
    persona_pensionado: employee.attributes.persona_pensionado === 1 ? "Si" : "No",
    gestantes: employee.attributes.gestantes === 1 ? "Si" : "No",
    lactancia: employee.attributes.lactancia === 1 ? "Si" : "No",
    discapacidad: employee.attributes.discapacidad === 1 ? "Si" : "No",
    tipo_discapacidad: employee.attributes.tipo_discapacidad,
    experiencia_cargo: employee.attributes.experiencia_cargo,
    eps: employee.attributes.eps,
    arl: employee.attributes.arl,
    afp: employee.attributes.afp,
    nombre_contacto: employee.attributes.nombre_contacto,
    telefono_contacto: employee.attributes.telefono_contacto,
  }));
  
  const headersCsv = Object.keys(csvData[0] || {});
  /**
   * Updates the sort configuration based on the given key.
   *
   * @param {string} key - The key to sort by
   * @return {void}
   */
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    setSortConfig({ key, direction });
  };

  /**
   * A function that handles the change event for the filter.
   *
   * @param {Event} event - the event object triggering the change
   * @return {void}
   */
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };
  /**
   * A function that handles copying the values of a specified column for each employee
   *
   * @param {string} columnKey - the key of the column to copy
   * @return {void}
   */
  const handleCopyColumn = (columnKey) => {
    const columnValues = filteredEmployees.map(
      (employee) => employee.attributes[columnKey]
    );
    const textToCopy = columnValues.join("\n");

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alerts("success", "Datos copiadas", 2000);
      })
      .catch(() => {
        alerts("error", "Error al copiar los datos!", 3000);
      });
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getEmployeeDemographic();

      if (response.success) {
        let filteredData;

        if (type === "general") {
          filteredData = response.data;
        } else {
          filteredData = response.data.filter((employee) => {
            const { campaing_id, area_id } = employee.attributes;
            return (
              (type === "campaing" && campaing_id === id) ||
              (type === "area" && area_id === id)
            );
          });
        }

        setEmployees(filteredData);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      console.error("Error fetching employee demographic data:", error);
      setEmployees([]);
    } finally {
      setIsLoading(false);
    }
  }, [id, type]);

  useEffect(() => {
    fetchData();
    if (isUpdated) {
      setIsUpdated(false);
    }
  }, [fetchData, isUpdated]);

  const handleUpdate = () => {
    setIsUpdated(true);
  };
  return {
    requestSort,
    handleCopyColumn,
    isLoading,
    sortedEmployees,
    handleFilterChange,
    filterValue,
    filteredEmployees,
    csvData,
    headersCsv,
    isEditModalOpen,
    setIsEditModalOpen,
    selectedEmployee,
    setSelectedEmployee,
    handleUpdate,
    calculateAge
  };
};

export { useListDemographic };
