import { useState, useEffect, useMemo, useCallback } from "react";
import { addMonths } from "date-fns";
import { useParams } from "react-router-dom";
import { getAllEmployees } from "../../services/EmployeeService";
/**
 * A custom React hook that manages employees data, including filtering, sorting, and handling updates.
 *
 * @param {object} options - An object containing the id and type properties.
 * @param {string} options.id - The id used for fetching the employees.
 * @param {string} options.type - The type of employees to fetch.
 * @return {object} An object containing various state variables and functions to interact with the employees data.
 */
const useListGeneral = ({ id, type }) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isChildrenModalOpen, setIsChildrenModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterGroup, setFilterGroup] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const { group } = useParams();
  const todayPlusOneMonth = useMemo(() => addMonths(new Date(), 1), []);
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "ascending",
  });

  const sortedEmployees = useMemo(() => {
    if (!sortConfig.key) {
      return [...employees];
    }

    const sortedEmployees = [...employees].sort((a, b) => {
      let keyA = a.attributes[sortConfig.key];
      let keyB = b.attributes[sortConfig.key];

      // Verificar si las claves son undefined
      if (keyA === undefined) keyA = "";
      if (keyB === undefined) keyB = "";

      // Convertir a cadena si no es una cadena
      if (typeof keyA !== "string") {
        keyA = keyA.toString();
      }
      if (typeof keyB !== "string") {
        keyB = keyB.toString();
      }

      if (keyA === "0") keyA = 0;
      if (keyB === "0") keyB = 0;
      if (keyA === "1") keyA = 1;
      if (keyB === "1") keyB = 1;

      return sortConfig.direction === "ascending"
        ? isNaN(keyA) || isNaN(keyB)
          ? keyA.localeCompare(keyB)
          : keyA - keyB
        : isNaN(keyA) || isNaN(keyB)
        ? keyB.localeCompare(keyA)
        : keyB - keyA;
    });

    return sortedEmployees;
  }, [employees, sortConfig]);

  /**
   * A description of the entire function.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  /**
   * A function to update the sorting configuration based on the key provided.
   *
   * @param {string} key - The key to set for sorting.
   * @return {void} This function does not return anything.
   */
  const requestSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAllEmployees();

      if (response.success) {
        let filteredData;

        if (type === "general") {
          filteredData = response.data;
        } else {
          filteredData = response.data.filter((employee) => {
            const { campaing_id, area_id } = employee.attributes;
            return (
              (type === "campaing" && campaing_id === id) ||
              (type === "area" && area_id === id)
            );
          });
        }

        setEmployees(filteredData);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      console.error("Error fetching employee demographic data:", error);
      setEmployees([]);
    } finally {
      setIsLoading(false);
    }
  }, [id, type]);

  useEffect(() => {
    fetchData();
    if (isUpdated) {
      setIsUpdated(false);
    }
  }, [fetchData, isUpdated]);

  useEffect(() => {
    if (group === "contratistas") {
      setFilterGroup("Prestación de servicios");
    } else if (group === "empleados") {
      setFilterGroup("No prestación de servicios");
    }
  }, [group]);

  /**
   * A description of the entire function.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  const handleUpdate = () => {
    setIsUpdated(true);
  };

  const filteredByContractType = useMemo(() => {
    return sortedEmployees.filter((employee) => {
      if (filterGroup === "Prestación de servicios") {
        return employee.attributes.tipo_contrato === "Prestación de servicios";
      } else if (filterGroup === "No prestación de servicios") {
        return employee.attributes.tipo_contrato !== "Prestación de servicios";
      }
      return true;
    });
  }, [sortedEmployees, filterGroup]);

  /**
   * Filters the employees array based on the provided status.
   *
   * @param {array} employees - The array of employees to filter.
   * @param {string} status - The status to filter employees by.
   * @return {array} The filtered array of employees.
   */
  const filterByStatus = (employees, status) => {
    return employees.filter((employee) =>
      status === "activo"
        ? employee.attributes.estado === 1
        : employee.attributes.estado === 0
    );
  };

  const filteredEmployees = useMemo(() => {
    let filtered = filteredByContractType;

    if (filterValue) {
      const lowercaseFilterValue = filterValue.toLowerCase();
      if (["activo", "inactivo"].includes(lowercaseFilterValue)) {
        filtered = filterByStatus(filtered, lowercaseFilterValue);
      } else {
        filtered = filtered.filter((employee) => {
          const attributes = employee.attributes;
          return (
            Object.values(attributes).some((value) =>
              String(value).toLowerCase().includes(lowercaseFilterValue)
            ) ||
            (attributes.campaign && attributes.campaign.name
              ? attributes.campaign.name
                  .toLowerCase()
                  .includes(lowercaseFilterValue)
              : false) ||
            (attributes.area && attributes.area.name
              ? attributes.area.name
                  .toLowerCase()
                  .includes(lowercaseFilterValue)
              : false)
          );
        });
      }
    }

    return filtered;
  }, [filteredByContractType, filterValue]);
  // Función para formatear la fecha
  const formatDate = (fecha) => {
    if (!fecha) return ""; 
    const date = new Date(fecha); 

    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;

    return formattedDate;
  };
  const csvData = filteredEmployees.map((employee) => ({
    ID: employee.id,
    "Nombre completo": employee.attributes.nombre.toUpperCase(),
    Estado: employee.attributes.estado === 1 ? "Activo" : "Inactivo",
    Campaña: employee.attributes.campaing.name,
    Área: employee.attributes.area.name,
    Cargo: employee.attributes.cargo,
    "Número de Identificación": employee.attributes.numero_identificacion,
    "Tipo de Identificación": employee.attributes.tipo_identificacion,
    "Correo Electrónico": employee.attributes.correo_electronico.toUpperCase(),
    "Correo Corporativo": employee.attributes.correo_corporativo,

    "Fecha de Nacimiento":formatDate(employee.attributes.fecha_nacimiento),
    Banco: employee.attributes.banco,
    Cuenta: employee.attributes.numero_cuenta,
    Dirección: employee.attributes.direccion,
    Ciudad: employee.attributes.ciudad,
    Género: employee.attributes.genero,
    "Nivel Educativo": employee.attributes.nivel_educativo,
    Profesion: employee.attributes.profesion,
    Teléfono: employee.attributes.telefono,
    "Estado Contrato": employee.attributes.estado_contrato,
    "Estado de la Liquidación":
      employee.attributes.liquidacion === "Pagada"
        ? "Pagada"
        : "Pendiente por pagar",
    "Modalidad de Trabajo": employee.attributes.modalidad_trabajo,

    "Tipo de Contrato": employee.attributes.tipo_contrato,
    Salario: employee.attributes.salario,
    "Fecha de Inicio": formatDate(employee.attributes.fecha_inicio),
    "Fecha de Fin": formatDate(employee.attributes.fecha_fin) || "-",
    "Fecha de Afiliación EPS": formatDate(employee.attributes.fecha_afiliacion_eps),
    "Fecha de Desafiliación EPS": formatDate(employee.attributes.fecha_desafiliacion_eps),
    EPS: employee.attributes.eps,
    "Fecha de Afiliación ARL": formatDate(employee.attributes.fecha_afiliacion_arl),
    "Fecha de Desafiliación ARL": formatDate(employee.attributes.fecha_desafiliacion_arl),
    ARL: employee.attributes.arl,
    "Fecha de Afiliación AFP": formatDate(employee.attributes.fecha_afiliacion_afp),
    "Fecha de Desafiliación AFP": formatDate(employee.attributes.fecha_desafiliacion_afp),
    AFP: employee.attributes.afp,
    "Fecha de Afiliación Cesantías":
    formatDate(employee.attributes.fecha_afiliacion_cesantias),
    "Fecha de Desafiliación Cesantías":
    formatDate(employee.attributes.fecha_desafiliacion_cesantias),
    Cesantías: employee.attributes.cesantias,
    "Fecha de Afiliación Caja de Compensación":
    formatDate(employee.attributes.fecha_afiliacion_caja_compensacion),
    "Fecha de Desafiliación Caja de Compensación":
    formatDate(employee.attributes.fecha_desafiliacion_caja_compensacion),
    "Caja de Compensación": employee.attributes.caja_compensacion,
    "Hoja de Vida": employee.attributes.cv === 1 ? "Si" : "No",
    "Documento de Identidad":
      employee.attributes.identificacion === 1 ? "Si" : "No",
    RUT: employee.attributes.RUT === 1 ? "Si" : "No",
    "Seguridad Social":
      employee.attributes.seguridad_social === 1 ? "Si" : "No",
    "Certificado de Estudios": employee.attributes.estudios === 1 ? "Si" : "No",
    "Certificado Laboral":
      employee.attributes.certficado_laboral === 1 ? "Si" : "No",
    "Certificado Bancario":
      employee.attributes.certificado_bancario === 1 ? "Si" : "No",
    "Referencias Laborales":
      employee.attributes.referencias === 1 ? "Si" : "No",
    Antecedentes: employee.attributes.antecedentes === 1 ? "Si" : "No",
    "Paz y Salvo RRHH": employee.attributes.paz_salvo_rrhh === 1 ? "Si" : "No",

    Observaciones: employee.attributes.observaciones,
  }));


  const headersCsv = Object.keys(csvData[0] || {});

  /**
   * Calculate the age based on the input birth date.
   *
   * @param {Date} fechaNacimiento - The birth date to calculate the age from
   * @return {number} The calculated age based on the birth date
   */
  const calculateAge = (fechaNacimiento) => {
    const hoy = new Date();
    const fechaNac = new Date(fechaNacimiento);

    let edad = hoy.getFullYear() - fechaNac.getFullYear();

    const cumpleaniosEsteAno =
      hoy.getMonth() > fechaNac.getMonth() ||
      (hoy.getMonth() === fechaNac.getMonth() &&
        hoy.getDate() >= fechaNac.getDate());

    if (!cumpleaniosEsteAno) {
      edad--;
    }
    if (edad <= 0) {
      edad = 0;
    }
    return edad;
  };

  return {
    selectedEmployee,
    setSelectedEmployee,
    isEditModalOpen,
    setIsEditModalOpen,
    setIsChildrenModalOpen,
    isChildrenModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isLoading,
    requestSort,
    handleFilterChange,
    filteredByContractType,
    employees,
    filteredEmployees,
    csvData,
    headersCsv,
    handleUpdate,
    todayPlusOneMonth,
    calculateAge,
    formatDate,
  };
};

export { useListGeneral };
