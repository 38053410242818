import Swal from "sweetalert2";

export const alerts = (icon, title, timer) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: timer,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    iconColor: "##0d6efd",
  });

  Toast.fire({
    icon: icon,
    title: title,
  });
};
export const alertsWithLoading = (title) => {
  Swal.fire({
    toast: true,
    title: title,
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      Swal.showLoading();
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};


export const closeAlert = () => {
  Swal.close();
};