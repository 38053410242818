import React from "react";
import { Card, Button, Container, Image, Col, Row } from "react-bootstrap";
import UpdatePassword from "../../Components/UpdatePassword";
import { FaPeopleGroup } from "react-icons/fa6";
import { RiMentalHealthFill } from "react-icons/ri";
import { IoIosMegaphone } from "react-icons/io";
import { FaComputer } from "react-icons/fa6";
import Header from "../../Components/Header";
import { useHome } from "./useHome";
import imageHome from "../../Assets/mascota-home.png";
import "./home.css";

/**
 * Renders the Home component which displays a dashboard with different cards based on user permissions.
 *
 * @return {JSX.Element} The rendered Home component with cards based on user permissions.
 */
const Home = () => {
  const {
    canViewRH,
    canViewSgsst,
    canViewComunicaciones,
    canViewSupport,
    updatePassword,
  } = useHome();

  const cardsData = [
    {
      icon: <FaPeopleGroup size="60" color="#0096C8" />,
      title: "Gestión del contratista",
      text: "Podrás gestionar, organizar y observar información detallada de cada contratista de la empresa.",
      href: "/personal/contratistas",
      isVisible: canViewRH,
    },
    {
      icon: <FaPeopleGroup size="60" color="#0096C8" />,
      title: "Gestión de empleados",
      text: "Podrás gestionar, organizar y observar información detallada de cada empleado de la empresa.",
      href: "/personal/empleados",
      isVisible: canViewRH,
    },
    {
      icon: <RiMentalHealthFill size="60" color="#0096C8" />,
      title: "SG - SST",
      text: "Información demografica de los colaboradores activos.",
      href: "/sgsst",
      isVisible: canViewSgsst,
    },
    {
      icon: <IoIosMegaphone size="60" color="#0096C8" />,
      title: "Comunicaciones",
      text: "Información de los colaboradores activos para enviar comunicados personalizados y grupales.",
      href: "/comunicaciones",
      isVisible: canViewComunicaciones,
    },
    {
      icon: <FaComputer size="60" color="#0096C8" />,
      title: "Soporte",
      text: "Gestión y registro de equipos de cómputo asignados y sus respectivas actas.",
      href: "/soporte",
      isVisible: canViewSupport,
    },
  ];

  return (
    <>
      <UpdatePassword updatePassword={updatePassword} />
      <Header />
      <Container className="my-3">
        <Row className="align-items-center justify-content-center mb-4">
          <Col xs="auto">
            <h1 className="text-blue display-4 fw-bold">Talento Humano</h1>
          </Col>
          <Col xs="auto">
            <Image
              src={imageHome}
              alt="Mascota ccg"
              style={{ width: "100px" }}
              fluid
            />
          </Col>
        </Row>

        <Row className="justify-content-center align-items-center">
          {cardsData.map(
            (card, index) =>
              card.isVisible && (
                <Col key={index} lg={5} md={6} sm={6} xs={12} className="mb-3">
                  <Card className="shadow-lg rounded w-100">
                    <Card.Body className="d-flex flex-column align-items-center text-center">
                      {card.icon}
                      <Card.Title className="fw-bold">
                        {card.title}
                      </Card.Title>
                      <Card.Text>{card.text}</Card.Text>
                      <Button className="bg-blue mt-auto" href={card.href}>
                        Ingresar
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              )
          )}
        </Row>
      </Container>
    </>
  );
};

export default Home;
