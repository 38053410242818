import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import section2 from "../../Assets/1.png";
import section3 from "../../Assets/2.png";
import section1 from "../../Assets/2.png";
import FormFirstSection from "../../Components/FormFirstSection";
import FormSecondSection from "../../Components/FormSecondSection";
import FormThirdSection from "../../Components/FormThirdSection";
import CompleteForms from "../../Components/CompleteForms";

const FormDemographic = () => {
  const navigate = useNavigate();
  const employeeId = localStorage.getItem("employeeId");
  const name = localStorage.getItem("name");
  const [section, setSection] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (!employeeId) {
      navigate("/inicio-encuesta-sociodemografica");
    }
  }, [employeeId, navigate]);

  const changeSection = (section) => {
    setSection(section);
  };

  const sectionTexts = {
    1: {
      title: `Hola ${name}`,
      subtitle: "Inicia tu encuesta sociodemográfica:",
    },
    2: {
      title: "Segunda sección",
      subtitle: "Completa tu encuesta sociodemográfica:",
    },
    3: {
      title: "Última sección",
      subtitle: "Finaliza tu encuesta sociodemográfica:",
    },
  };

  const renderImage = () => {
    switch (section) {
      case 1:
        return <Image src={section1} fluid />;
      case 2:
        return <Image src={section2} fluid />;
      case 3:
        return <Image src={section3} fluid />;
      default:
        return null;
    }
  };

  const handleSubmitForm = () => {
    setFormSubmitted(true);
  };

  return (
    <>
      {!formSubmitted ? (
        <Container className="my-5">
          <Row className="align-items-center">
            <Col md={6} xs={12}>
              {renderImage()}
            </Col>
            <Col md={6} xs={12}>
              <h1 className="text-blue fw-bold display-5">
                {sectionTexts[section].title}
              </h1>
              <span className="fs-3 my-1">{sectionTexts[section].subtitle}</span>
              {section === 1 && (
                <FormFirstSection
                  showFirstSection={true}
                  setShowFirstSection={() => changeSection(1)}
                  setShowSecondSection={() => changeSection(2)}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              )}
              {section === 2 && (
                <FormSecondSection
                  showSecondSection={true}
                  setShowSecondSection={() => changeSection(2)}
                  setShowFirstSection={() => changeSection(1)}
                  setShowThirdSection={() => changeSection(3)}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              )}
              {section === 3 && !formSubmitted && (
                <FormThirdSection
                  showThirdSection={true}
                  setShowThirdSection={() => changeSection(3)}
                  setShowSecondSection={() => changeSection(2)}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  onSubmit={handleSubmitForm}
                />
              )}
            </Col>
          </Row>
        </Container>
      ) : (
        <CompleteForms
          title="¡Gracias por completar el formulario!"
          description="Si necesitas actualizar la información, ponte en contacto con Recursos Humanos"
        
        />
      )}
    </>
  );
};

export default FormDemographic;
