import { useState } from "react";
import { updatePassword } from "../../services/AuthService";
import { alerts } from "../../utils/Notifications";

/**
 * Custom hook for managing password update functionality.
 *
 * @return {Object} Object containing state variables and functions for password update
 */
const useUpdatePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(true);


  /**
   * A function that handles form submission asynchronously.
   *
   * @param {Event} e - the event object
   * @return {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== password_confirmation) {
      alerts("error", "Las contraseñas no coinciden", 3000);
      return;
    }

    setIsLoading(true);

    try {
      const response = await updatePassword(newPassword);
      if (response.success) {
        alerts(
          "success",
          "La contraseña se cambió exitosamente",
          2000
        );
        setShowModal(false);
      } else {
        const errors = response?.message || {};
        let errorMessage = "Error al cambiar la contraseña";

        if (Object.keys(errors).length > 0) {
          errorMessage += "<ul>";
          Object.entries(errors).forEach(([field, messages]) => {
            messages.forEach((message) => {
              errorMessage += `<li>${message}</li>`;
            });
          });
          errorMessage += "</ul>";
        }

        alerts("error", errorMessage, 2000);
      }
    } catch (e) {
      alerts("error", "Error al procesas la solicitud", 3000);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * A function that handles the change event for the password input field.
   *
   * @param {Event} e - the event object triggering the change
   * @return {void}
   */
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  
  /**
   * Handles the change event for the password confirmation field.
   *
   * @param {object} e - the event object
   * @return {void}
   */
  const handlepasswordconfirmationChange = (e) => {
    setpassword_confirmation(e.target.value);
  };

  return {
    newPassword,
    password_confirmation,
    isLoading,
    handleSubmit,
    alerts,
    setIsLoading,
    handlePasswordChange,
    handlepasswordconfirmationChange,
    showModal,
  };
};

export { useUpdatePassword };
