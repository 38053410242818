import React from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import Loading from "../Loading";
import { useEditModal } from "./useEditModal";
import "./EditModal.css";

/**
 * ModalEdit component for editing employee details.
 * @param {Object} props Props passed to the component.
 * @param {Function} props.onClose Callback function to close the modal.
 * @param {Object} props.employee The employee object to be edited.
 * @param {Function} props.onUpdate Callback function to trigger when the employee data is updated.
 * @returns {JSX.Element} The ModalEdit component.
 */
const ModalEdit = ({ onClose, employee, onUpdate }) => {
  const {
    editEmployee,
    handleInputChange,
    handleSubmit,
    isLoading,
    error,
    showForm,
    areas,
    campaings,
  } = useEditModal({ employee, onUpdate, onClose });
  return (
    <Modal
      show={true}
      onHide={onClose}
      id="custom-modal"
      dialogClassName="my-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{editEmployee.nombre}</Modal.Title>
      </Modal.Header>
      <Loading isLoading={isLoading} />
      <Modal.Body>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {!isLoading && showForm && (
          <Form onSubmit={handleSubmit}>
            <section>
              <h5>Datos Personales</h5>
              <Row>
                <Col md={12} className="mt-1">
                  <Form.Group controlId="formNombre">
                    <Form.Label>Nombre Completo</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombre"
                      value={editEmployee.nombre}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formNumeroIdentificacion">
                    <Form.Label>Numero de Identificación</Form.Label>
                    <Form.Control
                      type="text"
                      name="numero_identificacion"
                      value={editEmployee.numero_identificacion}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formTipoIdentificación">
                    <Form.Label>Tipo de Identificación</Form.Label>

                    <Form.Select
                      name="tipo_identificacion"
                      value={editEmployee.tipo_identificacion || ""}
                      onChange={handleInputChange}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona una opción
                      </option>
                      <option value="Tarjeta de identidad">
                        Tarjeta de identidad
                      </option>
                      <option value="Cédula de ciudadanía">
                        Cédula de ciudadanía
                      </option>
                      <option value="Cédula de extranjería">
                        Cédula de extranjería
                      </option>
                      <option value="Pasaporte">Pasaporte</option>
                      <option value="PPT">PPT</option>
                      <option value="PEP">PEP</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaNacimiento">
                    <Form.Label>Fecha de Nacimiento</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_nacimiento"
                      value={editEmployee.fecha_nacimiento}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formLugarNacimiento">
                    <Form.Label>Lugar de Nacimiento</Form.Label>
                    <Form.Control
                      type="text"
                      name="lugar_nacimiento"
                      value={editEmployee.lugar_nacimiento}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaExpedicion">
                    <Form.Label>Fecha de Expedición</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_expedicion"
                      value={editEmployee.fecha_expedicion}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaExpedicion">
                    <Form.Label>Lugar de Expedición</Form.Label>
                    <Form.Control
                      type="text"
                      name="lugar_expedicion"
                      value={editEmployee.lugar_expedicion}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formCorreoElectronico">
                    <Form.Label>Correo Electrónico</Form.Label>
                    <Form.Control
                      type="email"
                      name="correo_electronico"
                      value={editEmployee.correo_electronico}
                      onChange={handleInputChange}
                    />
                  </Form.Group>{" "}
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formTelefono">
                    <Form.Label>Celular</Form.Label>
                    <Form.Control
                      type="text"
                      name="telefono"
                      value={editEmployee.telefono}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formBanco">
                    <Form.Label>Banco</Form.Label>
                    <Form.Control
                      type="text"
                      name="banco"
                      value={editEmployee.banco}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formNumeroCuenta">
                    <Form.Label>N° de Cuenta</Form.Label>
                    <Form.Control
                      type="text"
                      name="numero_cuenta"
                      value={editEmployee.numero_cuenta}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-1">
                  <Form.Group controlId="formdireccion">
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control
                      type="text"
                      name="direccion"
                      value={editEmployee.direccion}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formCiudad">
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control
                      type="text"
                      name="ciudad"
                      value={editEmployee.ciudad}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formGenero">
                    <Form.Label>Género</Form.Label>

                    <Form.Select
                      name="genero"
                      value={editEmployee.genero || ""}
                      onChange={handleInputChange}
                    >
                      <option value="Hombre">Hombre</option>
                      <option value="Mujer">Mujer</option>
                      <option value="Prefiero no decirlo">
                        Prefiero no decirlo
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </section>

            <section className="my-2">
              <h5>Datos de Contacto</h5>
              <Row>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formNombreContacto">
                    <Form.Label>Nombre Contacto</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombre_contacto"
                      value={editEmployee.nombre_contacto}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formTelefonoContacto">
                    <Form.Label>Teléfono del Contacto</Form.Label>
                    <Form.Control
                      type="text"
                      name="telefono_contacto"
                      value={editEmployee.telefono_contacto}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </section>

            <section className="mt-2">
              <h5>Datos Laborales</h5>
              <Row>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formCargo">
                    <Form.Label>Cargo</Form.Label>
                    <Form.Control
                      type="text"
                      name="cargo"
                      value={editEmployee.cargo}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formEstado">
                    <Form.Label>Tipo de Contrato</Form.Label>

                    <Form.Select
                      name="tipo_contrato"
                      value={editEmployee.tipo_contrato || ""}
                      onChange={handleInputChange}
                      defaultValue={editEmployee.tipo_contrato || ""}
                    >
                      <option value="Obra o Labor">Obra o Labor</option>
                      <option value="Indefinido">Indefinido</option>
                      <option value="Prestación de servicios">
                        Prestación de servicios
                      </option>
                      <option value="Termino fijo">Termino fijo</option>
                      <option value="Freelance">Freelance</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label>Area</Form.Label>
                  <Form.Select
                    name="area"
                    // value={editEmployee.area ? editEmployee.area.id : 0}
                    value={editEmployee.area ? editEmployee.area : 0}
                    onChange={handleInputChange}
                  >
                    {areas.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.attributes.name}
                      </option>
                    ))}
                    <option value="0" key="0">
                      N/A
                    </option>
                  </Form.Select>
                </Col>

                <Col md={6}>
                  <Form.Label>Campaña</Form.Label>
                  <Form.Select
                    name="campaing"
                    // value={editEmployee.campaing ? editEmployee.campaing.id : 0}
                    value={editEmployee.campaing ? editEmployee.campaing : 0}
                    onChange={handleInputChange}
                  >
                    {campaings.map((campaing) => (
                      <option key={campaing.id} value={campaing.id}>
                        {campaing.attributes.name}
                      </option>
                    ))}
                    <option value="0" key="0">
                      N/A
                    </option>
                  </Form.Select>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formSalario">
                    <Form.Label>Salario</Form.Label>
                    <Form.Control
                      type="text"
                      name="salario"
                      value={editEmployee.salario}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formEstado">
                    <Form.Label>Estado de Contrato</Form.Label>

                    <Form.Select
                      name="estado_contrato"
                      value={editEmployee.estado_contrato || ""}
                      onChange={handleInputChange}
                    >
                      <option value="Firmado">Firmado</option>
                      <option value="Pendiente de Firma">
                        Pendiente de Firma
                      </option>
                      <option value="Pendiente Envio">Pendiente Envio</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaInicio">
                    <Form.Label>Fecha de Inicio</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_inicio"
                      value={editEmployee.fecha_inicio}
                      // disabled={editEmployee.fecha_inicio !== null}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaFin">
                    <Form.Label>Fecha de Fin</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_fin"
                      value={editEmployee.fecha_fin || ""}
                      // disabled={editEmployee.fecha_fin !== null}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formModalidad">
                    <Form.Label>Modalidad de trabajo</Form.Label>

                    <Form.Select
                      name="modalidad_trabajo"
                      value={editEmployee.modalidad_trabajo || ""}
                      onChange={handleInputChange}
                    >
                      <option value="Presencial">Presencial</option>
                      <option value="Hibrido">Hibrido</option>
                      <option value="Virtual">Virtual</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formLiquidacion">
                    <Form.Label>Estado Liquidación</Form.Label>

                    <Form.Select
                      name="estadio_liquidacion"
                      value={editEmployee.estado_liquidacion || ""}
                      onChange={handleInputChange}
                    >
                      <option value="1">Pagada</option>
                      <option value="0">Pediente por Pagar</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-1">
                  <Form.Group controlId="formCargo">
                    <Form.Label>Correo Corporativo</Form.Label>
                    <Form.Control
                      type="email"
                      name="correo_corporativo"
                      value={editEmployee.correo_corporativo}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </section>

            <section>
              <h5>Otros si</h5>
              <Row>
                <Col md={12} className="mt-1">
                  <Form.Group controlId="formSeguridadSocial">
                    <Form.Label>¿Tiene otro si?</Form.Label>
                    <Form.Select
                      name="tiene_otro_si"
                      value={
                        editEmployee.tiene_otro_si !== null &&
                        editEmployee.tiene_otro_si !== undefined
                          ? editEmployee.tiene_otro_si.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Si</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaAfiliacionEps">
                    <Form.Label>Fecha de inicio 1</Form.Label>
                    <Form.Control
                      type="date"
                      name="otro_si_fecha_inicio1"
                      value={editEmployee.otro_si_fecha_inicio1}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaAfiliacionEps">
                    <Form.Label>Fecha de fin 1</Form.Label>
                    <Form.Control
                      type="date"
                      name="otro_si_fecha_fin1"
                      value={editEmployee.otro_si_fecha_fin1}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaAfiliacionEps">
                    <Form.Label>Fecha de fin 2</Form.Label>
                    <Form.Control
                      type="date"
                      name="otro_si2_fecha_inicio2"
                      value={editEmployee.otro_si2_fecha_inicio2}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formFechaAfiliacionEps">
                    <Form.Label>Fecha de inicio 2</Form.Label>
                    <Form.Control
                      type="date"
                      name="otro_si2_fecha_fin2"
                      value={editEmployee.otro_si2_fecha_fin2}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </section>

            <section className="mt-2">
              <h5>Fechas</h5>
              <Row>
                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechaAfiliacionEps">
                    <Form.Label>Fecha de Afiliación EPS</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_afiliacion_eps"
                      value={editEmployee.fecha_afiliacion_eps}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechadesAfiliacionEps">
                    <Form.Label>Fecha de desafiliación EPS</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_desafiliacion_eps"
                      value={editEmployee.fecha_desafiliacion_eps}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-1">
                  <Form.Group controlId="formEps">
                    <Form.Label>EPS</Form.Label>
                    <Form.Control
                      type="text"
                      name="eps"
                      value={editEmployee.eps}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechaAfiliacionArl">
                    <Form.Label>Fecha de Afiliación ARL</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_afiliacion_arl"
                      value={editEmployee.fecha_afiliacion_arl}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechadesAfiliacionArl">
                    <Form.Label>Fecha de desafiliación ARL</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_desafiliacion_arl"
                      value={editEmployee.fecha_desafiliacion_arl}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-1">
                  <Form.Group controlId="formArl">
                    <Form.Label>ARL</Form.Label>
                    <Form.Control
                      type="text"
                      name="arl"
                      value={editEmployee.arl}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechaAfiliacionCesantias">
                    <Form.Label>Fecha de Afiliación Cesantías</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_afiliacion_cesantias"
                      value={editEmployee.fecha_afiliacion_cesantias}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechadesafiliacionCesantias">
                    <Form.Label>Fecha de desafiliación Cesantías</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_desafiliacion_cesantias"
                      value={editEmployee.fecha_desafiliacion_cesantias}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-1">
                  <Form.Group controlId="formCesantías">
                    <Form.Label>Cesantías</Form.Label>
                    <Form.Control
                      type="text"
                      name="cesantias"
                      value={editEmployee.cesantias}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechaAfiliacionAFP">
                    <Form.Label>Fecha de Afiliación AFP</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_afiliacion_afp"
                      value={editEmployee.fecha_afiliacion_afp}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechadesafiliacionAFP">
                    <Form.Label>Fecha de desafiliación AFP</Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_desafiliacion_afp"
                      value={editEmployee.fecha_desafiliacion_afp}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-1">
                  <Form.Group controlId="formAfp">
                    <Form.Label>AFP</Form.Label>
                    <Form.Control
                      type="text"
                      name="afp"
                      value={editEmployee.afp}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechaCajaCompensacion">
                    <Form.Label>
                      Fecha de Afiliación Caja de Compensación
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_afiliacion_caja_compensacion"
                      value={editEmployee.fecha_afiliacion_caja_compensacion}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-1">
                  <Form.Group controlId="formFechadesafiliacioncaja_compensacion">
                    <Form.Label>
                      Fecha de desafiliación Caja de Compensación
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="fecha_desafiliacion_caja_compensacion"
                      value={editEmployee.fecha_desafiliacion_caja_compensacion}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-1">
                  <Form.Group controlId="formAfp">
                    <Form.Label>Caja de Compensación</Form.Label>
                    <Form.Control
                      type="text"
                      name="caja_compensacion"
                      value={editEmployee.caja_compensacion}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </section>

            <section className="mt-2">
              <h5>Documentación</h5>
              <Row>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formCV">
                    <Form.Label>CV</Form.Label>
                    <Form.Select
                      name="cv"
                      value={
                        editEmployee.cv !== null &&
                        editEmployee.cv !== undefined
                          ? editEmployee.cv.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formIdentificacion">
                    <Form.Label>Identificación</Form.Label>
                    <Form.Select
                      name="identificacion"
                      value={
                        editEmployee.identificacion !== null &&
                        editEmployee.identificacion !== undefined
                          ? editEmployee.identificacion.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formRUT">
                    <Form.Label>RUT</Form.Label>
                    <Form.Select
                      name="RUT"
                      value={
                        editEmployee.RUT !== null &&
                        editEmployee.RUT !== undefined
                          ? editEmployee.RUT.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>{" "}
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formSeguridadSocial">
                    <Form.Label>Seguridad Social</Form.Label>
                    <Form.Select
                      name="seguridad_social"
                      value={
                        editEmployee.seguridad_social !== null &&
                        editEmployee.seguridad_social !== undefined
                          ? editEmployee.seguridad_social.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formCerficadoLaboral">
                    <Form.Label>Cerficado Laboral</Form.Label>
                    <Form.Select
                      name="certficado_laboral"
                      value={
                        editEmployee.certficado_laboral !== null &&
                        editEmployee.certficado_laboral !== undefined
                          ? editEmployee.certficado_laboral.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>{" "}
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-1">
                  <Form.Group controlId="formCerficadoBancario">
                    <Form.Label>Cerficado Laboral</Form.Label>
                    <Form.Select
                      name="certificado_bancario"
                      value={
                        editEmployee.certificado_bancario !== null &&
                        editEmployee.certificado_bancario !== undefined
                          ? editEmployee.certificado_bancario.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>{" "}
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formReferencias">
                    <Form.Label>Referencias</Form.Label>
                    <Form.Select
                      name="referencias"
                      value={
                        editEmployee.referencias !== null &&
                        editEmployee.referencias !== undefined
                          ? editEmployee.referencias.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>{" "}
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formEstudios">
                    <Form.Label>Estudios</Form.Label>
                    <Form.Select
                      name="estudios"
                      value={
                        editEmployee.estudios !== null &&
                        editEmployee.estudios !== undefined
                          ? editEmployee.estudios.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>{" "}
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formRegistro">
                    <Form.Label>Antecedentes</Form.Label>
                    <Form.Select
                      name="antecedentes"
                      value={
                        editEmployee.antecedentes !== null &&
                        editEmployee.antecedentes !== undefined
                          ? editEmployee.antecedentes.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Completo</option>
                      <option value="0">Incompleto</option>
                    </Form.Select>{" "}
                  </Form.Group>
                </Col>

                <Col md={6} className="mt-1">
                  <Form.Group controlId="formpazSalvo">
                    <Form.Label>Paz y Salvo</Form.Label>
                    <Form.Select
                      name="paz_salvo_rrhh"
                      value={
                        editEmployee.paz_salvo_rrhh !== null &&
                        editEmployee.paz_salvo_rrhh !== undefined
                          ? editEmployee.paz_salvo_rrhh.toString()
                          : "1"
                      }
                      onChange={handleInputChange}
                    >
                      <option value="1">Si</option>
                      <option value="0">No</option>
                    </Form.Select>{" "}
                  </Form.Group>
                </Col>

                <Col md={12} className="mt-1">
                  <Form.Group controlId="formObservaciones">
                    <Form.Label>observaciones</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="observaciones"
                      value={editEmployee.observaciones}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </section>

            <Modal.Footer className="mt-2">
              <Button variant="secondary" onClick={onClose}>
                Cancelar
              </Button>
              <Button className="bg-blue" type="submit" disabled={isLoading}>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalEdit;
