import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import robot from "../../Assets/robot-forms.png";
import FormRegister from "../../Components/FormRegister";
import FormDemographic from "../../Components/FormDemographic"; 
import { IoLocationSharp } from "react-icons/io5";

/**
 * Renders the Register component with personal information form.
 *
 * @return {JSX.Element} The Forms component JSX
 */
const Forms = ({ formType }) => {
  let formComponent;
  let title;
  let description;
  let icon;

  switch (formType) {
    case "register":
      formComponent = <FormRegister />;
      title = "Información personal";
      description =
        "Eres parte fundamental de nuestra empresa por eso, para nosotros es importante tener actualizada tu información. De esta manera podremos tener un contacto más cercano contigo, por favor ingresa tus datos:";
      break;
    case "start":
      formComponent = <FormDemographic />;
      title = "Encuesta sociodemográfica";
      description = "Ingresa tu número de identificación para iniciar :";
      icon = <IoLocationSharp size={150} color="#0096C8" />;
      break;

    default:
      formComponent = null;
      title = "";
      description = "";
  }

  return (
    <Container className="my-3">
      <Row className="align-items-center">
        <Col md={6} xs={12}>
          <Image src={robot} fluid  />
        </Col>

        <Col md={6} xs={12}>
          <div className="d-flex align-items-center mb-3">
            <h1 className="text-blue fw-bold w-75 display-5">{title}</h1>
            {icon && <div className="ms-3" >{icon}</div>}
          </div>

          <p className="text-grey text-justify text-left w-75">{description}</p>

          {formComponent}
        </Col>
      </Row>
    </Container>
  );
};

export default Forms;
