import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Loading from "../Loading";
import { useResetPassword } from "./useResetPassword";

/**
 * Renders a modal for resetting the password.
 *
 * @param {boolean} show - Flag to show the modal
 * @param {function} handleClose - Function to handle modal close
 * @return {JSX.Element} A modal for resetting the password
 */
const ResetPassword = ({ show, handleClose }) => {
  const { isValidEmail, isLoading, handleSubmit, email, handleEmailChange } =
    useResetPassword({ handleClose });

  return (
    <Modal
      show={show}
      centered
      onHide={handleClose}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Recuperar contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Correo Electrónico</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingresa tu correo electrónico"
              value={email}
              onChange={handleEmailChange}
              isInvalid={!isValidEmail}
            />
            <Form.Control.Feedback type="invalid">
              Correo electrónico no válido
            </Form.Control.Feedback>
          </Form.Group>

          <Loading isLoading={isLoading} />
          <Button
            type="submit"
            className="btn bg-blue text-white w-100 mt-3 mx-auto"
          >
            Enviar correo de recuperación
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export { ResetPassword };
