 
import apiService from "./ApiService";


/**
 * Function to refresh the access token using the refresh token.
 * @returns {Promise<Object>} A Promise that resolves to an object indicating the success status and message.
 * @throws {Error} If an error occurs during the token refresh process, redirects to the login page and removes the token from local storage.
 */
const refreshAccessToken = async () => {
  try {
    let token = localStorage.getItem("token");
   
    if (token) {
      const response = await apiService.get("refresh-token", {
        headers: { Authorization: `Bearer ${token}` },
      });


      localStorage.setItem("token", response.data.token);
      apiService.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.token}`;
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    window.location.href = "/";
    localStorage.removeItem("token");
  }
};

/**
 * An asynchronous function that logs out the user by sending a POST request to the "logout" endpoint. It removes the token, name, and role from the local storage upon successful logout and returns a boolean value based on the response status.
 *
 * @return {boolean} Indicates the success or failure of the logout operation.
 */
const logout = async () => {
  try {
    const response = await apiService.post("logout", {});
    if (response && response.status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};


/**
 * An asynchronous function that logs in a user by sending a POST request with login credentials. It sets the user's token, name, and role in local storage upon successful login and returns an object with user information.
 *
 * @param {string} email - The user's email address.
 * @param {string} password - The user's password.
 * @param {string} deviceName - The name of the device used for login.
 * @return {Object} An object containing login success status, change password URL, and user information.
 */
const login = async (email, password, deviceName) => {
  try {
    const response = await apiService.post("login", {
      email,
      password,
      device_name: deviceName,
    });
    if (response && response.status === 200) {
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("name", response.data.data.attributes.name);
      localStorage.setItem("role", response.data.data.attributes.role);
      return {
        success: true,
        change_password_url: response.data.change_password_url,
        user: { name: response.data.data.attributes.name },
      };
    } else {
      return { success: false, message: "Credenciales incorrectas" };
    }
  } catch (e) {
    return { success: false, message: e.response.data.message };
  }
};


/**
 * An asynchronous function that changes the user's password by sending a POST request with the new password and token. It returns an object indicating the success status and a message if the password change was unsuccessful.
 *
 * @param {string} token - The token associated with the password change request.
 * @param {string} newPassword - The new password that the user wants to set.
 * @param {string} passwordConfirmation - The confirmation of the new password.
 * @param {string} email - The user's email address.
 * @return {Object} An object containing the success status and a message regarding the password change.
 */
const changePassword = async (
  token,
  newPassword,
  passwordConfirmation,
  email
) => {
  try {
    const response = await apiService.post(`/forgot-password/${token}`, {
      token: token,
      password: newPassword,
      password_confirmation: passwordConfirmation,
      email: email,
    });
    if (response && !response.data.email) {
      return { success: true };
    } else {
      return {
        success: false,
        message:
          "No se pudo cambiar la contraseña, solicita el correo de recuperación",
      };
    }
  } catch (e) {
    return {
      success: false,
      message:
        "No se pudo cambiar la contraseña, solicita el correo de recuperación",
    };
  }
};
/**
 * An asynchronous function that sends a POST request to reset the user's password using the provided email address. 
 *
 * @param {string} email - The user's email address for password reset.
 * @return {Object} An object indicating the success status of the password reset request.
 */
const forgotPassword = async (email) => {
  try {
    const response = await apiService.post("forgot-password", { email });

    if (response && response.status === 200) {
      return { success: true };
    } else {
      return {
        success: false,
        message: "Error en la solicitud de recuperación de contraseña",
      };
    }
  } catch (e) {
    return {
      success: false,
    };
  }
};

/**
 * An asynchronous function that updates the user's password by sending a POST request with the new password. It returns an object indicating the success status and a message regarding the update.
 *
 * @param {string} password - The new password that the user wants to set.
 * @return {Object} An object containing the success status and a message regarding the password update.
 */
const updatePassword = async (password) => {
  try {
    const response = await apiService.post("update-password", {
      password,
    });

    if (response && response.status === 200) {
      return { success: true, message: response.data.message };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (e) {
    return { success: false, message: e.response.data.message };
  }
};

setInterval(refreshAccessToken, 60000*10);
export {
  refreshAccessToken,
  logout,
  login,
  forgotPassword,
  changePassword,
  updatePassword,
};
