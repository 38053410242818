import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import logo from "../../Assets/logo-header.png";
import { FaGear } from "react-icons/fa6";
import { MdWorkHistory } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import User from "../User";
import ListLogs from "../ListLogs";
import "../../Pages/Home/home.css";

/**
 * Renders the Header component with navigation, user role check, and optional logs modal.
 *
 * @return {JSX.Element} The Header component JSX
 */
const Header = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");
  const [showLogsModal, setShowLogsModal] = useState(false);

  const isAdmin = userRole === "Administrador";
  /**
   * A function that handles opening the logs modal.
   */
  const handleOpenLogsModal = () => {
    setShowLogsModal(true);
  };

  return (
    <>
      <Navbar variant="dark" className="bg-blue px-5 navbar-thin py-5">
        <Navbar.Brand href="/home" className="ms-5">
          <img src={logo} alt="Logo CCG" className="w-20" />
        </Navbar.Brand>

        <Navbar.Collapse className="">
          {isAdmin && (
            <>
              <FaGear
                size={25}
                className="icon"
                style={{ color: "white" }}
                onClick={() => navigate("/config")}
              />
              <MdWorkHistory
                size={25}
                className="icon ms-4"
                style={{ color: "white" }}
                onClick={handleOpenLogsModal}
              />
            </>
          )}

          <User color="white" />
        </Navbar.Collapse>
      </Navbar>

      {showLogsModal && <ListLogs closeModal={() => setShowLogsModal(false)} />}
    </>
  );
};

export default Header;
