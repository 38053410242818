import { useState } from "react";
import { changePassword } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { alerts } from "../../utils/Notifications";


/**
 * Generates various functions related to changing password, handling form submission, and displaying alerts.
 *
 * @param {Object} token - The token for authentication
 * @param {string} email - The email of the user
 * @param {Function} handleClose - Function to close a modal or dialog
 * @return {Object} An object containing functions and state variables related to changing password
 */
const useChangePassword = ({ token, email, handleClose }) => {
  const [newPassword, setNewPassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  /**
   * Handles the form submission asynchronously, performs password validation,
   * makes an API call to change the password, and displays appropriate alerts.
   *
   * @param {Object} e - The event object
   * @return {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== password_confirmation) {
      alerts("error", "Las contraseñas no coinciden", 3000);
      return;
    }

    setIsLoading(true);

    try {
      const response = await changePassword(
        token,
        newPassword,
        password_confirmation,
        email
      );
      if (response.success) {
        alerts(
          "success",
          "La contraseña se cambió exitosamente",
          2000
        );
      } else {
        alerts("error", response.message, 3000);
      }
    } catch (e) {
      alerts("error", "Error al procesas la solicitud", 3000);
    } finally {
      setIsLoading(false);
      handleClose();
      navigate("/");
    }
  };
  /**
   * Handles the form submission asynchronously, performs password validation,
   * makes an API call to change the password, and displays appropriate alerts.
   *
   * @param {Object} e - The event object
   * @return {void}
   */

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  /**
   * Handles the change event for the password confirmation field.
   *
   * @param {object} e - the event object
   * @return {void}
   */
  const handlepasswordconfirmationChange = (e) => {
    setpassword_confirmation(e.target.value);
  };

  return {
    newPassword,
    password_confirmation,
    isLoading,
    handleSubmit,
    alerts,
    setIsLoading,
    handlePasswordChange,
    handlepasswordconfirmationChange,
    handleClose,
  };
};

export { useChangePassword };
