 
import apiService from "./ApiService";

/**
 * Asynchronously retrieves active areas using a GET request and handles different response scenarios.
 *
 * @return {object} An object with properties success and data, or success and message indicating the success of the retrieval operation.
 */
const getActiveAreas = async () => {
  try {
    const response = await apiService.get("/area/active-areas");
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Asynchronously creates an area using a POST request and handles different response scenarios.
 *
 * @param {object} campaing - The campaing object containing the details of the area to be created.
 * @return {object} An object with properties success and message indicating the success of the creation operation.
 */
const createArea = async (campaing) => {
  try {
    const response = await apiService.post("/areas", campaing);

    if (response && response.status === 201) {
      return { success: true, message: response.data.message };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false, message: e.response.data.message };
  }
};

/**
 * Asynchronously retrieves all areas using a GET request and handles different response scenarios.
 *
 * @return {object} An object with properties success and data, or success and message indicating the success of the retrieval operation.
 */
const getAreas = async () => {
  try {
    const response = await apiService.get("/areas");
    if (response && response.status === 200) {
      return { success: true, data: response.data.data };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return { success: false };
  }
};

/**
 * Asynchronously deletes an area using a DELETE request and handles different response scenarios.
 *
 * @param {string} area - The ID of the area to delete.
 * @return {object} An object with a property `success` indicating the success of the deletion operation, 
 * and an optional property `message` with an error message in case of failure.
 */
const deleteArea = async (area) => {
  try {
    const response = await apiService.delete(`areas/${area}`);
    if (response && response.status === 200) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }
    return {
      success: false,
    };
  }
};

/**
 * Asynchronously updates an area using a PUT request and handles different response scenarios.
 *
 * @param {object} areas - The areas object containing the ID and attributes of the area to update.
 * @return {object} An object with properties success and message indicating the success of the update operation.
 */
const updateArea = async (areas) => {
  try {
    const response = await apiService.put(`areas/${areas.id}`, {
      _method: "PUT",
      ...areas.attributes,
    });
    if (response && response.status === 200) {
      return {
        success: true,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        message: response.data.message,
      };
    }
  } catch (e) {
    if (e.response.status === 403) {
      return { success: false, message: "Error de permisos" };
    }

    return {
      success: false,
      message: e.response.data.message,
    };
  }
};

export { getAreas, getActiveAreas, updateArea, createArea, deleteArea };
