import { useState, useEffect } from "react";
import { getUsers, deleteUser, updateUser } from "../../services/UserService";
import { getAreas, deleteArea, updateArea } from "../../services/AreaService";
import {
  getCampaings,
  deleteCampaing,
  updateCampaing,
} from "../../services/CampaingService";
import { forgotPassword } from "../../services/AuthService";
import { alerts } from "../../utils/Notifications";

/**
 * Generates a custom hook for handling modal configuration deletion.
 *
 * @param {object} props - The properties object containing show, onHide, and type.
 * @return {object} An object with various functions and states for handling modal configuration deletion.
 */
const useModalConfigDelete = (props) => {
  const { show, onHide, type } = props;
  const [usersActive, setUsersActive] = useState([]);
  const [areas, setAreas] = useState([]);
  const [campaings, setCampaings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [editEntityData, seteditEntityData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});

  /**
   * A function that handles input change events and updates the editEntityData state.
   *
   * @param {Event} event - The input change event object
   * @param {string} key - The key to update in the editEntityData state
   * @return {void}
   */
  const handleInputChange = (event, key) => {
    const { value } = event.target;
    let newValue = value;

    if (event.target.tagName === "SELECT") {
      const { selectedIndex } = event.target;
      const selectedOption = event.target[selectedIndex];
      const optionValue = value;
      const optionText = selectedOption.innerText;

      newValue = { value: optionValue, text: optionText };
    }

    seteditEntityData((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };
  /**
   * A function that retrieves a list of users asynchronously and updates the state accordingly.
   *
   * @return {Promise<void>}
   */
  const getUsersList = async () => {
    try {
      setIsLoading(true);
      const response = await getUsers();
      if (response.success) {
        setUsersActive(response.data);
      }
    } catch (e) {
      setUsersActive(null);
    } finally {
      setEditingUser(null);
      seteditEntityData({});
      setIsLoading(false);
    }
  };
  /**
   * Asynchronously retrieves the areas list, sets loading state, and handles response and errors.
   *
   * @return {void}
   */
  const getAreasList = async () => {
    setIsLoading(true);
    try {
      const response = await getAreas();
      if (response.success) {
        setAreas(response.data);
      }
    } catch (e) {
      setAreas(null);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * A function that fetches campaign data and updates the state accordingly.
   */
  const getCampaingList = async () => {
    setIsLoading(true);
    try {
      const response = await getCampaings();
      if (response.success) {
        setCampaings(response.data);
      }
    } catch (e) {
      setCampaings(null);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * A function that handles the deletion process based on the type of entity.
   *
   * @param {type} id - The unique identifier of the entity to be deleted
   * @return {type} void
   */
  const handleDeleteClick = async (id) => {
    try {
      setIsLoading(true);
      let response;
      if (type === "users") {
        response = await deleteUser(id);
      } else if (type === "area") {
        response = await deleteArea(id);
      } else {
        response = await deleteCampaing(id);
      }

      if (response.success) {
        alerts("success", "Se han aplicado los cambios", 2000);
      }
    } catch (e) {
      alerts("error", "Se ha presentado un error, vuelva a intentarlo", 2000);
    } finally {
      setIsLoading(false);
      updateList();
    }
  };

  /**
   * A function that updates the list based on the type of entity.
   *
   * @return {void} No return value
   */
  const updateList = () => {
    if (type === "users") {
      getUsersList();
    } else if (type === "area") {
      getAreasList();
    } else {
      getCampaingList();
    }
  };

  /**
   * A function that handles the recovery button click event. Initiates the password recovery process
   * by sending an email to the provided email address. Displays success or error messages via sweet alerts.
   *
   * @param {string} email - The email address for password recovery
   * @return {void}
   */
  const handleRecoverClick = async (email) => {
    setIsLoading(true);
    try {
      const response = await forgotPassword(email);

      if (response.success) {
        alerts("success", "Se ha enviado un correo electronico", 2000);
      } else {
        alerts("error", "Se ha presentado un error, vuelva a intentarlo", 2000);
      }
    } catch (error) {
      alerts("error", "Se ha presentado un error, vuelva a intentarlo", 2000);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * An asynchronous function that handles the edit button click event.
   *
   * @param {type} user - The user data to be edited
   * @return {type} No return value
   */
  const handleEditClick = async (user) => {
    setEditingUser((prevUserId) => (prevUserId === user ? null : user));
  };

  const handleSaveClick = (entity) => {
    const { id, attributes } = entity;
    const { name: originalName, role: originalRole } = attributes;
    let { name: editedName, role: editedRole } = editEntityData;

    if (type === "users") {
      editedName = editedName !== undefined ? editedName : originalName;
      editedRole = editedRole !== undefined ? editedRole : originalRole;

      if (editedName.trim() === "") {
        setErrors({ name: "El nombre no puede estar vacío" });
        return;
      }

      if (editedRole.value.trim() === "") {
        setErrors({ role: "El rol no puede estar vacío" });
        return;
      }
    } else {
      editedName = editedName !== undefined ? editedName : originalName;

      if (editedName.trim() === "") {
        setErrors({ name: "El nombre no puede estar vacío" });
        return;
      }
    }
    if (type === "users") {
      handleUpdateEntity({
        id,
        attributes: { name: editedName, role: editedRole.value },
      });
    } else {
      handleUpdateEntity({ id, attributes: { name: editedName } });
    }
  };

  /**
   * Handles the save click event based on the entity type. Updates entity data and triggers an update action.
   *
   * @param {object} entity - The entity object containing id and attributes
   * @return {void} No return value
   */
  const handleUpdateEntity = async (entity) => {
    try {
      setIsLoading(true);
      let response;

      switch (type) {
        case "users":
          response = await updateUser(entity);
          break;
        case "campaign":
          response = await updateCampaing(entity);
          break;
        default:
          response = await updateArea(entity);
      }

      if (response.success) {
        alerts("success", response.message, 2000);
      } else {
        alerts("error", response.message, 2000);
      }
    } catch (e) {
      alerts("error", e.data.response.message, 2000);
    } finally {
      setIsLoading(false);
      updateList();
      setErrors({});
      setEditingUser(null);
      seteditEntityData({});
    }
  };

  useEffect(() => {
    if (show) {
      if (type === "users") {
        getUsersList();
      } else if (type === "area") {
        getAreasList();
      } else {
        getCampaingList();
      }
    }
  }, [show, type]);

  useEffect(() => {
    return () => {
      setEditingUser(null);
      setErrors({});
      seteditEntityData({});
      setIsEditing(false);
    };
  }, [onHide]);
  return {
    usersActive,
    handleDeleteClick,
    isLoading,
    handleEditClick,
    editingUser,
    handleInputChange,
    handleSaveClick,
    areas,
    campaings,
    isEditing,
    errors,
    handleRecoverClick,
  };
};

export { useModalConfigDelete };
