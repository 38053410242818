import { useState, useEffect } from "react";
import { updateEmployeeSupportData } from "../../services/EmployeeService";
import { alerts } from "../../utils/Notifications";

/**
 * Custom hook for managing small edit modal state and actions.
 *
 * @param {Object} employee - The employee object containing attributes
 * @param {Function} onHide - The function to handle hiding the modal
 * @param {Function} onUpdate - The function to handle updating employee data
 * @return {Object} An object containing state variables and functions for the modal
 */
const useModalSupport = ({ employee, onHide, onUpdate }) => {
  const [editEmployee, setEditEmployee] = useState(employee);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEditEmployee(employee);
  }, [employee]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditEmployee((prevEmployee) => ({
      ...prevEmployee,
      attributes: {
        ...prevEmployee.attributes,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await updateEmployeeSupportData(editEmployee.id, editEmployee.attributes);
      if (response.success) {
        alerts("success", "Sus cambios fueron guardados", 2000);
        onHide();
        onUpdate();
      }
    } catch (error) {
      alerts("error", "Hubo un error al guardar los cambios", 2000);
    }
    setIsLoading(false);
  };

  return {
    editEmployee,
    handleInputChange,
    handleSubmit,
    isLoading,
  };
};

export { useModalSupport };
