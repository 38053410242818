
import { Navigate, Outlet } from "react-router-dom";

/**
 *  The `ProtectedRoute` function checks if a user can access a route based on their role and redirects
 * them if they do not have the required role.
 *
 * @param {boolean} canActivate - Flag indicating if the user can access the route.
 * @param {string} redirectPath - The path to redirect if user does not have the required role.
 * @param {array} roles - Array of roles that are allowed to access the route.
 * @return {JSX.Element} Either a `<Navigate>` component with the `to` prop set to `redirectPath` and `replace`
 * prop, or an `<Outlet>` component.
 */
const ProtectedRoute = ({ canActivate, redirectPath = "/", roles = [] }) => {
  const role = localStorage.getItem("role");

  if (!canActivate || !roles.includes(role)) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
