import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { login } from "../../services/AuthService";
import {
  alerts,
  alertsWithLoading,
  closeAlert,
} from "../../utils/Notifications";
import "./login.css";

/**
 * The `useLogin` function in JavaScript handles user login functionality, including password
 * visibility toggling, displaying toast notifications, managing modals, and processing login
 * credentials.
 *
 * @returns An object containing properties and functions related to user login functionality.
 */
const useLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [emailParam, setEmailParam] = useState("");
  const [email, setEmail] = useState("");
  const [showIcon, setShowIcon] = useState(true);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showResetChangeModal, setShowResetChangeModal] = useState(false);
  const location = useLocation();
  const deviceName = "Navegador Web";

  /**
   * A function that toggles the visibility of a password input field.
   *
   * @returns {void}
   */
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * A function that handles opening the reset modal.
   */
  const handleOpenResetModal = () => {
    setShowResetModal(true);
  };

  /**
   * A function that handles closing the reset modal.
   *
   * @return {void} No return value.
   */
  const handleCloseResetModal = () => {
    setShowResetModal(false);
  };

  /**
   * Function that handles closing the reset change modal.
   *
   * @return {void} No return value.
   */
  const handleCloseResetChangeModal = () => {
    setShowResetChangeModal(false);
  };

  /**
   * A function that handles the login process, including displaying loading alerts, processing the login credentials, and redirecting the user based on the response.
   *
   * @returns {Promise<void>}
   */
  const handleLogin = async () => {
    try {
      alertsWithLoading("Validando credenciales");
      const response = await login(email, password, deviceName);
  
      if (response.success) {
        closeAlert();
  
        alerts("success", `¡Bienvenido, ${response.user.name}!`, 2000);
        setTimeout(() => {
        
          if (response.change_password_url) {
            window.location.href = "/home?updatePassword=true";
          } else {
            window.location.href = "/home";
          }
        }, 1000); 
  
      } else {
        closeAlert();
        alerts("error", response.message, 2000);
      }
    } catch (e) {
      closeAlert();
      alerts("error", "Error al iniciar sesión", 2000);
    }
  };
  

  /**
   * Handles the key down event, preventing default action for Enter key and triggering the login process.
   *
   * @param {Event} e - The event object triggering the key down
   * @return {void} No return value.
   */
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setToken(params.get("token"));
    setEmailParam(params.get("email"));
    setShowResetChangeModal(true);
  }, [location.search]);

  return {
    showPassword,
    password,
    email,
    handleTogglePassword,
    handleLogin,
    handleKeyDown,
    setEmail,
    setPassword,
    setShowIcon,
    showIcon,
    handleOpenResetModal,
    handleCloseResetModal,
    showResetModal,
    token,
    emailParam,
    handleCloseResetChangeModal,
    showResetChangeModal,
  };
};

export { useLogin };
