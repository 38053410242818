import React from "react";
import { CSVLink } from "react-csv";
import { Container, Table, Form, Row, Col } from "react-bootstrap";
import { BsToggle2Off, BsToggle2On } from "react-icons/bs";
import { TbReload } from "react-icons/tb";
import { IoMdCloudDownload } from "react-icons/io";
import { MdMarkEmailUnread } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { FaChild } from "react-icons/fa6";

import User from "../User";
import DeleteModal from "../DeleteModal";
import ModalEdit from "../ModalEdit";
import ModalChildren from "../ModalChildren";
import Loading from "../Loading";
import { useListGeneral } from "./useListGeneral";
import Error from "../Error";
import robot from "../../Assets/mascota-confundida.png";
import "./ListGeneral.css";

/**
 * Renders the ListGernal component.
 *
 * @param {Object} id - The id of the communication
 * @param {string} type - The type of the communication
 * @param {string} name - The name of the communication
 * @return {JSX.Element} The rendered ListComunications component
 */
const ListGeneral = ({ id, type, name }) => {
  const role = localStorage.getItem("role");
  const hasPermissionToEdit = role === "Visualizador";

  const {
    selectedEmployee,
    setSelectedEmployee,
    isEditModalOpen,
    setIsEditModalOpen,
    setIsChildrenModalOpen,
    isChildrenModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isLoading,
    requestSort,
    handleUpdate,
    filterValue,
    filteredEmployees,
    handleFilterChange,
    csvData,
    headersCsv,
    error,
    calculateAge,
    formatDate,
    todayPlusOneMonth,
  } = useListGeneral({
    id,
    type,
    name,
  });

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (error) {
    return <Error text={error} robot={robot} />;
  }

  return (
    <>
      <Container fluid className="mx-5 my-2">
        <User color="blue" marginX={4} position={true} marginY={2} />

        <h2 className="text-center text-blue">Personal - {name}</h2>

        <section>
          <Row className="justify-content-between mb-1">
            <Col md={4} className="d-flex flex-column">
              <span className="fw-bold text-blue">
                Módulo: Recursos Humanos
              </span>
              <span className="text-blue mb-0">
                Registros: {filteredEmployees.length}
              </span>
            </Col>

            <Col md={8} className="d-flex justify-content-end">
              <Form>
                <Form.Group controlId="filter">
                  <Form.Control
                    style={{
                      width: "400px",
                    }}
                    type="text"
                    placeholder="Filtrar..."
                    value={filterValue}
                    onChange={handleFilterChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </Form>
              <CSVLink
                data={csvData}
                headers={headersCsv}
                filename={"datos_empleados.csv"}
              >
                <IoMdCloudDownload
                  color="#0096C8"
                  size={30}
                  className="mx-1"
                  title="Descargar la información"
                />
              </CSVLink>
              <TbReload
                title="Actualizar la información"
                role="button"
                color="#0096C8"
                size={25}
                className="mx-1"
                onClick={handleUpdate}
              />
            </Col>
          </Row>
        </section>

        <div className="table-container">
          <Table striped bordered hover size="md" className="table-employee">
            <thead>
              <tr className="text-center">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th onClick={() => requestSort("estado")}>Estado</th>
                <th onClick={() => requestSort("id")}>ID</th>
                <th onClick={() => requestSort("nombre")}>Nombre</th>
                <th onClick={() => requestSort("campaing")}>Campaña</th>
                <th onClick={() => requestSort("area")}>Área</th>
                <th onClick={() => requestSort("cargo")}>Cargo</th>
                <th onClick={() => requestSort("tipo_identificacion")}>
                  Tipo Identificación
                </th>
                <th onClick={() => requestSort("numero_idetificacion")}>
                  N° Identificacion
                </th>
                <th onClick={() => requestSort("banco")}>Banco</th>
                <th onClick={() => requestSort("numero_cuenta")}>
                  {" "}
                  N° de cuenta
                </th>
                <th onClick={() => requestSort("correo_electronico")}>
                  Correo Electronico
                </th>
                <th onClick={() => requestSort("correo_corporativo")}>
                  Correo Corporativo
                </th>
                <th onClick={() => requestSort("fecha_nacimiento")}>
                  Fecha Nacimiento
                </th>
                <th>Edad</th>
                <th onClick={() => requestSort("lugar_nacimiento")}>
                  Lugar Nacimiento
                </th>
                <th onClick={() => requestSort("fecha_expedicion")}>
                  Fecha Expedición
                </th>
                <th onClick={() => requestSort("lugar_expedicion")}>
                  Lugar Expedición
                </th>
                <th onClick={() => requestSort("direccion")}>Direccion</th>
                <th onClick={() => requestSort("ciudad")}>Ciudad</th>
                <th onClick={() => requestSort("genero")}>Genero</th>
                <th onClick={() => requestSort("nivel_educativo")}>
                  Nivel Educativo
                </th>
                <th onClick={() => requestSort("profesion")}>Profesión</th>
                <th onClick={() => requestSort("telefono")}>Celular</th>
                <th onClick={() => requestSort("estado_contrato")}>
                  Estado Contrato
                </th>
                <th onClick={() => requestSort("estado_liquidacion")}>
                  Estado de la liquidación
                </th>
                <th onClick={() => requestSort("modalidad_trabajo")}>
                  Modalidad de trabajo
                </th>

                <th onClick={() => requestSort("tipo_contrato")}>
                  Tipo Contrato
                </th>
                <th onClick={() => requestSort("salario")}>Salario</th>
                <th onClick={() => requestSort("fecha_inicio")}>
                  Fecha Inicio
                </th>

                <th onClick={() => requestSort("fecha_fin")}>
                  Fecha Finalizacion
                </th>

                <th onClick={() => requestSort("tiene_otro_si")}>
                  ¿Tiene otro si?
                </th>

                <th onClick={() => requestSort("otro_si_fecha_inicio1")}>
                  Fecha Inicio Otro si
                </th>
                <th onClick={() => requestSort("otro_si_fecha_inicio1")}>
                  Fecha Finalizacion Otro si
                </th>
                <th onClick={() => requestSort("otro_si2_fecha_inicio2")}>
                  Fecha Finalizacion Otro si 2
                </th>
                <th onClick={() => requestSort("otro_si2_fecha_inicio2")}>
                  Fecha Finalizacion Otro si 2
                </th>
                <th onClick={() => requestSort("fecha_afiliacion_eps")}>
                  Fecha afilifacion EPS
                </th>
                <th onClick={() => requestSort("fecha_desafiliacion_eps")}>
                  Fecha Desafiliacion EPS
                </th>
                <th onClick={() => requestSort("eps")}>EPS</th>
                <th onClick={() => requestSort("fecha_afiliacion_arl")}>
                  Fecha Afiliacion ARL
                </th>
                <th onClick={() => requestSort("fecha_desafiliacion_arl")}>
                  Fecha Desafiliacion ARL
                </th>
                <th onClick={() => requestSort("arl")}>ARL</th>
                <th onClick={() => requestSort("fecha_afiliacion_afp")}>
                  Fecha Afiliacion AFP
                </th>
                <th onClick={() => requestSort("fecha_desafiliacion_afp")}>
                  Fecha Desafiliacion AFP
                </th>
                <th onClick={() => requestSort("afp")}>AFP</th>
                <th onClick={() => requestSort("fecha_afiliacion_cesantias")}>
                  Fecha Afiliacion Censatias
                </th>
                <th
                  onClick={() => requestSort("fecha_desafiliacion_cesantias")}
                >
                  Fecha Desafiliacion Censatias
                </th>
                <th onClick={() => requestSort("cesantias")}>Cesantias</th>
                <th
                  onClick={() =>
                    requestSort("fecha_afiliacion_caja_compensacion")
                  }
                >
                  Fecha Afiliacion Caja de Compensación
                </th>
                <th
                  onClick={() =>
                    requestSort("fecha_desafiliacion_caja_compensacion")
                  }
                >
                  Fecha Desafiliacion Caja de Compensación
                </th>
                <th onClick={() => requestSort("caja_compensacion")}>
                  Caja de Compensación
                </th>
                <th onClick={() => requestSort("cv")}>CV</th>
                <th onClick={() => requestSort("identificacion")}>
                  Identificacion
                </th>
                <th onClick={() => requestSort("RUT")}>RUT</th>
                <th onClick={() => requestSort("seguridad_social")}>
                  Seguridad Social
                </th>
                <th onClick={() => requestSort("estudios")}>Estudios</th>
                <th onClick={() => requestSort("certficado_laboral")}>
                  Certificado laboral
                </th>
                <th onClick={() => requestSort("certificado_bancario")}>
                  Certificado bancario
                </th>
                <th onClick={() => requestSort("referencias")}>Referencias</th>
                <th onClick={() => requestSort("antecedentes")}>
                  Antecedentes
                </th>

                <th onClick={() => requestSort("paz_salvo_rrhh")}>
                  Paz y Salvo RRHH
                </th>
                {/* <th onClick={() => requestSort("paz_salvo_TI")}>
                          Paz y Salvo TI
                        </th> */}

                <th onClick={() => requestSort("nombre_contacto")}>
                  Nombre de Contacto
                </th>
                <th onClick={() => requestSort("telefono_contacto")}>
                  Teléfono de Contacto
                </th>

                <th onClick={() => requestSort("observaciones")}>
                  Observaciones
                </th>
                {/* <th onClick={() => requestSort("observaciones")}>
                          Observaciones TI
                        </th> */}
              </tr>
            </thead>
            <tbody>
              {filteredEmployees.map((employee) => (
                <tr key={employee.id}>
                  <td>
                    {employee.attributes.estado === 1 &&
                      !hasPermissionToEdit && (
                        <CiEdit
                          className="icon text-blue"
                          onClick={() => {
                            setIsEditModalOpen(true);
                            setSelectedEmployee(employee);
                          }}
                        />
                      )}
                  </td>
                  <td>
                    {!hasPermissionToEdit &&
                      (employee.attributes.estado === 1 ? (
                        <BsToggle2On
                          className="icon"
                          color="#0096C8"
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                            setSelectedEmployee(employee);
                          }}
                        />
                      ) : (
                        <BsToggle2Off
                          className="icon text-red"
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                            setSelectedEmployee(employee);
                          }}
                        />
                      ))}
                  </td>

                  <td>
                    {employee.attributes.tiene_hijo ? (
                      <FaChild
                        color="0096C8"
                        className="icon"
                        onClick={() => {
                          setIsChildrenModalOpen(true);
                          setSelectedEmployee(employee);
                        }}
                      />
                    ) : null}
                  </td>

                  <td>
                    {employee.attributes.fecha_fin &&
                    new Date(employee.attributes.fecha_fin) <
                      todayPlusOneMonth ? (
                      <span title="Enviar notificación de vencimiento de contrato">
                        <MdMarkEmailUnread className="icon" color="red" />
                      </span>
                    ) : (
                      ""
                    )}
                  </td>

                  <td>
                    {employee.attributes.estado === 1 ? "Activo" : "Inactivo"}
                  </td>
                  <td>{employee.id}</td>
                  <td>{employee.attributes.nombre}</td>
                  <td>{employee.attributes.campaing.name}</td>
                  <td>{employee.attributes.area.name}</td>
                  <td>{employee.attributes.cargo}</td>
                  <td>{employee.attributes.tipo_identificacion}</td>
                  <td>{employee.attributes.numero_identificacion}</td>
                  <td>{employee.attributes.banco}</td>
                  <td>{employee.attributes.numero_cuenta}</td>
                  <td>{employee.attributes.correo_electronico}</td>
                  <td>{employee.attributes.correo_corporativo}</td>

                  <td>{formatDate(employee.attributes.fecha_nacimiento)}</td>
                  <td>{calculateAge(employee.attributes.fecha_nacimiento)}</td>
                  <td>{employee.attributes.lugar_nacimiento}</td>
                  <td>{formatDate(employee.attributes.fecha_expedicion)}</td>
                  <td>{employee.attributes.lugar_expedicion}</td>

                  <td>{employee.attributes.direccion.replace(",", "")}</td>
                  <td>{employee.attributes.ciudad}</td>
                  <td>{employee.attributes.genero}</td>
                  <td>{employee.attributes.nivel_educativo}</td>
                  <td>{employee.attributes.profesion}</td>

                  <td>{employee.attributes.telefono}</td>

                  <td>{employee.attributes.estado_contrato}</td>
                  <td>
                    {employee.attributes.estado_liquidacion === 1
                      ? "Pagada"
                      : "Pendiente por pagar"}
                  </td>
                  <td>{employee.attributes.modalidad_trabajo}</td>

                  <td>{employee.attributes.tipo_contrato}</td>
                  <td>${employee.attributes.salario}</td>
                  <td>{formatDate(employee.attributes.fecha_inicio)}</td>
                  <td>{formatDate(employee.attributes.fecha_fin) || "-"}</td>
                  <td>
                    {employee.attributes.tiene_otro_si === 1 ? "Sí" : "No"}
                  </td>
                  <td>
                    {formatDate(employee.attributes.otro_si_fecha_inicio1)}
                  </td>
                  <td>{formatDate(employee.attributes.otro_si_fecha_fin1)}</td>

                  <td>{formatDate(employee.attributes.otro_si2_fecha_inicio2)}</td>
                  <td>{formatDate(employee.attributes.otro_si2_fecha_fin2)}</td>

                  <td>{formatDate(employee.attributes.fecha_afiliacion_eps)}</td>
                  <td>{formatDate(employee.attributes.fecha_desafiliacion_eps)}</td>
                  <td>{employee.attributes.eps}</td>
                  <td>{formatDate(employee.attributes.fecha_afiliacion_arl)}</td>
                  <td>{formatDate(employee.attributes.fecha_desafiliacion_arl)}</td>
                  <td>{employee.attributes.arl}</td>
                  <td>{formatDate(employee.attributes.fecha_afiliacion_afp)}</td>
                  <td>{formatDate(employee.attributes.fecha_desafiliacion_afp)}</td>
                  <td>{employee.attributes.afp}</td>
                  <td>{formatDate(employee.attributes.fecha_afiliacion_cesantias)}</td>
                  <td>{formatDate(employee.attributes.fecha_afiliacion_cesantias)}</td>

                  <td>{employee.attributes.cesantias}</td>
                  <td>
                    {formatDate(employee.attributes.fecha_afiliacion_caja_compensacion)}
                  </td>
                  <td>
                    {formatDate(employee.attributes.fecha_desafiliacion_caja_compensacion)}
                  </td>
                  <td>{employee.attributes.caja_compensacion}</td>
                  <td
                    className={`${
                      employee.attributes.cv !== 1 ? "text-red" : ""
                    }`}
                  >
                    {employee.attributes.cv === 1 ? "Completo" : "Incompleto"}
                  </td>
                  <td
                    className={`${
                      employee.attributes.identificacion !== 1 ? "text-red" : ""
                    }`}
                  >
                    {employee.attributes.identificacion === 1
                      ? "Completo"
                      : "Incompleto"}
                  </td>
                  <td
                    className={`${
                      employee.attributes.RUT !== 1 ? "text-red" : ""
                    }`}
                  >
                    {employee.attributes.RUT === 1 ? "Completo" : "Incompleto"}
                  </td>
                  <td
                    className={`${
                      employee.attributes.seguridad_social !== 1
                        ? "text-red"
                        : ""
                    }`}
                  >
                    {employee.attributes.seguridad_social === 1
                      ? "Completo"
                      : "Incompleto"}
                  </td>
                  <td
                    className={`${
                      employee.attributes.estudios !== 1 ? "text-red" : ""
                    }`}
                  >
                    {employee.attributes.estudios === 1
                      ? "Completo"
                      : "Incompleto"}
                  </td>

                  <td
                    className={`${
                      employee.attributes.certficado_laboral !== 1
                        ? "text-red"
                        : ""
                    }`}
                  >
                    {employee.attributes.certficado_laboral === 1
                      ? "Completo"
                      : "Incompleto"}
                  </td>

                  <td
                    className={`${
                      employee.attributes.certificado_bancario !== 1
                        ? "text-red"
                        : ""
                    }`}
                  >
                    {employee.attributes.certificado_bancario === 1
                      ? "Completo"
                      : "Incompleto"}
                  </td>

                  <td
                    className={`${
                      employee.attributes.referencias !== 1 ? "text-red" : ""
                    }`}
                  >
                    {employee.attributes.referencias === 1
                      ? "Completo"
                      : "Incompleto"}
                  </td>
                  <td
                    className={`${
                      employee.attributes.antecedentes !== 1 ? "text-red" : ""
                    }`}
                  >
                    {employee.attributes.antecedentes === 1
                      ? "Completo"
                      : "Incompleto"}
                  </td>

                  <td
                    className={`${
                      employee.attributes.paz_salvo_rrhh !== 1 ? "text-red" : ""
                    }`}
                  >
                    {employee.attributes.paz_salvo_rrhh === 1 ? "Si" : "No"}
                  </td>

                  {/* <td
                            className={`${
                              employee.attributes.paz_salvo_TI !== 1
                                ? "text-red"
                                : ""
                            }`}
                          >
                            {employee.attributes.paz_salvo_TI === 1
                              ? "Si"
                              : "No"}
                          </td> */}
                  <td>{employee.attributes.nombre_contacto}</td>
                  <td>{employee.attributes.telefono_contacto}</td>
                  <td>{employee.attributes.observaciones}</td>
                  {/* <td>{employee.attributes.observaciones_TI}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>

      {isDeleteModalOpen && (
        <DeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          employee={selectedEmployee}
          onUpdate={handleUpdate}
        />
      )}
      {isEditModalOpen && (
        <ModalEdit
          onClose={() => setIsEditModalOpen(false)}
          employee={selectedEmployee}
          onUpdate={handleUpdate}
        />
      )}
      {isChildrenModalOpen && (
        <ModalChildren
          onClose={() => setIsChildrenModalOpen(false)}
          employee={selectedEmployee}
        />
      )}
    </>
  );
};

export default ListGeneral;
